import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";
var qs = require("qs");
// encription not needed
// export const activeEmailAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   // console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .get("/user/activate/" + payload.id, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       Promise.reject(error.response.data);
//     });
// };
export const activeEmailAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the payload.id and ensure valid ciphertext
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    const response = await axiosInstance().get(
      `/user/activate/${ciphertext}`,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    );

    return response; // Return only the response data
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};