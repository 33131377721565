import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import MovieGenreCategory from "./MovieGenreCategory";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";
import { authenticatedStatusSelector } from "../../../Redux/authentication/selectors";
import { generalSettingsSelector } from "../../../Redux/live/selectors";
const MovieGenreContainer = ({
  setGenreDetails,
  setIsVideoPlayer,
  DEVICE_STATUS,
  AUTHENTICATION,
  setSelectedCategory,
  GENERAL_DATA,
}) => {
  const history = useHistory();
  let location = useLocation().state;

  document.title = GENERAL_DATA?.appTitle;
  useEffect(() => {
    setIsVideoPlayer(false);
  });

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      // console.log("Scrolling slider to element:", myRef.current);

      requestAnimationFrame(() => {
        myRef?.current?.scrollIntoView({
          // behavior: "smooth",
          block: "start",
        });
      });
    }
  }, [myRef]);
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  return (
    <div
      ref={myRef}
      className="main-content p-0 wrapper"
      style={{
        minHeight: "600px",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section id="iq-favorites">
        <div className="container-fluid">
          <div className="row">
            <MovieGenreCategory
              setSelectedCategory={setSelectedCategory}
              setGenreDetails={setGenreDetails}
              location={location}
            />
          </div>
        </div>
      </section>
      {/* <div style={{ height: "80px", paddingTop: "100px" }}>
        <FooterPage />
      </div> */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GENERAL_DATA: generalSettingsSelector,
});
export default connect(mapStateToProps)(MovieGenreContainer);
