/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import { nodeimprovisationServerPath } from "../../components/IpConstantFile/IpConstantFile";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const seriesGenreAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  // alert("Genre parameters " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      nodeimprovisationServerPath + "/V3/genre/seriesDetails",
      payload,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    )
    .then((response) => {
      // alert(JSON.stringify(response));
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      // alert(JSON.stringify(error));
      Promise.reject(error.response.data);
    });
};



