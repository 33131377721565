export const baseName = "/";

// export const subscribeUrl = "https://dishhome.com.np/dishhome-go/packages";
// export const subscribeUrl = "http://demo1.dishhome.com.np/dishhome-go/packages";

// //*********Dev

// export const serverIpPath =
//   "https://soapboxplayer.riversilica.com/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapboxplayer.riversilica.com/soapboxdbcache";
// export const websocketPathForCheckDevice =
//   "soapboxplayer.riversilica.com/websocket/?deviceId=";

// //*********Testing

// export const serverIpPath = "https://soapboxplayer.riversilica.com/rswebappws";
// export const nodeimprovisationServerPath =
//   "https://soapboxplayer.riversilica.com/rsdbcache";
// export const websocketPathForCheckDevice =
//   "soapboxplayer.riversilica.com/rswebsocket/?deviceId=";
// ----------------------------------

// //********* soapbox-testing

// export const serverIpPath =
//   "https://soapbox-testing.riversilica.com/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox-testing.riversilica.com/soapboxdbcache";
// export const websocketPathForCheckDevice =
//   "soapbox-testing.riversilica.com/websocket/?deviceId=";
// ----------------------------------

//*********gemini

// export const serverIpPath = "https://soapbox-dev.riversilica.com/geminiwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox-dev.riversilica.com/geminiV2dbcache";
// export const websocketPathForCheckDevice =
//   "soapbox-dev.riversilica.com/geminiwebsocket/?deviceId=";
// ----------------------------------

// //***************staging

// export const serverIpPath = "https://dmnott.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   //  "https://dmnott.dishhome.com.np/soapboxdbcache";  // old
//   "https://dmnott.dishhome.com.np/soapboxcache"; //new

// export const websocketPathForCheckDevice =
//   "dmnott.dishhome.com.np/websocket/?deviceId=";

// export const serverIpPath = "https://soapbox.dishhome.com.np/rswebappws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/nodeimprovisation";

// export const serverIpPath = "https://soapbox.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/soapboxdbcache";

// // ******sms

export const serverIpPath =
// "https://soapboxplayer.riversilica.com/soapboxwebws";
  "https://soapboxplayer.riversilica.com/soapboxtvappws";
export const nodeimprovisationServerPath =
// "https://soapboxplayer.riversilica.com/soapboxdbcache";
"https://soapboxplayer.riversilica.com/rsdbcache";
//  "http://3.108.142.245:3210/rsdbcache";

export const websocketPathForCheckDevice =
  "soapboxplayer.riversilica.com/websocket/?deviceId=";



// // // with port
// export const serverIpPath =
// "http://3.108.142.245:3006/soapboxwebws";
//  export const nodeimprovisationServerPath =
// "http://3.108.142.245:3010/soapboxdbcache";

// export const websocketPathForCheckDevice =
//    "3.108.142.245:3001/websocket/?deviceId=";
// // //  "localhost:3001/websocket/?deviceId=";





// export const serverIpPath = "https://soapbox.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/soapboxdbcache";

// export const websocketPath = "soapbox.dishhome.com.np";

// export const websocketPathForCheckDevice =
//   "soapbox.dishhome.com.np/websocket/?deviceId=";

// export const serverIpPath = "https://dmnott.dishhome.com.np/rswebappws";
// export const nodeimprovisationServerPath =
//   "https://dmnott.dishhome.com.np/rsdbcache";

// export const websocketPathForCheckDevice =
//   "dmnott.dishhome.com.np/rswebsocket/?deviceId=";

// fro GIT

// export const serverIpPath =
//   window.location.protocol + "//" + window.location.hostname + "/soapboxwebws";
// //  "http://localhost:3006/soapboxwebws/";
// export const nodeimprovisationServerPath =
//   window.location.protocol + "//" + window.location.hostname + "/dhdbcache/";
// // "http://localhost:3010/soapboxdbcache/";

// export const websocketPathForCheckDevice =
//   window.location.hostname + "/websocket/?deviceId=";
//  "localhost:3001/websocket/?deviceId=";
// "localhost:3003/dataVersioning/?deviceId=";

// export const serverIpPath = "http://10.0.10.102:3006/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "http://10.0.10.102:3010/soapboxdbcache";
// export const websocketPathForCheckDevice = "10.0.10.102/websocket/?deviceId=";

// export const serverIpPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":3006/soapboxwebws";
// //  "http://localhost:3006/soapboxwebws/";
// export const nodeimprovisationServerPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":5010/dhdbcache";

// export const websocketPathForCheckDevice =
//   window.location.hostname + ":3001/websocket/?deviceId=";
// // //  "localhost:3001/websocket/?deviceId=";
// // // "localhost:3003/dataVersioning/?deviceId=";

// without port

// export const serverIpPath =
//   window.location.protocol + "//" + window.location.hostname + "/soapboxwebws";
// //  "http://localhost:3006/soapboxwebws/";
// export const nodeimprovisationServerPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/dhdbcacheV2";

// export const websocketPathForCheckDevice =
//   window.location.hostname + "/dhwebsocket/?deviceId=";
//  "localhost:3001/websocket/?deviceId=";
// "localhost:3003/dataVersioning/?deviceId=";

// // // with port
// export const serverIpPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":3006/soapboxwebws";
// //  "http://localhost:3006/soapboxwebws/";
// export const nodeimprovisationServerPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":3010/soapboxdbcache";

// export const websocketPathForCheckDevice =
//   window.location.hostname + ":3001/websocket/?deviceId=";
// // //  "localhost:3001/websocket/?deviceId=";




// export const serverIpPath =
// // "https://soapboxplayer.riversilica.com/soapboxwebws";
//   "http://3.108.142.245/soapboxtvappws";
// export const nodeimprovisationServerPath =
//   "http://3.108.142.245/soapboxdbcache";
// export const websocketPathForCheckDevice =
//   "3.108.142.245/websocket/?deviceId=";
