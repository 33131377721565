import ACTION_TYPES from "./action.types";

const {
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_SUCCESS,
  GET_SEARCH_DATA_FAILED,
  GET_SEARCH_SERIES_DATA_FAILED,


} = ACTION_TYPES;



export const getSearchStart = (payload) => ({
  type: GET_SEARCH_DATA_START,
  payload,
});

export const getSearchSuccess = (payload) => ({
  type: GET_SEARCH_DATA_SUCCESS,
  payload,
});
export const getSearchFailed = (payload) => ({
  type: GET_SEARCH_DATA_FAILED,
  payload,
});

export const getSearchSeriesData = (payload) => ({
  type: GET_SEARCH_SERIES_DATA_FAILED,
  payload,
});

  // export const SK = "4{6(2]8#efaae1da59d9b+3ffaec0.82d}9|967cae8293fa5d}03fd.3ce51=c:f7c8f;3393fc&a$"
  export const SK = "6DWwxcMnPSSaLo6E"