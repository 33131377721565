/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";
var qs = require("qs");

// export const liveChannelAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .post("/live/" + payload.id, payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response.data);
//     });
// };
export const liveChannelAsync = async (payload) => {
  try {
    const ConstantHeaders = HeadersAcessToken();

    // Encrypt the payload.id and make it URL-safe
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    const response = await axiosInstance().post(
      `/live/V1/${ciphertext}`, // Use the encrypted id in the URL
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};
export const MovieDataAsync = async (payload) => {
  try {
    // Encrypt the payload.id
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    const ConstantHeaders = HeadersAcessToken();

    // Use the encrypted id in the URL
    const response = await axiosInstance().post(
      `/vod/V1/${ciphertext}`,
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};

// export const SeriesDataAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   return await axiosInstance()
//     .post("/series/" + payload.id, payload, {
//       headers: ConstantHeaders, // Use the headers obtained from createHeaders
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response.data);
//     });
// };
 
export const SeriesDataAsync = async (payload) => {
  try {
    // Encrypt the payload.id
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    const ConstantHeaders = HeadersAcessToken();

    // Use the encrypted id in the URL
    const response = await axiosInstance().post(
      `/series/V1/${ciphertext}`,
      payload,
      {
        headers: ConstantHeaders,
      }
    );

    return response;
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};