/** @format */

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect, useDispatch } from "react-redux";
import Modal from "react-responsive-modal";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as PROFILE_TYPES from "../../Redux/auth/actions";
import { createStructuredSelector } from "reselect";
import "./profileModal.css";
import { getProfilePicLoadingSelector } from "../../Redux/auth/selectors";
// import * as PROFILE_ACION from "./../../Redux/ProfileDetails/actions";
import { platformsSelector } from "../../Redux/platforms/selectors";

import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { newImageIpPath } from "../../App";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import { generalSettingsSelector } from "../../Redux/live/selectors";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    height: "50%",
    width: "50%",
  },
};
const setAppElement = "#yourAppElement";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function ManageProfileModal({
  modalIsOpen,
  setIsOpen,
  setProfilePic,
  PLATFORM,
  PROFILE_LOADING,

  USER_DETAILS,
  AUTHENTICATION,
  GENERAL_DATA,
}) {
  const classes = useStyles();
  let selectedFile;
  const dispatch = useDispatch();

  function closeModal() {
    // setProfileImg(null);
    setIsOpen(false);
  }
  const [profileImg, setProfileImg] = useState();
  const [profileImgUrl, setProfileImgUrl] = useState();
  useEffect(() => {
    if (
      USER_DETAILS?.profilePictureUrl != undefined &&
      USER_DETAILS?.profilePictureUrl != null
    ) {
      setProfileImg(newImageIpPath + USER_DETAILS?.profilePictureUrl);
      setProfileImgUrl(newImageIpPath + USER_DETAILS?.profilePictureUrl);
    }
  }, [USER_DETAILS?.profilePictureUrl]);

  const onImageSelect = (e) => {
    selectedFile = URL.createObjectURL(e.target.files[0]);

    //console.log("url", selectedFile);

    let file = e.target.files[0];

    if (file.size > 2e6) {
      Swal.fire({
        icon: "warning",

        // position: "top-end",

        title: GENERAL_DATA?.alertBoxHeader,

        text: "File size should not exceed 2MB",

        allowOutsideClick: () => !swal.isLoading(),

        confirmButtonColor: "#3085d6",

        button: "CANCEL",
      });

      return false;
    }

    setProfileImgUrl(selectedFile);

    setProfileImg(file);

    //console.log(profileImg);
  };
  const onUploadProfile = () => {
    setProfilePic(profileImgUrl);
    setIsOpen(false);
    let profileDetails = {
      id: USER_DETAILS?.id,
      updatedBy: USER_DETAILS?.id,
      account_id: PLATFORM.currentPlatformId,
      profile_url: profileImg,
    };

    //console.log("profile ditailsssss", profileDetails);
    dispatch(PROFILE_TYPES.profilepicUrlStart(profileDetails));
    // dispatch(PROFILE_ACION.getProfileDetailsStart(data));
    setProfileImg(null);
    // window.location.reload();
  };

  useEffect(() => {
    // dispatch(PROFILE_ACION.getProfileDetailsStart(data1));
  }, []);

  return (
    <div>
      <Modal
        open={modalIsOpen}
        center
        onClose={() => setIsOpen(false)}
        closeIcon={null}
        animationDuration={500}
        classNames={{
          modal: "ModalClassss",
          // overlayAnimationOut: "customLeaveOverlayAnimation",
          // modalAnimationIn: "customEnterModalAnimation",
          // modalAnimationOut: "customLeaveModalAnimation"
        }}
        style={customStyles}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
          color="white"
          cursor="pointer"
          onClick={closeModal}
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
        <input
          accept=".jpg,.jpeg,.png"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onImageSelect}
        />
        <Grid container justify="center">
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span">
              Choose file
            </Button>
          </label>
        </Grid>
        {profileImg != undefined && profileImg != null && (
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            height="20%"
            width="40%"
            src={profileImgUrl}
            alt=""
          />
        )}
        {profileImg != undefined ? (
          <Grid container justify="center">
            <Button
              className="newBtn"
              // style={{
              //   color: "white",
              //   marginTop: "5%",
              //   border: "1px solid white",
              //   borderRadius: "5px",
              // }}
              style={{
                color: "white",
                marginTop: "5%",
                border: "1px solid white",
                borderRadius: "5px",
                fontFamily: "Montserrat,sans-serif",
                backgroundColor:
                  "linear-gradient(to right, #220703, #b90909, #8b0a08e0)",
                padding: "10px",
                borderRadius: "5px",
                color: "white",
                borderColor: "transparent",
              }}
              onClick={onUploadProfile}
            >
              upload
            </Button>
          </Grid>
        ) : (
          " "
        )}
      </Modal>
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  PLATFORM: platformsSelector,
  PROFILE_LOADING: getProfilePicLoadingSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GENERAL_DATA: generalSettingsSelector,
});
export default connect(mapStateToProps)(ManageProfileModal);
