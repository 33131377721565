/** @format */

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as ACTION_TYPE from "./../Redux/resetPassword/action";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getActiveUserIdSelector } from "../Redux/email-activation/selector";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./forgotPassword.css";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
import { useHistory } from "react-router-dom";
import { resetPasswordStateSelector } from "../Redux/resetPassword/selector";
import { baseName } from "./IpConstantFile/IpConstantFile";
import * as DEFAULT_PLATFORM from "./../Redux/DefaultPlatform/actions";
import * as MAIL_VALIDITY from "./../Redux/Checking the mail validity/actions";
import {
  getCheckingMailValiditySelector,
  getMailLoadingSelector,
} from "../Redux/Checking the mail validity/selectors";
import NewLoadingPage from "./NewLoadingPage";
import { newImageIpPath } from "../App";

function ForgotResetPasswordPage({
  USERID,
  PLATFORM_ID,
  RESET_STATUS,
  CURRENT_PLATFORM,
  CHECK_MAIL_VALIDITY_DATA,
  MAIL_LOADING,
}) {
  const [brandBackgroundImage, setBrandBackgroundImage] = useState(
    newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage
  );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    userId = url.searchParams.get("userId");
    dispatch(MAIL_VALIDITY.checkingMailValidityStart(userId));
  }, []);
  useEffect(() => {
    dispatch(DEFAULT_PLATFORM.getDefaultPlatformsListStart());
  }, []);

  // const handleValidation = (event) => {
  //   let formIsValid = true;
  //   // if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
  //   //   formIsValid = false;
  //   //   setemailError("Email Not Valid");
  //   //   return false;
  //   // } else {
  //   //   setemailError("");
  //   //   formIsValid = true;
  //   // }

  //   if (!newPassword.match(/^[a-zA-Z]{8,22}$/)) {
  //     formIsValid = false;
  //     setpasswordError(
  //       "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
  //     );
  //     return false;
  //   } else {
  //     setpasswordError("");
  //     formIsValid = true;
  //   }

  //   return formIsValid;
  // };
  let userId;

  //alert(JSON.stringify(userId))
  const loginSubmit = (e) => {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      userId = url.searchParams.get("userId");
    } catch (err) {}
    let data = {
      id: userId,
      updatedBy: userId,
      account_id: PLATFORM_ID.currentPlatformId,
      password: newPassword,
    };

    if (newPassword?.trim().length != 0) {
      var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
      var result = pattern.test(newPassword);
      if (result == true) {
        if (confirmPassword?.trim().length != 0) {
          if (newPassword == confirmPassword) {
            dispatch(
              ACTION_TYPE.resetPasswordStart({ data: data, history: history })
            );
          } else {
            Swal.fire({
              icon: "warning",
              // position: "top-end",
              text: "Password doesn't match.",
              allowOutsideClick: () => !swal.isLoading(),
              confirmButtonColor: "#3085d6",
              button: "OK",
            });
          }
        } else {
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text: "Please enter confirm password.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        }
      } else {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "Password must contain at least one number, one uppercase ,one lowercase letter, one special character, and at least six or more characters",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text: "Please enter new password.",
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      });
    }
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return MAIL_LOADING == false ? (
    CHECK_MAIL_VALIDITY_DATA == true ? (
      <div
        style={{
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(${brandBackgroundImage})`,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "fixed",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            // backgroundColor: "green",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            className="resetP"
            style={{
              height: "70%",
              width: "40%",
              backgroundColor: "rgba(52, 53, 53, 0.9)",
              borderRadius: "10%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div className="col-12 mt-3 ">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 24 24"
                  fill="none"
                  color="white"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-lock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "34px",
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                Reset Password
              </div>

              <div className="mt-5">
                <div
                  className="row p-2 ml-1"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    className="resetP"
                    style={{
                      color: "black",
                      width: "70%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type={passwordType}
                      name="currentPassword"
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      required
                      placeholder="New password"
                      style={{
                        width: "100%",
                        height: "48px",
                        backgroundColor: "transparent",
                        outline: 0,
                        border: "1px solid white",
                        borderRadius: "5px",
                        transition: "all 0.3s ease-in-out",
                        padding: "15px 15px 15px 44px",
                        fontSize: "14px",
                        color: "white",
                      }}
                      maxLength="25"
                    />

                    {passwordType === "password" ? (
                      <i
                        className="bi bi-eye-slash p-1"
                        style={{
                          color: "gray",
                          float: "right",
                          zIndex: 2000,
                          position: "relative",
                          marginTop: "-40px",
                          marginLeft: "20px",
                          // left: 3,
                        }}
                        onClick={togglePassword}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye p-1"
                        onClick={togglePassword}
                        style={{
                          color: "gray",
                          float: "right",
                          zIndex: 2000,
                          position: "relative",
                          marginTop: "-40px",
                          marginLeft: "20px",
                        }}
                      ></i>
                    )}
                    {/* </div> */}
                  </div>
                </div>

                <div
                  className="row p-2 ml-1"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    className="input-single1 input-pass1 resetP"
                    style={{
                      position: "relative",
                      color: "black",
                      width: "70%",
                    }}
                  >
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                      placeholder="Confirm new password"
                      //id="exampleInputPassword2"
                      required
                      name="password"
                      // value={password.password}
                      // onChange={handlePasswordChange}
                      style={{
                        width: "100%",
                        height: "48px",
                        backgroundColor: "transparent",
                        outline: 0,
                        border: "1px solid white",
                        borderRadius: "5px",
                        transition: "all 0.3s ease-in-out",
                        padding: "15px 15px 15px 44px",
                        fontSize: "14px",
                        color: "white",
                      }}
                      maxLength="25"
                    />
                  </div>
                </div>
              </div>
              <div
                className="mt-5 ml-1"
                style={{
                  // backgroundColor: "red",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <button
                  className="newBtn"
                  style={{
                    width: "30%",

                    fontFamily: "Montserrat,sans-serif",
                    backgroundColor: "red",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "white",
                    borderColor: "transparent",
                    marginLeft: "8%",
                  }}
                  onClick={loginSubmit}
                >
                  Update Password
                </button>
                <div
                  className="ml-5"
                  // href="/login"
                  onClick={() => {
                    window.location.href = baseName + "login";
                  }}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Skip
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="col-12 "
        style={{
          // height: "100%",
          justifyContent: "center",
          alignItems: "center",
          // display: "flex",
          marginTop: "15%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            display: "flex",
          }}
        >
          <img src="/images/warning1.png" height={80} />
        </div>
        <div className="mt-5">
          <h3 style={{ textAlign: "center", color: "white" }}>
            Reset Password Link has expired. Reach out to the Admin
          </h3>
        </div>
      </div>
    )
  ) : (
    <div style={{ marginTop: "7%" }}>
      <NewLoadingPage />
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  USERID: getActiveUserIdSelector,
  PLATFORM_ID: platformsSelector,
  RESET_STATUS: resetPasswordStateSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  CHECK_MAIL_VALIDITY_DATA: getCheckingMailValiditySelector,

  MAIL_LOADING: getMailLoadingSelector,
});
export default connect(mapStateToProps)(ForgotResetPasswordPage);
