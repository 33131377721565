/** @format */
import axiosInstance, { encryptDataForHeader } from "../../utils/axiosInstance";

 var qs = require("qs");


export const getDeviceListAsync = async (payload) => {
  try {
    // Encrypt the userId
    const ciphertext = encryptDataForHeader(payload?.userId);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    // Make the API request with the encrypted userId
    const response = await axiosInstance().post(
      `V2/devices/new/${ciphertext}`,
      payload
    );

    return response;
  } catch (error) {
    return Promise.reject(error.response ? error.response.data : error);
  }
};

export const replaceDeviceAsync = async (payload) => {
  try {


    const response = await axiosInstance().post("/replace/deviceV1", payload);
    return response;
  } catch (error) {
    throw error;
  }
};
