import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";

var qs = require("qs");

// export const chechMailValidityAsync = async (payload) => {
//   const ConstantHeaders = HeadersAcessToken();

//   //console.log("live assets parameters " + JSON.stringify(payload));
//   // payload.programmeDate = "2022-09-17";
//   // alert(JSON.stringify(payload));

//   return await axiosInstance()
//     .get(
//       "/user/varify/" + payload,

//       {
//         headers: ConstantHeaders, // Use the headers obtained from createHeaders
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       //console.log("error response " + error);
//       Promise.reject(error.response);
//     });
// };
// export const chechMailValidityAsync = async (payload) => {
//   try {
//     // Encrypt the payload
//     const ciphertext = encryptData(payload);
//     const urlSafeCiphertext = ciphertext
//       .replace(/\//g, "_")
//       .replace(/\+/g, "-")
//       .replace(/=+$/, "");

//     const response = await axiosInstance().get(
//       // `/user/varify/${urlSafeCiphertext}`

//       "/user/varify/" + urlSafeCiphertext
//     );

//     return response; // Return data directly
//   } catch (error) {
//     throw error.response.data; // Throw the response data in case of error
//   }
// };
export const chechMailValidityAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the email (not the entire payload)
    const ciphertext = encryptDataForHeader(payload?.email);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    // Make the API request
    const response = await axiosInstance().get(
      `/user/varify/${ciphertext}`,
      {
        headers: ConstantHeaders,
      }
    );

    return response; // Return only the response data
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};