import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";

import {
  allLiveAssetSelector,
  getAllAssetsIdSelector,
} from "../../Redux/AllLiveDetails/selectors";
import { getMovieDetaisSelector } from "../../Redux/MoviesRedux/selector";
import { seriesAssetSelector } from "../../Redux/SeriesGenre/selector";
import * as GET_FAVORITES_ACTION from "../../Redux/FavouteDataRedux/action";
import { userDetailsSelector } from "../../Redux/authentication/selectors";
import DisplayMovieImage from "../DisplayMovieImage";
import MovieScreenDisplayMovieImage from "../MoviesScreens/MovieScreenDisplayMovieImage";
import SeriesPageDisplayImage from "../SeariesPages/SeriesPageDisplayImage";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import * as MOVIE_ACTION from "../../Redux/MoviesRedux/actions";
import * as SERIAS_GENRE_ACTION from "../../Redux/SeriesGenre/actions";
import * as ALL_LIVE_ASSETS_ACTION from "../../Redux/AllLiveDetails/actions";
import { generalSettingsSelector } from "../../Redux/live/selectors";

const MyListCategory = ({
  GET_FAVOURITES_DATA,
  LIVEASSETS,
  MOVIE_DETAILS,
  SERIES_ASSETS,
  USER_DETAILS,
  ASSET_IDs_BASED_ON_LCN,
  CURRENT_PLATFORM,
  GENERAL_DATA,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      GET_FAVORITES_ACTION.getFavouriteDataStart({
        userId: USER_DETAILS?.id,
      })
    );
  }, []);

  let liveExists = false;
  let movieExists = false;
  let seriesExists = false;

  let liveCount = 0;
  let movieCount = 0;
  let seriesCount = 0;
  let isLiveRequired = Number(GENERAL_DATA?.isLiveRequired);
  let isMovieRequired = Number(GENERAL_DATA?.isMovieRequired);
  let isSeriesRequired = Number(GENERAL_DATA?.isSeriesRequired);

  if (
    GET_FAVOURITES_DATA &&
    Array.isArray(GET_FAVOURITES_DATA) &&
    GET_FAVOURITES_DATA.length > 0
  ) {
    GET_FAVOURITES_DATA.forEach((element) => {
      if (
        element?.categoryId == 1 &&
        ASSET_IDs_BASED_ON_LCN?.includes(Number(element.assetId)) &&
        isLiveRequired == 1
      ) {
        liveExists = true;
        liveCount += 1; // Count live assets
      }
      if (element?.categoryId == 2 && isMovieRequired == 1) {
        movieExists = true;
        movieCount += 1; // Count movies
      }
      if (element?.categoryId == 3 && isSeriesRequired) {
        seriesExists = true;
        seriesCount += 1; // Count series
      }
    });
  }

  useEffect(() => {
    // console.log("calling movie api arun", MOVIE_DETAILS?.moviegenres);
    // console.log("calling movie api arun", MOVIE_DETAILS?.movieliveAssetDetails);
    // console.log("calling movie api arun", MOVIEVERSION);

    if (
      MOVIE_DETAILS?.moviegenres == null ||
      MOVIE_DETAILS?.moviegenres.length < 1 ||
      MOVIE_DETAILS?.movieliveAssetDetails == undefined ||
      MOVIE_DETAILS?.movieliveAssetDetails == null ||
      MOVIE_DETAILS?.movieliveAssetDetails.length < 1
    ) {
      // console.log("calling movie api arun");
      dispatch(
        MOVIE_ACTION.getMoviesDataStart({
          account_id: CURRENT_PLATFORM?.orgId,
        })
      );
    }
    if (
      SERIES_ASSETS?.genreDetails == null ||
      SERIES_ASSETS?.genreDetails.length < 1 ||
      SERIES_ASSETS?.assetDetails == undefined ||
      SERIES_ASSETS?.assetDetails == null ||
      SERIES_ASSETS?.assetDetails.length < 1
    ) {
      dispatch(
        SERIAS_GENRE_ACTION.getSriesGenresStart({
          account_id: CURRENT_PLATFORM?.orgId,
        })
      );
    }
    if (
      LIVEASSETS?.genres == null ||
      LIVEASSETS?.genres.length < 1 ||
      LIVEASSETS?.liveAssetDetails == undefined ||
      LIVEASSETS?.liveAssetDetails == null ||
      LIVEASSETS?.liveAssetDetails.length < 1
    ) {
      dispatch(
        ALL_LIVE_ASSETS_ACTION.getAllLiveAssetsStart({
          account_id: CURRENT_PLATFORM?.orgId,
        })
      );
      dispatch(
        ALL_LIVE_ASSETS_ACTION.getAllLiveAsetsBasedOnKeyStart({
          account_id: CURRENT_PLATFORM?.orgId,
        })
      );
    }
  }, []);
  const getItem1 = (item) => ({ assetId: item.id, categoryId: 1, active: 1 });
  const getItem2 = (item) => ({ assetId: item.id, categoryId: 2, active: 1 });
  const getItem3 = (item) => ({ assetId: item.id, categoryId: 3, active: 1 });
  const isLiveCountMoreThanFour = liveCount > 4;
  const isMovieCountMoreThanFour = movieCount > 4;
  const isSeriesCountMoreThanFour = seriesCount > 4;
  return (
    <div className="main-content p-0">
      {GET_FAVOURITES_DATA &&
      Array.isArray(GET_FAVOURITES_DATA) &&
      GET_FAVOURITES_DATA.length > 0 ? (
        <section id="iq-favorites">
          <div className="container-fluid">
            <div className="row">
              {/* Live Section */}
              {liveExists && (
                <div className="col-sm-12 pr-4 pl-4">
                  <div
                    className="iq-main-header  align-items-center justify-content-between p-0 m-0"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    <h5
                      className="main-title p-0 mt-3"
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        color: "white",
                      }}
                    >
                      Live
                    </h5>
                  </div>
                  <Swiper
                    slidesPerView="auto" // Allow Swiper to automatically determine slides per view
                    navigation={isLiveCountMoreThanFour}
                    pagination={{ clickable: false }}
                    keyboard={{ enabled: true }}
                    modules={[Navigation, Keyboard]}
                    className={`mySwiper ${"category-1"}`} // Add conditional class
                    spaceBetween={10}
                  >
                    {GET_FAVOURITES_DATA?.map(
                      (item, index) =>
                        item?.categoryId == 1 &&
                        LIVEASSETS?.liveAssetDetails?.[item?.assetId] !=
                          undefined && (
                          <SwiperSlide key={index}>
                            <DisplayMovieImage
                              item={getItem1(
                                LIVEASSETS?.liveAssetDetails?.[item?.assetId]
                              )}
                              index={index}
                              from={"mylist"}
                            />
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              )}

              {/* Movies Section */}
              {movieExists && (
                <div className="col-sm-12 pr-4 pl-4">
                  <div
                    className="iq-main-header  align-items-center justify-content-between p-0 m-0"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    <h5
                      className="main-title p-0 mt-3"
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        color: "white",
                      }}
                    >
                      Movies
                    </h5>
                  </div>
                  <Swiper
                    slidesPerView="auto" // Allow Swiper to automatically determine slides per view
                    navigation={isMovieCountMoreThanFour}
                    pagination={{ clickable: false }}
                    keyboard={{ enabled: true }}
                    modules={[Navigation, Keyboard]}
                    className="mySwiper"
                    spaceBetween={10}
                  >
                    {GET_FAVOURITES_DATA?.map(
                      (item, index) =>
                        item?.categoryId == 2 &&
                        MOVIE_DETAILS?.movieliveAssetDetails[item?.assetId] !=
                          undefined && (
                          <SwiperSlide key={index}>
                            <MovieScreenDisplayMovieImage
                              item={getItem2(
                                MOVIE_DETAILS?.movieliveAssetDetails[
                                  item?.assetId
                                ]
                              )}
                              index={index}
                              from={"mylist"}
                            />
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              )}

              {/* Series Section */}
              {seriesExists && (
                <div className="col-sm-12 pr-4 pl-4">
                  <div
                    className="iq-main-header  align-items-center justify-content-between p-0 m-0"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    <h5
                      className="main-title p-0 mt-3"
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        color: "white",
                      }}
                    >
                      Series
                    </h5>
                  </div>
                  <Swiper
                    slidesPerView="auto" // Allow Swiper to automatically determine slides per view
                    navigation={isSeriesCountMoreThanFour}
                    pagination={{ clickable: false }}
                    keyboard={{ enabled: true }}
                    modules={[Navigation, Keyboard]}
                    className="mySwiper"
                    spaceBetween={10}
                  >
                    {GET_FAVOURITES_DATA?.map(
                      (item, index) =>
                        item?.categoryId == 3 &&
                        SERIES_ASSETS?.assetDetails?.[item?.assetId] !=
                          undefined && (
                          <SwiperSlide key={index}>
                            <SeriesPageDisplayImage
                              item={getItem3(
                                SERIES_ASSETS?.assetDetails[item?.assetId]
                              )}
                              index={index}
                              from={"mylist"}
                            />
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        <div
          className="empty-favorites-message"
          style={{
            textAlign: "center",
            padding: "30px",
            background:
              "linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(25, 25, 25, 0.2))", // Updated background gradient
            color: "#d7c8c8", // Updated color from the class
            borderRadius: "12px",
            margin: "20px",
            border: "1px solid #000000",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow matching the class
          }}
        >
          <p style={{ fontSize: "24px", fontWeight: "bold" }}>
            Looks like your favourite list is empty...
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LIVEASSETS: allLiveAssetSelector,
  MOVIE_DETAILS: getMovieDetaisSelector,
  SERIES_ASSETS: seriesAssetSelector,
  USER_DETAILS: userDetailsSelector,
  ASSET_IDs_BASED_ON_LCN: getAllAssetsIdSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(MyListCategory);
