/** @format */

import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBannerSelector } from "../Redux/live/selectors";
import { createStructuredSelector } from "reselect";
import Carousel from "react-bootstrap/Carousel";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import * as VIDEO_ACTION from "./../Redux/VideoRedux/action";
import "./slider.css";
import NewLoadingPage from "./NewLoadingPage";
import { getMovieDetaisSelector } from "../Redux/MoviesRedux/selector";
import { seriesAssetSelector } from "../Redux/SeriesGenre/selector";
import { allLiveAssetSelector } from "../Redux/AllLiveDetails/selectors";
import { newImageIpPath } from "../App";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
const Slider = ({
  LIVEASSETS,
  GET_BANNERS_DATA,
  MOVIE_DETAILS,
  SERIES_ASSETS,
  CURRENT_PLATFORM,
  PLATFORM,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleBannerDetails = (item) => {
    if (item?.categoryId == 4) {
      if (
        item?.redirectionUrl != undefined &&
        item?.redirectionUrl != null &&
        item?.redirectionUrl != "null" &&
        item?.redirectionUrl != "undefined" &&
        item?.redirectionUrl?.indexOf("-") < 0
      ) {
        window?.open(item?.redirectionUrl);
      }
    }
    if (
      item?.categoryId == 2 &&
      item?.assetId != null &&
      item?.assetId != undefined &&
      // MOVIE_DETAILS?.movieliveAssetDetails[item?.assetId] != undefined &&
      item?.assetId?.indexOf("null") < 0
    ) {
      const element = MOVIE_DETAILS?.movieliveAssetDetails[item?.assetId];

      if (element != undefined && element != null) {
        dispatch(VIDEO_ACTION.getMovieData(element));
      } else {
        let requestData = {
          id: item?.assetId,
          account_id: PLATFORM.currentPlatformId,
        };

        dispatch(VIDEO_ACTION.getMovieDetailsStart(requestData));
      }
      history.push({
        pathname: "/MovieDetailsPage",
        state: {
          from: "slider",
        },
      });
    } else if (
      item?.categoryId == 1 &&
      item?.assetId != null &&
      // LIVEASSETS?.liveAssetDetails?.[item?.assetId] != undefined &&
      item?.assetId?.indexOf("null") < 0
    ) {
      let requestData = {
        id: item?.assetId,
        account_id: PLATFORM.currentPlatformId,
      };
      if (
        LIVEASSETS?.liveAssetDetails?.[item?.assetId] != undefined &&
        LIVEASSETS?.liveAssetDetails?.[item?.assetId] != null
      ) {
        dispatch(
          VIDEO_ACTION.getLiveChannelData(
            LIVEASSETS?.liveAssetDetails?.[item?.assetId]
          )
        );
      } else {
        dispatch(VIDEO_ACTION.getLiveDetailsStart(requestData));
      }

      history.push({
        pathname: "/liveDetailsPage",
        state: {
          from: "slider",
        },
      });
    } else if (
      item?.categoryId == 3 &&
      item?.assetId != null &&
      // SERIES_ASSETS?.assetDetails[item?.assetId] != null &&
      // SERIES_ASSETS?.assetDetails[item?.assetId] != undefined &&
      item?.assetId?.indexOf("null") < 0
    ) {
      const element = SERIES_ASSETS?.assetDetails[item?.assetId];

      if (element != undefined && element != null) {
        dispatch(VIDEO_ACTION.getSeriesData(element));
      } else {
        let requestData = {
          id: item?.assetId,
          account_id: PLATFORM.currentPlatformId,
        };

        dispatch(VIDEO_ACTION.getSeriesDetailsStart(requestData));
      }
      history.push({
        pathname: "/SeriesDetailsPage",
        state: {
          from: "slider",
        },
      });
    }
  };
  const targetRef = useRef(null);
  // useEffect(() => {
  //   const onmoviesValue = localStorage.getItem("onmovies");

  //   if (onmoviesValue === "true" && targetRef.current) {
  //     // console.log("Scrolling slider to element:", targetRef.current);

  //     requestAnimationFrame(() => {
  //       targetRef?.current?.scrollIntoView({
  //         // behavior: "smooth",
  //         block: "start",
  //       });
  //     });
  //   }
  // }, [targetRef]);
  return (
    <div style={{ marginTop: "8px" }} ref={targetRef}>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      >
        <Carousel
          prevLabel=""
          nextLabel=""
          prevIcon=""
          nextIcon=""
          fade={true}
          interval={3000}
          style={{ alignItems: "center" }}
        >
          {GET_BANNERS_DATA != undefined &&
          GET_BANNERS_DATA != null &&
          GET_BANNERS_DATA?.length > 0 ? (
            GET_BANNERS_DATA?.map((item, index) => (
              <Carousel.Item
                indicators="true"
                onClick={() => handleBannerDetails(item)}
                style={{ cursor: "pointer" }}
                key={index}
              >
                <img
                  style={{
                    maxHeight: "370px",
                    minHeight: "370px",
                    width: "90%",
                  }}
                  className="clearfix d-block mx-auto"
                  src={newImageIpPath + item?.webappImage}
                  alt="First slide"
                />
              </Carousel.Item>
            ))
          ) : (
            <NewLoadingPage />
          )}
        </Carousel>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  LIVEASSETS: allLiveAssetSelector,
  MOVIE_DETAILS: getMovieDetaisSelector,
  SERIES_ASSETS: seriesAssetSelector,
  GET_BANNERS_DATA: getBannerSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  PLATFORM: platformsSelector,
});

export default connect(mapStateToProps)(Slider);
