/** @format */

import React, { useEffect, useState } from "react";
import "./MovieAssetViewPage.css";
import { createStructuredSelector } from "reselect";
import { getMovieDataSelector } from "../../../Redux/VideoRedux/selector";
import { connect, useDispatch } from "react-redux";
import {
  getGeneralAdsDetailSelector,
  getgeneralGenreDetailsSelector,
  getgeneralLanguageDetailsSelector,
  getgeneralTagDetailsSelector,
} from "../../../Redux/AllLiveDetails/selectors";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors";
import * as LIVE_VIDEO_ACTION from "./../../../Redux/VideoRedux/action";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import MovieScreenTeaserVideoPlayer from "../MovieScreenTeaserVideoPlayer";
import { getFavouriteSelector } from "../../../Redux/FavouteDataRedux/selector";
import * as GET_FAVORITES_ACTION from "../../../Redux/FavouteDataRedux/action";
import {
  configurationSelector,
  continueWatchingSelector,
} from "../../../Redux/DATA-analytics/selector";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../../Redux/package&subscriptions/selector";
import * as FAVOURATE_ACTION from "../../../Redux/favourites/actions";
import * as MOVIE_VIDEO_ACTION from "../../../Redux/VideoRedux/action";
import ReactTooltip from "react-tooltip";
import * as MOVIE_DATA_ACTION from "../../../Redux/playerMovieData/action";
import AlertModal from "../../AlertModal";
import LandingScreenAlert from "../../LandingScreens/LandingScreenAlert";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import ProgressBarForMovie from "../../progress-bar/ProgressBarForMovie";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";
import AdsPage from "../../AdsPage";
import { getDeviceTokenSelector } from "../../../Redux/deviceRedux/selectors";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../../services/datAnalytics/dataAnalytics";
import { getCurrentTimeZone, newImageIpPath } from "../../../App";
import ReactShowMoreText from "react-show-more-text";

const MovieAssetViewPage = ({
  setIsVideoPlayer,
  ASSET_DATA,
  GENERAL_GENRE_DETAILS,
  GENERAL_TAG_DETAILS,
  GENERAL_LANGUAGE_DETAILS,
  PACKAGE_DETAILS,
  USER_DETAILS,
  AUTHENTICATION,
  GET_FAVOURITES_DATA,
  CONTINUE_WATCHING_DATA,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  setIsOpen,
  // from,
  CURRENT_PLATFORM_DATA,
  DEVICE_STATUS,
  GENERAL_ADS_DETAILS,
  DEVICE_TOKEN,
  CONFIGURATION_DAS,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeZone = getCurrentTimeZone();

  // console.log("ASSET_DATA" + JSON.stringify(ASSET_DATA));
  const location = useLocation().state;
  const [showOverlay, setShowOverlay] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [mouseMoveTimeout, setMouseMoveTimeout] = useState(null);
  const [isFavourite, setIsFavourite] = useState();
  const [open, setOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  let from = location?.from;

  const handleVolumeToggle = () => {
    setIsMuted(!isMuted);
  };
  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  const [alertOpen, setAlertOpen] = useState(false);

  let flag = 0;
  let date = new Date();
  let timestamp = date?.getTime();
  //  ***************PACKAGE_DETAILS**************
  for (var p = 0; p < ASSET_DATA?.packages?.length; p++) {
    let stopDate =
      PACKAGE_DETAILS?.[ASSET_DATA?.packages?.[p]?.assetGroupId]?.stopDate;

    let stopDateTimeStamp = parseInt(Date.parse(stopDate));
    if (
      ASSET_DATA?.packages[p]?.assetGroupId ==
        PACKAGE_DETAILS?.[ASSET_DATA?.packages[p]?.assetGroupId]?.packageId &&
      timestamp < stopDateTimeStamp
    ) {
      flag = 1;
    }
  }

  // ************ALACARTE_DETAILS***********************
  let stopDateAlacarte = ALACARTE_DETAILS?.[ASSET_DATA?.id]?.expiryDate;
  let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

  if (
    ASSET_DATA?.id == ALACARTE_DETAILS?.[ASSET_DATA?.id]?.assetId &&
    ALACARTE_DETAILS?.[ASSET_DATA?.id]?.categoryId == 2 &&
    timestamp < stopDateTimeStampAlacarte
  ) {
    flag = 1;
  }
  // *****************PAY_PER_VIEW_DETAILS*************
  let stopDatePPV = PAY_PER_VIEW_DETAILS?.[2]?.[ASSET_DATA?.id]?.expiryDate;
  let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

  if (
    ASSET_DATA?.id == PAY_PER_VIEW_DETAILS?.[2]?.[ASSET_DATA?.id]?.assetId &&
    // PAY_PER_VIEW_DETAILS?.[2]?.[ASSET_DATA?.id]?.categoryId == 2 &&
    timestamp < stopDateTimeStampPPV
  ) {
    flag = 1;
  }
  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);
  // useEffect(() => {
  //   setIsVideoPlayer(true);
  //   const timer = setTimeout(() => {
  //     setShowOverlay(false);
  //   }, 10000); // Hide overlay after 10 seconds

  //   return () => clearTimeout(timer);
  // }, []);

  // useEffect(() => {
  //   const handleMouseMove = () => {
  //     setShowOverlay(true);
  //     setShowDetails(true);

  //     // Clear existing timeout if there's one
  //     if (mouseMoveTimeout) {
  //       clearTimeout(mouseMoveTimeout);
  //     }

  //     // Hide details after 3 seconds of inactivity
  //     const timeout = setTimeout(() => {
  //       setShowOverlay(false);
  //       setShowDetails(false);
  //     }, 10000);

  //     setMouseMoveTimeout(timeout);
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     if (mouseMoveTimeout) {
  //       clearTimeout(mouseMoveTimeout);
  //     }
  //   };
  // }, [mouseMoveTimeout]);

  //code of creating tag lang genre using general details
  let tag = [];
  let genre = [];
  let languages = [];
  let ads = [];

  if (
    GENERAL_ADS_DETAILS?.[Number(ASSET_DATA?.vodAssetsPostAds?.[0]?.adId)] !=
    null
  ) {
    ads?.push(
      GENERAL_ADS_DETAILS?.[Number(ASSET_DATA?.vodAssetsPostAds?.[0]?.adId)]
    );
  }

  useEffect(() => {
    if (ads?.length > 0 && AUTHENTICATION) {
      let data2 = {
        adsAnalyticsData: {
          addId: ads[0].id,
          userId: USER_DETAILS?.id,
          platformId: CURRENT_PLATFORM_DATA.orgId,
          profileId: 1,
          deviceId: DEVICE_TOKEN,
          categoryId: 2,
          assetId: ASSET_DATA?.id,
          timeZone: timeZone,

        },
        ServerIp: CONFIGURATION_DAS?.analyticsIp,
      };
      getSendingDataOfPosteradsAsync(data2);
    }
  }, []);
  for (var j = 0; j < ASSET_DATA?.genres?.length; j++) {
    genre?.push(GENERAL_GENRE_DETAILS?.[ASSET_DATA?.genres?.[j]?.genreId]);
  }

  for (var k = 0; k < ASSET_DATA?.tags?.length; k++) {
    tag?.push(GENERAL_TAG_DETAILS?.[ASSET_DATA?.tags[k]?.tagId]);
  }

  languages?.push(GENERAL_LANGUAGE_DETAILS?.[ASSET_DATA?.languageId]);
  //end

  //function for playing the video
  const handleVideoDislay = (startOver) => {
    // console.log("DISPLAY_MOVIE_DATA" + JSON.stringify(element));
    dispatch(MOVIE_DATA_ACTION.getMovieDataForPlayer(ASSET_DATA));

    dispatch(MOVIE_VIDEO_ACTION.getMovieVideoUrl(ASSET_DATA?.movieUrl));

    if (AUTHENTICATION == true) {
      if (flag == 1) {
        history.push({
          pathname: "/movieScreenVideoPlayer",
          state: {
            flag: flag,
            startOver: startOver,
            from: from,
          },
        });
      } else {
        history.push({
          pathname: "/movieScreenVideoPlayer",
          state: {
            flag: flag,
            startOver: startOver,
            from: from,
          },
        });
      }
    } else {
      history.push({
        pathname: "/movieScreenVideoPlayer",
        state: {
          flag: flag,
          startOver: startOver,
          from: from,
        },
      });
    }
  };
  const handleFavouriteFill = () => {
    dispatch(
      GET_FAVORITES_ACTION.getFavouriteDataStart({
        userId: USER_DETAILS?.id,
      })
    );
    let favoriteData = {
      userId: USER_DETAILS?.id,
      categoryId: 2,
      assetId: ASSET_DATA?.id,
    };

    if (AUTHENTICATION == true) {
      // alert(flags);
      setIsFavourite(!isFavourite);
      dispatch(FAVOURATE_ACTION.addFavouritesStart(favoriteData));
      if (from == "isFavouritePage") {
        // setTimeout(() => {
        setIsOpen(false);
        // }, 1000);
      }
    } else {
      setAlertOpen(true);
    }

    // if(from)
  };

  const handleTeaser = () => {
    history.push({
      pathname: "/teaserPlayer",

      state: {
        src: ASSET_DATA?.teaserUrl,
        posterUrl: newImageIpPath + ASSET_DATA?.thumbnailUrl,
      },
    });
  };
  let checkFavourite = false;
  let lengthList = null;
  useEffect(() => {
    setIsFavourite(checkFavourite);
  }, [checkFavourite]);

  if (
    GET_FAVOURITES_DATA != undefined &&
    GET_FAVOURITES_DATA != null &&
    GET_FAVOURITES_DATA != undefined
  ) {
    lengthList = GET_FAVOURITES_DATA?.length;
  }

  if (lengthList != null && ASSET_DATA != undefined && ASSET_DATA != null) {
    for (var i = 0; i < lengthList; i++) {
      if (
        GET_FAVOURITES_DATA[i]?.categoryId == 2 &&
        GET_FAVOURITES_DATA[i] != undefined &&
        GET_FAVOURITES_DATA[i] != null
      ) {
        if (Number(GET_FAVOURITES_DATA[i]?.assetId) == Number(ASSET_DATA?.id)) {
          checkFavourite = true;
        }
      }
    }
  }
  const isContineuwatching = CONTINUE_WATCHING_DATA?.filter(
    (item) =>
      Number(item?.categoryId) === 2 &&
      Number(item?.assetId) === ASSET_DATA?.id &&
      Number(item?.isVideoEnded) === 0 &&
      Number(item?.videoDeleted) === 0
  );
  // console.log(JSON.stringify(isContineuwatching));
  let percentageViewed = 0;

  if (
    isContineuwatching != undefined &&
    isContineuwatching != null &&
    isContineuwatching.length > 0
  ) {
    const totalDurationInSeconds = convertTimeToSeconds(
      isContineuwatching?.[0].totalDuration
    );
    const durationInSeconds = convertTimeToSeconds(
      isContineuwatching?.[0]?.duration
    );
    percentageViewed = (durationInSeconds / totalDurationInSeconds) * 100;
  }

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    // Calculate the total duration in minutes
    const totalMinutes = hours * 60 + minutes + Math.round(seconds / 60);

    return totalMinutes;
  };
  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  //end

  //for alert when make favourite
  let text = "";

  if (ASSET_DATA != null && ASSET_DATA?.isPPVRequired == 0) {
    text = "Please subscribe or upgrade your package to make it as favourite.";
  } else {
    text = "Please subscribe to watch.";
  }
  if (alertOpen == true) {
    return <LandingScreenAlert open={alertOpen} setOpen={setAlertOpen} />;
  }
  if (open == true) {
    return <AlertModal open={open} setOpen={setOpen} text={text} />;
  }
  return (
    <div className="fullscreen-series-container p-0 m-0">
      <div className="video-wrapper">
        <MovieScreenTeaserVideoPlayer
          posterUrl={newImageIpPath + ASSET_DATA?.thumbnailUrl}
          teaserUrl={ASSET_DATA?.teaserUrl}
          CURRENT_PLATFORM={CURRENT_PLATFORM_DATA}
          isMuted={isMuted}
        />
      </div>
      <div className={`overlay ${showOverlay ? "visible" : "hidden"}`}>
        {" "}
        <span
          className="mute-unmute"
          style={{
            position: "absolute",
            top: "10px", // Move up by decreasing the top value
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleVolumeToggle()}
        >
          {isMuted ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white" // Change icon color to white
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-volume-x"
              style={{ cursor: "pointer" }}
            >
              <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
              <line x1="23" y1="9" x2="17" y2="15"></line>
              <line x1="17" y1="9" x2="23" y2="15"></line>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white" // Change icon color to white
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-volume-2"
              style={{ cursor: "pointer" }}
            >
              <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
              <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
            </svg>
          )}
        </span>
        <span
          className="go-back"
          style={{
            position: "absolute",
            top: "10px", // Move up by decreasing the top value
            left: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleGoBack()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white" // Change icon color to white
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-left-circle"
            style={{ cursor: "pointer" }}
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 8 8 12 12 16"></polyline>
            <line x1="16" y1="12" x2="8" y2="12"></line>
          </svg>
        </span>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            top: -10,
          }}
        >
          {/* <div
            style={{
              height: "calc(100vh / 3)",
              position: "relative",
            }}
          >
            {" "}
          </div> */}
          <div style={{ flex: 1 }}>
            {" "}
            <div className="content">
              {ASSET_DATA != undefined &&
              ASSET_DATA != null &&
              ASSET_DATA?.title != undefined &&
              ASSET_DATA?.title != null ? (
                <h1 className="title">{ASSET_DATA.title}</h1>
              ) : (
                ""
              )}
              <div
                className="col-sm-12 p-0"
                style={{ marginTop: "0px", float: "left" }}
              >
                {ASSET_DATA != undefined ? (
                  <div
                    style={{
                      fontSize: "18px",
                      color: "white",
                      marginTop: "15px",
                      fontFamily:
                        "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    }}
                  >
                    {ASSET_DATA?.shortDescription}
                  </div>
                ) : (
                  ""
                )}
                {ASSET_DATA?.longDescription ? (
                  <ReactShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="long-description"
                    anchorClass="show-more-anchor"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {ASSET_DATA?.longDescription}
                  </ReactShowMoreText>
                ) : (
                  ""
                )}
                {/* {ASSET_DATA != undefined ? (
              <div
                style={{
                  fontSize: "18px",
                  color: "white",
                  marginTop: "2px",
                  fontFamily:
                    "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                }}
              >
                {ASSET_DATA?.longDescription}
              </div>
            ) : (
              ""
            )} */}
                <span
                  style={{
                    color: "#999",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    marginRight: "10px", // Add margin to create a gap between spans

                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.yearOfRelease}
                </span>
                <span
                  style={{
                    color: "white",

                    backgroundColor: "#33373d",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    borderRadius: "5px", // Add this line for border radius
                    padding: "5px", // Optional: add padding for better appearance
                    marginRight: "10px", // Add margin to create a gap between spans
                  }}
                >
                  {ASSET_DATA?.duration}
                </span>
                <span
                  style={{
                    color: "white",

                    backgroundColor: "#33373d",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    borderRadius: "5px", // Add this line for border radius
                    padding: "5px", // Optional: add padding for better appearance
                    marginRight: "10px", // Add margin to create a gap between spans
                  }}
                >
                  {ASSET_DATA?.maturityRating}
                </span>
                {languages != undefined &&
                  languages != null &&
                  languages?.length > 0 &&
                  languages?.map((i) => (
                    <span
                      style={{
                        color: "white",

                        backgroundColor: "#33373d",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginTop: "10px",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                        borderRadius: "5px", // Add this line for border radius
                        padding: "5px", // Optional: add padding for better appearance
                        marginRight: "10px", // Add margin to create a gap between spans
                      }}
                    >
                      {" " + i?.name}
                    </span>
                  ))}
                <br />

                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Actors :{" "}
                </span>
                <span
                  style={{
                    color: "white",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.actors}
                </span>
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Director :{" "}
                </span>
                <span
                  style={{
                    color: "white",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.directors}
                </span>
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Tags :{" "}
                </span>
                {tag != undefined && tag != null && tag.length > 0
                  ? tag.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.tag}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {"|" + item?.tag}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Genres :{" "}
                </span>
                {genre.length > 0
                  ? genre.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.name}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {", " + item?.name}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
              </div>
              <div className=" col-12 mt-0 p-0 ml-0">
                <div
                  className=" col-12 p-0 m-0 row"
                  style={{ float: "left", zIndex: "9999" }}
                >
                  <div className="col-3 p-0 mt-3 ">
                    {" "}
                    <div className="hover-buttons">
                      {AUTHENTICATION === true &&
                      Number(
                        CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                      ) === 1 &&
                      Number(USER_DETAILS?.viewing_analytics_status) === 1 &&
                      isContineuwatching != undefined &&
                      isContineuwatching != null &&
                      isContineuwatching.length > 0 ? (
                        <button
                          onClick={() => handleVideoDislay(1)}
                          className="glow-on-hover"
                        >
                          Continue Watching
                        </button>
                      ) : (
                        <button
                          onClick={() => handleVideoDislay(0)}
                          className="glow-on-hover"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            class="bi bi-play-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.445 8L5.694 4.052a.5.5 0 0 0-.694.417v7.062a.5.5 0 0 0 .694.417l5.75-3.948a.5.5 0 0 0 0-.834z" />
                          </svg>
                          Play
                        </button>
                      )}
                    </div>
                    <ReactTooltip type="dark" />
                  </div>
                  {isContineuwatching != undefined &&
                    isContineuwatching != null &&
                    isContineuwatching.length > 0 && (
                      <div
                        className="col-3"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          heightL: "100%",
                        }}
                      >
                        {" "}
                        <div className="col-12 p-0 m-0">
                          {" "}
                          {AUTHENTICATION === true &&
                            Number(
                              CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                            ) == 1 &&
                            Number(USER_DETAILS?.viewing_analytics_status) ==
                              1 &&
                            isContineuwatching != undefined &&
                            isContineuwatching != null &&
                            isContineuwatching.length > 0 && (
                              <div
                                className="col-12 p-0 m-0"
                                style={{
                                  whiteSpace: "nowrap",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100%",
                                  top: -12,
                                }}
                              >
                                <div
                                  className="p-0 m-0"
                                  style={{
                                    color: "white",
                                    fontFamily:
                                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                  }}
                                >
                                  {/* {matchedEpisode?.title} */}
                                </div>
                                <div className="">
                                  <div
                                    className=""
                                    // style={{
                                    //   zIndex: 200,
                                    // }}
                                    data-tip={
                                      isContineuwatching != undefined &&
                                      isContineuwatching != null &&
                                      isContineuwatching.length > 0
                                        ? `${convertDurationToMinutes(
                                            isContineuwatching?.[0]
                                              ?.totalDuration
                                          ) -
                                            convertDurationToMinutes(
                                              isContineuwatching?.[0]?.duration
                                            )} Min Remaining`
                                        : ""
                                    }
                                  >
                                    {AUTHENTICATION === true &&
                                    Number(
                                      CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                    ) == 1 &&
                                    Number(
                                      USER_DETAILS?.viewing_analytics_status
                                    ) == 1 &&
                                    isContineuwatching != undefined &&
                                    isContineuwatching != null &&
                                    isContineuwatching.length > 0 ? (
                                      <ProgressBarForMovie
                                        completed={percentageViewed}
                                        height="40px"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {AUTHENTICATION === true &&
                                  Number(
                                    CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                  ) == 1 &&
                                  Number(
                                    USER_DETAILS?.viewing_analytics_status
                                  ) == 1 &&
                                  isContineuwatching != undefined &&
                                  isContineuwatching != null &&
                                  isContineuwatching.length > 0 ? (
                                    <span className="mb-2">
                                      {convertDurationToMinutes(
                                        isContineuwatching?.[0]?.duration
                                      )}{" "}
                                      of{" "}
                                      {convertDurationToMinutes(
                                        isContineuwatching?.[0]?.totalDuration
                                      )}{" "}
                                      Min
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  {AUTHENTICATION === true &&
                    Number(CURRENT_PLATFORM_DATA?.viewing_analytics_enabled) ==
                      1 &&
                    Number(USER_DETAILS?.viewing_analytics_status) == 1 &&
                    isContineuwatching != undefined &&
                    isContineuwatching != null &&
                    isContineuwatching.length > 0 && (
                      <div
                        className="col-2 mt-4"
                        onClick={() => handleVideoDislay(0)}
                        style={{
                          cursor: "pointer",
                          top: -10,
                        }}
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="100"
                            viewBox="0 0 50 50"
                            width="100"
                            data-tip="Start over"
                            style={{
                              cursor: "pointer",
                            }}

                            // style={{
                            //   whiteSpace: "nowrap",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            //   height: "100%",
                            //   top: -12,
                            // }}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"
                              fill="#FFFFFF"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  <div className="col-2 mt-3">
                    {" "}
                    <div
                      className=" col-2 "
                      data-tip="Trailer"
                      style={{ top: -5, right: 55, cursor: "pointer" }}
                    >
                      <div
                        className="icon-wrapper-fav"
                        data-tip="Trailer"
                        onClick={handleTeaser}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="40px"
                          viewBox="0 -960 960 960"
                          width="40px"
                          fill="white"
                        >
                          <path d="M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v560q0 33-23.5 56.5T800-120H160Zm0-80h80v-80h-80v80Zm560 0h80v-80h-80v80Zm-280-80h80v-240h-80v240Zm-280-80h80v-80h-80v80Zm560 0h80v-80h-80v80ZM160-520h80v-80h-80v80Zm560 0h80v-80h-80v80Zm-240-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm-320-80h80v-80h-80v80Zm560 0h80v-80h-80v80ZM320-200h320v-560H320v560Zm0-560h320-320Z" />
                        </svg>
                      </div>
                      <ReactTooltip type="dark" />
                    </div>
                  </div>

                  <div
                    className="col-2 mt-3"
                    style={{ cursor: "pointer", top: -5, right: 76 }}
                  >
                    {USER_DETAILS != undefined &&
                    USER_DETAILS != null &&
                    AUTHENTICATION == true ? (
                      <div
                        className="icon-wrapper-fav"
                        onClick={handleFavouriteFill}
                      >
                        {isFavourite ? (
                          <img
                            height="40px"
                            width="40px"
                            src="images/login/h4.png"
                            alt="Favorite icon"
                          />
                        ) : (
                          <img
                            height="40px"
                            width="40px"
                            src="images/login/h3.png"
                            alt="Not favorite icon"
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <ReactTooltip type="dark" />
                  </div>

                  <div
                    className="col-12"
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    {ads && ads.length > 0 ? <AdsPage ads={ads} /> : ""}
                  </div>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  ASSET_DATA: getMovieDataSelector,
  GENERAL_GENRE_DETAILS: getgeneralGenreDetailsSelector,
  GENERAL_TAG_DETAILS: getgeneralTagDetailsSelector,
  GENERAL_LANGUAGE_DETAILS: getgeneralLanguageDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GET_FAVOURITES_DATA: getFavouriteSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  GENERAL_ADS_DETAILS: getGeneralAdsDetailSelector,

  DEVICE_TOKEN: getDeviceTokenSelector,
  CONFIGURATION_DAS: configurationSelector,
});
export default connect(mapStateToProps)(MovieAssetViewPage);
