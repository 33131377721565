/** @format */

import base64 from "base-64";
import videojs from "video.js";
import { newImageIpPath } from "../../App";
export const getPlayList = (payload) => {
  let allChannelList = [];
  let selectedDrmCategory;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }

  var keyServer;

  let startOverUrl;
  // console.log(
  //   "payload?.ASSET_IDs_BASED_ON_LCN" +
  //     JSON.stringify(payload?.ASSET_IDs_BASED_ON_LCN)
  // );
  let assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map((item) => {
    return payload?.ASSET_DETAILS[item];
  });

  let catchup = 0;
  assetDetails?.length > 0 &&
    assetDetails?.map((a, index) => {
      if (selectedDrmCategory == 1) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let firstPartUrl = a?.assetUrl?.substring(0, pos);

        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl = firstPartUrl + "-" + timeStamp + "-now.mpd";
        } else {
          catchup = 0;

          startOverUrl = firstPartUrl + ".mpd";
        }
        let url1 = firstPartUrl + ".mpd";

        let certificate;

        let widewineLicense = payload?.DRM_DETAILS?.data[0]?.widewineLicense?.trim();
        let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
        let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
        let drmId = a?.drmId;
        let PlayState = "1";
        let DrmSystem = "Widevine";
        let date = new Date();

        let month1 = date.getMonth() + 1;
        let nowStampString =
          date.getFullYear() +
          "-" +
          month1 +
          "-" +
          date.getDate() +
          " " +
          0 +
          ":" +
          date.getHours() +
          ":" +
          date.getMinutes();

        let ContentUrl = url1;
        let UniqueDeviceId = payload.SAVED_DEVICE_TOCKEN;
        let DeviceTypeName = payload.SAVED_DEVICE_TOCKEN;
        let LoginName = base64.encode(loginName);
        let Password = base64.encode(loginPassword);
        let KeyId = base64.encode(drmId);
        let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);

        let ContentUrlEncode = base64.encode(ContentUrl);
        let NowStamp = base64.encode(nowStampString);
        let DeviceTypeNameEncode = base64.encode(DeviceTypeName);

        keyServer =
          widewineLicense +
          "?" +
          "PlayState=" +
          PlayState +
          "&" +
          "DrmSystem=" +
          DrmSystem +
          "&" +
          "LoginName=" +
          LoginName +
          "&" +
          "Password=" +
          Password +
          "&" +
          "KeyId=" +
          KeyId +
          "&" +
          "UniqueDeviceId=" +
          UniqueDeviceIdEncode +
          "&" +
          "ContentUrl=" +
          ContentUrlEncode +
          "&" +
          "NowStamp=" +
          NowStamp +
          "&" +
          "DeviceTypeName=" +
          DeviceTypeNameEncode;
      } else if (selectedDrmCategory == 2) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let firstPartUrl = a?.assetUrl?.substring(0, pos);

        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl = firstPartUrl + "-" + timeStamp + "-now.mpd";
        } else {
          catchup = 0;

          startOverUrl = firstPartUrl + ".mpd";
        }

        keyServer = payload?.AUTHENTICATION
          ? payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim() +
            "?assetId=" +
            a?.id +
            "&categoryId=1&userId=" +
            payload.USER_DETAILS?.id
          : payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
      }

      let flag = 0;
      let date = new Date();

      let timestamp = date?.getTime();

      // ***************PACKAGE_DETAILS**************
      for (var p = 0; p < a?.packages?.length; p++) {
        let stopDate =
          payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

        let stopDateTimeStamp = parseInt(Date.parse(stopDate));
        if (
          a?.packages[p]?.assetGroupId ==
            payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]
              ?.packageId &&
          timestamp < stopDateTimeStamp
        ) {
          flag = 1;
        }
      }
      // ************ALACARTE_DETAILS***********************
      let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
      let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

      if (
        a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
        payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampAlacarte
      ) {
        flag = 1;
      }
      // *****************PAY_PER_VIEW_DETAILS*************
      let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
      let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

      if (
        a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
        // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampPPV
      ) {
        flag = 1;
      }
      let data = {
        // src: startOverUrl,
        // src:
        //   "https://rsvod.dishhome.com.np/soapboxmovies/1percentOfSomethingS1EP2_5001/1percentOfSomethingS1EP2_5001.mp4/index.mpd",
        // type: "application/dash+xml",
        // keySystems: {
        //   "com.widevine.alpha": keyServer,
        // },
        src: startOverUrl,
        type: "application/dash+xml",
        keySystems: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",

            getLicense: (emeOptions, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",

                  url: keyServer,

                  responseType: "arraybuffer",

                  body: keyMessage,
                },

                (err, response, responseBody) => {
                  if (err) {
                    callback(err);

                    return;
                  }

                  callback(null, responseBody);
                }
              );
            },
          },
        },
        name: a?.title,
        id: a?.id,
      };
      let title = a?.title;

      let name = a?.title;
      let default_status = false;
      if (index == 4) {
        default_status = true;
      }
      // console.log("playListDetailsinPLayer1" + JSON.stringify(a));

      let dataForPoster = {};
      if (
        payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId] != undefined
      ) {
        dataForPoster = {
          id: a?.liveAssetsPostAds[0]?.adId,
          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId]?.adv_url,
          time: Number(a?.liveAssetsPostAds[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }

      let VideoadConfig = {};

      if (payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId] != undefined) {
        let videoUrl =
          payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          } else {
            videoUrl = videoUrl;
          }
        }

        if (Number(a?.liveAssetsVideoAds[0]?.isSkipRequired) !== 0) {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,

            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),

            skip: Number(a?.liveAssetsVideoAds[0]?.skipBeyond), // Corrected inequality operator
          };
        } else {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,
            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),
          };
        }
      }

      let PosteradConfig = [dataForPoster];
      let sources = [data];
      allChannelList.push({
        sources,
        logo: a?.liveThumbnailPath + "thumbnail.png",
        poster: a?.liveThumbnailPath + "thumbnail.png",
        name,
        title,
        searchItem: title + a?.logicalChannelNumber,
        logicalChannelNumber: a?.logicalChannelNumber,
        epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
        default: default_status,
        flag,
        id: a?.id,
        catchup,
        timeStamp:
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,

        PosteradConfig: PosteradConfig,
        adConfig:
          payload?.adsEnabled == 1 || payload?.adsEnabled == true
            ? [VideoadConfig]
            : [],
      });
    });
  return allChannelList;
};
export const getPlayListforHLS = (payload) => {
  let allChannelList = [];

  let startOverUrl;

  let assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map((item) => {
    return payload?.ASSET_DETAILS[item];
  });

  let catchup = 0;
  assetDetails?.length > 0 &&
    assetDetails?.map((a, index) => {
      var pos = a?.assetUrl?.lastIndexOf(".");
      let firstPartUrl = a?.assetUrl?.substring(0, pos);

      if (
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
        a?.catchupRequired == 1
      ) {
        catchup = 1;
        let timeStamp =
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp /
          1000;

        startOverUrl = firstPartUrl + "-" + timeStamp + "-now.m3u8";
      } else {
        catchup = 0;

        startOverUrl = firstPartUrl + ".m3u8";
      }

      let flag = 0;
      let date = new Date();

      let timestamp = date?.getTime();

      // ***************PACKAGE_DETAILS**************
      for (var p = 0; p < a?.packages?.length; p++) {
        let stopDate =
          payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

        let stopDateTimeStamp = parseInt(Date.parse(stopDate));
        if (
          a?.packages[p]?.assetGroupId ==
            payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]
              ?.packageId &&
          timestamp < stopDateTimeStamp
        ) {
          flag = 1;
        }
      }
      // ************ALACARTE_DETAILS***********************
      let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
      let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

      if (
        a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
        payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampAlacarte
      ) {
        flag = 1;
      }
      // *****************PAY_PER_VIEW_DETAILS*************
      let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
      let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

      if (
        a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
        // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampPPV
      ) {
        flag = 1;
      }
      let data = {
        src: startOverUrl,
        // src:
        //   "http://216.48.179.84:80/hlslive/Admin/SDCDEMO101/live/Single_bitrate/master_1.m3u8",
        type: "application/x-mpegURL",
        name: a?.title,
        id: a?.id,
      };
      let title = a?.title;

      let name = a?.title;
      let default_status = false;
      if (index == 4) {
        default_status = true;
      }
      // console.log("playListDetailsinPLayer1" + JSON.stringify(a));

      let dataForPoster = {};
      if (
        payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId] != undefined
      ) {
        dataForPoster = {
          id: a?.liveAssetsPostAds[0]?.adId,
          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId]?.adv_url,
          time: Number(a?.liveAssetsPostAds[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }

      let VideoadConfig = {};

      if (payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId] != undefined) {
        let videoUrl =
          payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          } else {
            videoUrl = videoUrl;
          }
        }

        if (Number(a?.liveAssetsVideoAds[0]?.isSkipRequired) !== 0) {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,

            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),

            skip: Number(a?.liveAssetsVideoAds[0]?.skipBeyond), // Corrected inequality operator
          };
        } else {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,
            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),
          };
        }
      }

      let PosteradConfig = [dataForPoster];
      let sources = [data];
      allChannelList.push({
        sources,
        logo: a?.liveThumbnailPath + "thumbnail.png",
        poster: a?.liveThumbnailPath + "thumbnail.png",
        name,
        title,
        searchItem: title + a?.logicalChannelNumber,
        logicalChannelNumber: a?.logicalChannelNumber,
        epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
        default: default_status,
        flag,
        id: a?.id,
        catchup,
        timeStamp:
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,

        PosteradConfig: PosteradConfig,
        adConfig:
          payload?.adsEnabled == 1 || payload?.adsEnabled == true
            ? [VideoadConfig]
            : [],
      });
    });
  return allChannelList;
};

export const getPlayListForFairplay = (payload) => {
  let allChannelList = [];
  let selectedDrmCategory = payload?.DRM_DETAILS?.selectedDrmCategory || null;
  let fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
  let fairplayLicense = payload?.DRM_DETAILS?.data[0]?.fairplayLicense?.trim();
  let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
  let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
  let uniqueDeviceId = payload?.SAVED_DEVICE_TOCKEN;
  let deviceTypeName = payload?.DEVICE_NAME;

  const assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map(
    (item) => payload?.ASSET_DETAILS[item]
  );
  let catchup = 0;

  assetDetails?.forEach((a, index) => {
    let startOverUrl, keyServer;
    const pos = a?.assetUrl?.lastIndexOf(".");
    const firstPartUrl = a?.assetUrl?.substring(0, pos);
    let date = new Date();

    let flag = 0;
    let timestamp = date?.getTime();

    // ***************PACKAGE_DETAILS**************
    for (var p = 0; p < a?.packages?.length; p++) {
      let stopDate =
        payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

      let stopDateTimeStamp = parseInt(Date.parse(stopDate));
      if (
        a?.packages[p]?.assetGroupId ==
          payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]?.packageId &&
        timestamp < stopDateTimeStamp
      ) {
        flag = 1;
      }
    }
    // ************ALACARTE_DETAILS***********************
    let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
    let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

    if (
      a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
      payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampAlacarte
    ) {
      flag = 1;
    }
    // *****************PAY_PER_VIEW_DETAILS*************
    let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
    let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

    if (
      a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
      // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampPPV
    ) {
      flag = 1;
    }
    // Catch-up or live URL
    if (
      payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg &&
      a?.catchupRequired === 1
    ) {
      const timeStamp =
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg.startTimeTimestamp /
        1000;
      startOverUrl = `${firstPartUrl}-${timeStamp}-now.m3u8`;
      catchup = 1;
    } else {
      startOverUrl = `${firstPartUrl}.m3u8`;
      catchup = 0;
    }

    // DRM Category 1
    if (selectedDrmCategory === 1) {
      const encodedParams = {
        PlayState: "1",
        DrmSystem: "Fairplay",
        LoginName: base64.encode(loginName),
        Password: base64.encode(loginPassword),
        KeyId: base64.encode(a?.drmId),
        UniqueDeviceId: base64.encode(uniqueDeviceId),
        ContentUrl: base64.encode(startOverUrl),
        NowStamp: base64.encode(new Date().toISOString()),
        DeviceTypeName: base64.encode(deviceTypeName),
      };

      keyServer = `${fairplayLicense}?${Object.entries(encodedParams)
        .map(([key, val]) => `${key}=${val}`)
        .join("&")}`;
    }

    // DRM Category 2
    if (selectedDrmCategory === 2) {
      keyServer = payload?.AUTHENTICATION
        ? `${fairplayLicense}?assetId=${a?.id}&categoryId=1&userId=${payload?.USER_DETAILS?.id}`
        : fairplayLicense;
    }

    // Fetch DRM Certificate and License
    const getCertificate = (emeOptions, callback) => {
      videojs.xhr(
        {
          method: "GET",
          url: fairplayCertificate,
          responseType: "arraybuffer",
        },
        (err, response, responseBody) => {
          if (err) return callback(err);
          callback(null, new Uint8Array(responseBody));
        }
      );
    };

    const getLicense = (emeOptions, contentId, keyMessage, callback) => {
      videojs.xhr(
        {
          method: "POST",
          url: keyServer,
          responseType: "string",
          body: keyMessage,
        },
        (err, response, responseBody) => {
          if (err) return callback(err);
          callback(null, licenseRequestLoaded(responseBody));
        }
      );
    };

    // Ad Configuration
    const adConfig = getAdConfig(payload, a);

    // Build Channel Data
    allChannelList.push({
      sources: [
        {
          src: startOverUrl,
          type: "application/x-mpegURL",
          keySystems: {
            "com.apple.fps.1_0": {
              videoRobustness: "SW_SECURE_CRYPTO",
              getCertificate,
              getLicense,
            },
          },
        },
      ],
      logo: `${a?.liveThumbnailPath}thumbnail.png`,
      poster: `${a?.liveThumbnailPath}thumbnail.png`,
      name: a?.title,
      title: a?.title,
      searchItem: `${a?.title}${a?.logicalChannelNumber}`,
      logicalChannelNumber: a?.logicalChannelNumber,
      epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
      default: index === 4,
      flag,
      id: a?.id,
      drm: a?.drmId,
      catchup,
      timeStamp:
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,
      PosteradConfig: adConfig.PosteradConfig,

        adConfig:
          payload?.adsEnabled == 1 || payload?.adsEnabled == true
            ? [adConfig.VideoadConfig]
            : [],
    });
  });

  return allChannelList;
};

// Helper function for ad configuration
const getAdConfig = (payload, asset) => {
  const posterAd =
    payload?.GENERAl_POST_ADS[asset?.liveAssetsPostAds?.[0]?.adId];
  const videoAd = payload?.VIDEO_ADS[asset?.liveAssetsVideoAds?.[0]?.adId];

  return {
    PosteradConfig: posterAd
      ? [
          {
            id: asset?.liveAssetsPostAds[0]?.adId,
            src: `${newImageIpPath}${posterAd.adv_url}`,
            time: Number(asset?.liveAssetsPostAds[0]?.duration),
            closeButtonInMilisec: 2000,
          },
        ]
      : [],
    VideoadConfig: videoAd
      ? [
          {
            id: asset?.liveAssetsVideoAds[0]?.adId,
            src: videoAd?.videoUrl?.includes("http")
              ? videoAd.videoUrl
              : `${newImageIpPath}${videoAd.videoUrl}`,
            time: Number(asset?.liveAssetsVideoAds[0]?.duration),
            skip: Number(asset?.liveAssetsVideoAds[0]?.isSkipRequired)
              ? Number(asset?.liveAssetsVideoAds[0]?.skipBeyond)
              : undefined,
          },
        ]
      : [],
  };
};

// Helper function to check flag
const checkFlag = (payload, asset) => {
  const timestamp = Date.now();

  const packageValid = asset?.packages?.some((pkg) => {
    const stopDate = payload?.PACKAGE_DETAILS?.[pkg.assetGroupId]?.stopDate;
    return (
      pkg.assetGroupId ===
        payload?.PACKAGE_DETAILS?.[pkg.assetGroupId]?.packageId &&
      timestamp < Date.parse(stopDate)
    );
  });

  const alacarteValid =
    payload?.ALACARTE_DETAILS?.[asset?.id] &&
    timestamp < Date.parse(payload?.ALACARTE_DETAILS?.[asset?.id]?.expiryDate);

  const ppvValid =
    payload?.PAY_PER_VIEW_DETAILS?.[1]?.[asset?.id] &&
    timestamp <
      Date.parse(payload?.PAY_PER_VIEW_DETAILS?.[1]?.[asset?.id]?.expiryDate);

  return packageValid || alacarteValid || ppvValid ? 1 : 0;
};

// Helper function for license request loaded
const licenseRequestLoaded = (stringbuffer) => {
  const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
  }
  return bytes;
};
