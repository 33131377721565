/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";

var qs = require("qs");

export const loginAsync = async (payload) => {

  // alert("loginV3"+JSON.stringify(payload))

  const ConstantHeaders = HeadersAcessToken();
  return await axiosInstance()
    .post("/loginV3", payload, {
      headers: ConstantHeaders,
    })
    .then((response) => {

      return response;
    })

    .catch((error) => {
      //alert(JSON.stringify(error))
      if (error.message == "Network Error") {
        return Promise.reject(error);
      } else {
        return Promise.reject(error?.response?.data);
      }
    });
};

export const signupAsync = async (payload) => {
  const data = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    emailId: payload.emailId,
    mobile: payload.mobile,
    password: payload.confirmPassword,
  };

  try {
    const response = await axiosInstance().post("/user/register", data);
    return response;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};
export const logoutAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/logoutV1", payload);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const userDetailsAsync = async (payload) => {
  try {
 
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    const userInfoResponse = await axiosInstance().post(
      `/user/userInfoV2/${ciphertext}`
    );

    return userInfoResponse?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};