/** @format */

import React, { useState } from "react";
import EpgPage from "./EpgPage";
// import * as PROFILE_ACION from "./../../Redux/ProfileDetails/actions";
import * as NOTIFICATION_ACTION from "./../../Redux/PushNotification/actions";
import * as ACTION_TYPES from "../../Redux/EPG/action";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as ACTION_TYPE from "./../../Redux/EPG/action";
import { platformsSelector } from "../../Redux/platforms/selectors";
import { createStructuredSelector } from "reselect";
import FooterPage from "../footerComponent/FooterPage";
import EpgTabScroll from "./EpgTabScroll";
import {
  epgDateSelector,
  epgSelector,
  epgStateSelector,
} from "../../Redux/EPG/seleector";
import NewLoadingPage from "../NewLoadingPage";
import SearchPage from "../SearchComponent/SearchPage";
import MainHeader from "../MainHeader";
import { getPackageDetailsSelector } from "../../Redux/package&subscriptions/selector";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";
import { generalSettingsSelector } from "../../Redux/live/selectors";

const EpgContainer = ({
  PLATFORM_ID,
  EPG_STATE,
  EPG,
  searchState,
  setSearchState,
  setSearchKey,
  searchKey,
  EPG_DATE,
  setIsVideoPlayer,
  setClearSearchState,
  clearSearchState,
  PACKAGE_DETAILS,
  USER_DETAILS,
  selectedCategory,
  setSelectedCategory,
  AUTHENTICATION,
  GENERAL_DATA,
}) => {
  const dispatch = useDispatch();
  document.title = GENERAL_DATA?.appTitle;
  useEffect(() => {
    setIsVideoPlayer(true);
    if (searchState == false) {
      document.body.style.overflowY = "scroll";
    }
  }, [searchState]);

  let profileData;
  if (USER_DETAILS != undefined && USER_DETAILS != null) {
    profileData = {
      id: USER_DETAILS?.id,
      account_id: PLATFORM_ID?.currentPlatformId,
    };
  }

  useEffect(() => {
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
    var arr2 = todayDate.split("/");
    //console.log("dghdsh ......", arr2);
    var arr3 = arr2[2] + "/" + arr2[1];
    //console.log("concatinated array", arr3);
    let programMonth = arr2[1];
    let programmeDate = arr2[2];
    if (parseInt(programMonth) <= 9) {
      programMonth = "0" + programMonth;
    }
    if (parseInt(programmeDate) <= 9) {
      programmeDate = "0" + programmeDate;
    }
    // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

    let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
    const data1 = {
      account_id: PLATFORM_ID?.currentPlatformId,
      programmeDate: date,
      limit: 100,
    };

    let startTimeStamp = new Date(
      programMonth + "/" + programmeDate + "/" + arr2[0]
    ).setHours(0, 0, 0, 0);
    let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
    let data = {
      startTimeTimestamp: startTimeStamp,
      stopTimeTimestamp: stopTimeStamp,
      account_id: PLATFORM_ID?.currentPlatformId,
      offset: 0,
      limit: 1000,
      a: false,
      programmeDate: date,
    };
    dispatch(ACTION_TYPES.getEpgDetailsStart(data));
  }, []);
  // const keysArray = Object.keys(PACKAGE_DETAILS);
  let keysArray;
  if (PACKAGE_DETAILS != undefined && PACKAGE_DETAILS != null) {
    keysArray = Object.keys(PACKAGE_DETAILS);
  }

  useEffect(() => {
    if (AUTHENTICATION) {
      // dispatch(
      //   NOTIFICATION_ACTION.getPushNotificationStart({
      //     packageId: JSON.stringify(keysArray),
      //   })
      // );
    }
    let dateArray1 = [];
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

    var arr2 = todayDate.split("/");
    //console.log("dghdsh ......", arr2);
    var arr3 = arr2[2] + "/" + arr2[1];
    //console.log("concatinated array", arr3);

    dateArray1.push({ date: todayDate });
    if (dateArray1 != undefined && dateArray1 != null) {
      // alert(dateArray1?.date);
      dispatch(ACTION_TYPE.getEpgStateSuccess(dateArray1?.date));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {}, []);
  return (
    <div
      style={
        {
          // backgroundColor: "#38393a",
          // backgroundImage: `url(${backgroundUrl})`,
          // backgroundAttachment: "fixed",
          // backgroundBlendMode: "hard-light"
        }
      }
    >
      <div>
        <div className="">
          <MainHeader
            setSearchState={setSearchState}
            setSearchKey={setSearchKey}
            setClearSearchState={setClearSearchState}
            clearSearchState={clearSearchState}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          {searchState == true ? (
            <SearchPage
              searchKey={searchKey}
              setSearchState={setSearchState}
              setClearSearchState={setClearSearchState}
            />
          ) : (
            ""
          )}
        </div>

        <div style={{ height: "80px", color: "black" }}></div>
        {EPG_STATE?.isLoading == false ? (
          <div>
            {EPG_STATE.isLoading == false &&
            EPG != undefined &&
            EPG != null &&
            EPG?.epg != undefined &&
            EPG?.epg != null ? (
              <EpgPage setIsVideoPlayer={setIsVideoPlayer} />
            ) : (
              <NewLoadingPage />
            )}
          </div>
        ) : (
          <>
            <div
              style={{
                height: "70vh",
                width: "100%",
              }}
            >
              <EpgTabScroll onScrollToNow={EPG_DATE} />
              <NewLoadingPage />
            </div>
          </>
        )}
        <div>
          <div style={{ height: "80px" }}>
            <FooterPage />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  PLATFORM_ID: platformsSelector,
  EPG_DATE: epgDateSelector,

  EPG_STATE: epgStateSelector,
  EPG: epgSelector,

  PACKAGE_DETAILS: getPackageDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(EpgContainer);
