/** @format */

import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import MoonLoader from "react-spinners/MoonLoader";
import "../loader/loadingPlugin.js"; // Import the loading plugin
import "../retryPlugin/retry.js"; // Import the loading plugin

// This imports the functional component from the previous sample.
import base64 from "base-64";
import "videojs-contrib-eme";

import { createStructuredSelector } from "reselect";
import "./../videoPlayer.css";
import {
  getEpgVideoUrlSelector,
  getIsCatchupRequiredSelector,
  getLiveChannelDataSelector,
} from "../../Redux/VideoRedux/selector";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import { getDrmDetailsSelector } from "../../Redux/DRM integration/selectors";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../Redux/package&subscriptions/actions";

import "videojs-hotkeys";
import { useHistory, useLocation } from "react-router-dom";
import "video.js/dist/video-js.css";
import "videojs-overlay/dist/videojs-overlay.css";
//import "./../skins/chrome/videojs.min.css";
import "./../skins/party/ima.css";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import { connect, useDispatch } from "react-redux";
import { baseName } from "../IpConstantFile/IpConstantFile";
import { platformsSelector } from "../../Redux/platforms/selectors";
import * as CHECK_DEVICES from "./../../Redux/Delete Device/actions";

import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { getDeviceStatusSelector } from "../../Redux/Delete Device/selectors";
import { getLiveAssetsDataSelector } from "../../Redux/Notication Assets redux/selectors";
import { getCurrentTimeZone, getPiPStateFromStorage, newImageIpPath, wsLive } from "../../App.js";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";
import { configurationSelector } from "../../Redux/DATA-analytics/selector.js";
import {
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../Redux/package&subscriptions/selector.js";
// import "../LiveVideoPlayerComponent/components/vroll.js";
import { getgeneralVideoAdsDetailsSelector } from "../../Redux/AllLiveDetails/selectors.js";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors.js";
import { getDefaultPlatformsSelector } from "../../Redux/DefaultPlatform/selectors.js";
import { getSaveDeviceTockenSelector } from "../../Redux/SaveDeviceTocken/selectors.js";
import "../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import { generalSettingsSelector } from "../../Redux/live/selectors.js";

const LiveDetailsPlayer = ({
  DRM_DETAILS,
  setIsVideoPlayer,
  CURRENT_PLATFORM,

  DEVICE_NAME,
  DEVICE_TOKEN,
  AUTHENTICATION,
  DEVICE_STATUS,
  ASSET_DATA,
  CONFIGURATION_DAS,
  PAY_PER_VIEW_DETAILS,
  USER_DETAILS,

  SAVED_DEVICE_TOCKEN,
  isMuted,
  flag,
  GENERAL_DATA,
}) => {
  document.title = ASSET_DATA?.title;
  let location = useLocation().state;
  var startTime, endTime;
  const timeZone = getCurrentTimeZone();

  const dispatch = useDispatch();
  const [signInMessage, setSignInMessage] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  let videoType = null;

  let posterUrl = ASSET_DATA?.liveThumbnailPath + "/thumbnail.png";
  // console.log("ASSET_DATA" + JSON.stringify(ASSET_DATA));
  const [isLoading, setIsLoading] = useState();
  const playerRef = React.useRef(null);
  if (ASSET_DATA?.assetUrl?.search(".mp4") >= 0) {
    videoType = "video/mp4";
  } else if (ASSET_DATA?.assetUrl?.search(".m3u8") >= 0) {
    videoType = "application/x-mpegURL";
  } else if (ASSET_DATA?.assetUrl.search(".mpd")) {
    videoType = "application/dash+xml";
  }

  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  // let isMuted = isMuted;

  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);

  let startOverUrl;

  let startOverUrl2;
  let firstPartUrl = null;
  let pos;
  if (ASSET_DATA != undefined && ASSET_DATA != null) {
    pos = ASSET_DATA?.assetUrl?.lastIndexOf(".");
    firstPartUrl = ASSET_DATA?.assetUrl?.substring(0, pos);
  }

  startOverUrl = firstPartUrl + ".mpd";
  startOverUrl2 = firstPartUrl + ".m3u8";
  // }
  let posEpg = ASSET_DATA?.assetUrl?.lastIndexOf(".");
  let firstPartUrlEpg = ASSET_DATA?.assetUrl?.substring(0, posEpg);
  let url1 = firstPartUrlEpg + ".mpd";
  let url2 = firstPartUrlEpg + ".m3u8";

  var base64DecodeUint8Array = function(a) {
    var b = window.atob(a),
      c = b.length,
      d = new Uint8Array(new ArrayBuffer(c));
    for (let i = 0; i < c; i++) d[i] = b.charCodeAt(i);
    return d;
  };

  var arrayToString = function(a) {
    var b = new Uint16Array(a.buffer);
    return String.fromCharCode.apply(null, b);
  };
  let contentId;
  function extractContentId(initData) {
    contentId = arrayToString(initData);
    // contentId is passed up as a URI, from which the host must be extracted:
    var link = document.createElement("a");
    link.href = contentId;
    return link.hostname;
  }

  function licenseRequestLoaded(stringbuffer) {
    // console.log("kkk" + stringbuffer);
    const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
    }
    //console.log(bytes);
    return bytes;
  }
  let certificate;
  let hlsKey = Number(GENERAL_DATA?.playOnlyHls);

  var selectedDrmCategory;
  var keyServer1;
  var keyServer;
  var fairplayCertificate;
  if (DRM_DETAILS != null && DRM_DETAILS != undefined) {
    selectedDrmCategory = DRM_DETAILS.selectedDrmCategory;
  }

  if (selectedDrmCategory == 1) {
    let widewineLicense = DRM_DETAILS?.data[0]?.widewineLicense?.trim();
    let fairplayLicense = DRM_DETAILS?.data[0]?.fairplayLicense?.trim();
    fairplayCertificate = DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
    let loginName = DRM_DETAILS?.data[0]?.subscriberName?.trim();
    let loginPassword = DRM_DETAILS?.data[0]?.subscriberPassword;
    let drmId = ASSET_DATA?.drmId;
    let PlayState = "1";
    let DrmSystem = "Widevine";
    let DrmSystem2 = "Fairplay";
    let date = new Date();
    let month = date.getMonth() + 1;
    let nowStampString =
      date.getFullYear() +
      "-" +
      month +
      "-" +
      date.getDate() +
      " " +
      0 +
      ":" +
      date.getHours() +
      ":" +
      date.getMinutes();

    let ContentUrl = url1;
    let ContentUrl2 = url2;
    let UniqueDeviceId = SAVED_DEVICE_TOCKEN;
    let UniqueDeviceId1 = SAVED_DEVICE_TOCKEN;
    let DeviceTypeName = DEVICE_NAME;
    let DeviceTypeName1 = DEVICE_NAME;
    let LoginName = base64.encode(loginName);
    let Password = base64.encode(loginPassword);
    let KeyId = base64.encode(drmId);
    let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);
    let UniqueDeviceIdEncode1 = base64.encode(UniqueDeviceId1);

    let ContentUrlEncode = base64.encode(ContentUrl);
    let ContentUrlEncode1 = base64.encode(ContentUrl2);
    let NowStamp = base64.encode(nowStampString);
    let DeviceTypeNameEncode = base64.encode(DeviceTypeName);
    let DeviceTypeNameEncode1 = base64.encode(DeviceTypeName1);

    keyServer =
      widewineLicense +
      "?" +
      "PlayState=" +
      PlayState +
      "&" +
      "DrmSystem=" +
      DrmSystem +
      "&" +
      "LoginName=" +
      LoginName +
      "&" +
      "Password=" +
      Password +
      "&" +
      "KeyId=" +
      KeyId +
      "&" +
      "UniqueDeviceId=" +
      UniqueDeviceIdEncode +
      "&" +
      "ContentUrl=" +
      ContentUrlEncode +
      "&" +
      "NowStamp=" +
      NowStamp +
      "&" +
      "DeviceTypeName=" +
      DeviceTypeNameEncode;
    // console.log("keyserver****&&&&^^^^" + keyServer);

    keyServer1 =
      fairplayLicense +
      "?" +
      "PlayState=" +
      PlayState +
      "&" +
      "DrmSystem=" +
      DrmSystem2 +
      "&" +
      "LoginName=" +
      LoginName +
      "&" +
      "Password=" +
      Password +
      "&" +
      "KeyId=" +
      KeyId +
      "&" +
      "UniqueDeviceId=" +
      UniqueDeviceIdEncode1 +
      "&" +
      "ContentUrl=" +
      ContentUrlEncode1 +
      "&" +
      "NowStamp=" +
      NowStamp +
      "&" +
      "DeviceTypeName=" +
      DeviceTypeNameEncode1;
  } else if (selectedDrmCategory == 2) {
    if (window.WebKitMediaKeys) {
      fairplayCertificate = DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
      keyServer1 = AUTHENTICATION
        ? DRM_DETAILS?.data[0]?.fairplayLicense.trim() +
          "?assetId=" +
          ASSET_DATA?.id +
          "&categoryId=1&userId=" +
          USER_DETAILS?.id
        : DRM_DETAILS?.data[0]?.fairplayLicense.trim();
    } else if (window.MediaKeys) {
      keyServer = AUTHENTICATION
        ? DRM_DETAILS?.data[0]?.widewineLicense.trim() +
          "?assetId=" +
          ASSET_DATA?.id +
          "&categoryId=1&userId=" +
          USER_DETAILS?.id
        : DRM_DETAILS?.data[0]?.widewineLicense.trim();
    } else {
      keyServer = AUTHENTICATION
        ? DRM_DETAILS?.data[0]?.widewineLicense.trim() +
          "?assetId=" +
          ASSET_DATA?.id +
          "&categoryId=1&userId=" +
          USER_DETAILS?.id
        : DRM_DETAILS?.data[0]?.widewineLicense.trim();
    }
  }

  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    liveui: false,
    qualitySelector: false, // Disable quality selector

    fluid: true,
    preload: "auto",

    aspectRatio: "16:9",
    playlistPicker: false,
    muted: isMuted,
  };
  const history = useHistory();

  let platformLogo = newImageIpPath + CURRENT_PLATFORM?.productLogo;
  let navigateLocation = null;
  let navPath = null;
  navigateLocation = baseName + "epgContainer";
  navPath = "/epgContainer";

  let player;
  const videoNode = useRef(null);
  // console.log("startOverUrl", startOverUrl);
  let dataAnalytics = {};

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let LiveIntervalForSendingDataToLocal = setInterval(
        () => {
          // Check if sessionStorage.getItem("buffering") is false
          if (sessionStorage.getItem("buffering") === "false") {
            let dataObject =
              JSON.parse(localStorage.getItem("MovieDataToLocal")) || {};

            if (
              dataAnalytics &&
              typeof dataAnalytics === "object" &&
              JSON.parse(localStorage.getItem("isPlaying")) == true
            ) {
              // console.log("dataAnalytics.duration" + dataAnalytics.duration);

              if (
                dataAnalytics.assetId !== undefined &&
                dataAnalytics.categoryId !== undefined
              ) {
                const uniqueKey =
                  dataAnalytics.assetId + "_" + dataAnalytics.categoryId;

                // If the data already exists in local storage, add the new duration to the existing one
                if (dataObject[uniqueKey]) {
                  dataObject[uniqueKey].duration += dataAnalytics.duration;
                } else {
                  // Otherwise, create a new entry in the object
                  dataObject[uniqueKey] = dataAnalytics;
                }

                // Update local storage with the modified object
                localStorage.setItem(
                  "MovieDataToLocal",
                  JSON.stringify(dataObject)
                );
              }
              startTime = new Date().getTime();
            }
          }
        },
        CONFIGURATION_DAS?.frequencyForStoringDataInApp == undefined
          ? 30000
          : CONFIGURATION_DAS?.frequencyForStoringDataInApp
      );

      return () => {
        clearInterval(LiveIntervalForSendingDataToLocal);
      };
    }
  }, []);
  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let interval = setInterval(
        () => {
          let dataAnalyticsForAPI = {};
          if (
            CURRENT_PLATFORM != undefined &&
            CURRENT_PLATFORM != null &&
            USER_DETAILS != undefined &&
            USER_DETAILS != null &&
            DEVICE_TOKEN != null &&
            DEVICE_TOKEN != undefined
          ) {
            dataAnalyticsForAPI = {
              platformId: CURRENT_PLATFORM?.orgId,
              profileId: 1,
              deviceId: DEVICE_TOKEN,
              timeZone: timeZone,

              userId: USER_DETAILS?.id,
              assets: {},
            };
          }
          let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));
          if (dataObject && typeof dataObject === "object") {
            const keys = Object.keys(dataObject);
            let finalData = [];

            keys.forEach((a) => {
              finalData?.push(dataObject?.[a]);
            });

            // console.log("dataObject" + JSON.stringify(finalData));
            if (finalData != undefined && finalData != null) {
              dataAnalyticsForAPI.assets = JSON.stringify(finalData);
            }

            // console.log("MovieDataToLocal" + JSON.stringify(MovieData));
            // alert("in LIVE Player sending Server" + JSON.stringify(dataObject));

            let data2;
            if (
              USER_DETAILS != undefined &&
              USER_DETAILS != null &&
              CONFIGURATION_DAS != null &&
              CONFIGURATION_DAS != undefined
            ) {
              data2 = {
                dataAnalyticsForAPI: dataAnalyticsForAPI,
                ServerIp: CONFIGURATION_DAS?.analyticsIp,
              };
            }
            dispatch(DATA_ANALYTICS.sendingDataToDasStart(data2));
            // startTime = new Date().getTime();
          }
        },
        CONFIGURATION_DAS?.frequencyForSendingDataToServer == undefined
          ? 300000
          : Number(CONFIGURATION_DAS?.frequencyForSendingDataToServer) + 6500
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);
  useEffect(() => {
    player = videojs(
      videoNode.current,
      videoJsOptions,
      function onPlayerReady() {
        if (hlsKey == 0) {
          if (window.WebKitMediaKeys) {
            player.src({
              src: url2,
              type: "application/x-mpegURL",
              keySystems: {
                "com.apple.fps.1_0": {
                  videoRobustness: "SW_SECURE_CRYPTO",
                  getCertificate: (emeOptions, callback) => {
                    videojs.xhr(
                      {
                        method: "GET",
                        url: fairplayCertificate,
                        responseType: "arraybuffer",
                      },
                      (err, response, responseBody) => {
                        if (err) {
                          callback(err);
                          return;
                        }
                        // certificate = new Uint8Array(responseBody);
                        callback(null, new Uint8Array(responseBody));
                      }
                    );
                  },
                  getContentId: (emeOptions, initData) => {
                    const contentId = extractContentId(initData);
                    return contentId;
                  },
                  getLicense: (emeOptions, contentId, keyMessage, callback) => {
                    videojs.xhr(
                      {
                        method: "POST",
                        url: keyServer1,
                        responseType: "string",
                        body: keyMessage,
                      },
                      (err, response, responseBody) => {
                        if (err) {
                          callback(err);

                          return;
                        }

                        callback(null, licenseRequestLoaded(responseBody));
                      }
                    );
                  },
                },
              },
            });
          } else if (window.MediaKeys) {
            player.src({
              src: url1,
              // src:
              //   "https://media.axprod.net/TestVectors/v7-Clear/Manifest_1080p.mpd",
              // src:
              //   "https://soapboxplayer.riversilica.com/genesis/medialocal_1/video/dwapara.mp4",
              // type: "video/mp4",
              type: "application/dash+xml",
              keySystems: {
                "com.widevine.alpha": {
                  videoRobustness: "SW_SECURE_CRYPTO",

                  getLicense: (emeOptions, keyMessage, callback) => {
                    videojs.xhr(
                      {
                        method: "POST",

                        url: keyServer,

                        responseType: "arraybuffer",

                        body: keyMessage,
                      },

                      (err, response, responseBody) => {
                        if (err) {
                          callback(err);

                          return;
                        }

                        callback(null, responseBody);
                      }
                    );
                  },
                },
              },
            });
          } else {
            player.src({
              src: url1,
              // src:
              //   "https://media.axprod.net/TestVectors/v7-Clear/Manifest_1080p.mpd",
              // src:
              //   "https://soapboxplayer.riversilica.com/genesis/medialocal_1/video/dwapara.mp4",
              // type: "video/mp4",
              type: "application/dash+xml",
              keySystems: {
                "com.widevine.alpha": {
                  videoRobustness: "SW_SECURE_CRYPTO",

                  getLicense: (emeOptions, keyMessage, callback) => {
                    videojs.xhr(
                      {
                        method: "POST",

                        url: keyServer,

                        responseType: "arraybuffer",

                        body: keyMessage,
                      },

                      (err, response, responseBody) => {
                        if (err) {
                          callback(err);

                          return;
                        }

                        callback(null, responseBody);
                      }
                    );
                  },
                },
              },
            });
          }
        } else {
          player.src({
            src: url2,
            type: "application/x-mpegURL",
          });
        }
        player.on("error", function() {
          localStorage.setItem("isPlaying", false);
          errordisplay();
          var error = player.error();
          // console.log("Video.js Error:", error);
          player.pause();
        });
      }
    );
    function errordisplay() {
      // Hide the modal dialogs
      var modalDialogs = document.querySelectorAll(
        ".video-js .vjs-modal-dialog"
      );
      modalDialogs.forEach(function(dialog) {
        dialog.style.display = "none";
      });

      // Show the overlay image
      setShowOverlay(true);
    }
    function setPiPStateInStorage(isPiPMode) {
      localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
    }
    player.eme();
    player.loadingPlugin();

    player.displayPlatFormLogo(platformLogo);

    // Show overlay when the player is paused
    player?.on("pause", () => setShowOverlay(true));

    // Hide overlay when the player starts playing
    player?.on("play", () => setShowOverlay(false));

    // player.errorOverlayPlugin(navigateLocation);

    let i = 0;
    let time;
    let remainingTime;
    let j = 0;
    player.on("waiting", function() {
      localStorage.setItem("isPlaying", false);
      sessionStorage.setItem("buffering", true);

      this.addClass("vjs-waiting");
    });
    // player.on("waiting", function() {
    //   this.addClass("vjs-waiting");
    //   // player.reconnect();
    // });
    player.on("playing", function() {
      localStorage.setItem("isPlaying", true);
      this.removeClass("vjs-waiting");
      sessionStorage.setItem("buffering", false);

      // player.reconnect();
    });

    player.on("seeking", function(e) {
      if (j == 0 && player.currentTime() != 0) {
        remainingTime = player.currentTime() - 30;
        j++;
      }
      if (player.currentTime() < remainingTime) {
        if (AUTHENTICATION === true) {
          if (flag == 0) {
            setSignInMessage(true);

            // Swal.fire({
            //   icon: "warning",
            //   text:
            //     "Please subscribe or upgrade your package to view this content.",
            //   allowOutsideClick: () => !Swal.isLoading(),
            //   confirmButtonColor: "#3085d6",
            //   confirmButtonText: "Subscribe",
            //   cancelButtonText: "May be later",
            //   showCancelButton: true,
            //   showCloseButton: true,
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     window.open(subscribeUrl);
            //   }
            // });

            // window.location.href = navigateLocation;
          }
        }

        if (AUTHENTICATION === false) {
          setSignInMessage(true);
        }
        setTimeout(() => {
          if (AUTHENTICATION === true) {
            if (flag == 0) {
              player.pause();

              // history.replace(navPath);
            }
          }

          if (AUTHENTICATION === false) {
            player.pause();
            // history.replace(navPath);
          }
        }, 1000);
      }
    });

    player.on("loadeddata", function() {
      localStorage.setItem("inLiveVidioPlayer", "liveVideoPlayer");

      if (wsLive != null && flag != 0) {
        localStorage.setItem("liveAssetId", ASSET_DATA?.id);

        wsLive.send(
          JSON.stringify({
            type: "open",
            deviceId: DEVICE_TOKEN,
            assetId: ASSET_DATA?.id,
          })
        );
        localStorage.setItem("ConcurrentliveAssetId", ASSET_DATA?.id);
      }
    });
    player.on("loadedmetadata", function() {
      if (AUTHENTICATION === true && flag != 0) {
        document.addEventListener("keydown", (event) => {
          if (event.key === " ") {
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
          }
        });
      }
      localStorage.setItem("isPlaying", true);

      startTime = new Date().getTime();
    });
    let n = 0;

    let timeForAlert;

    player.on("timeupdate", function(e) {
      // alert(ASSET_DATA?.id);
      // console.log(JSON.stringify(ASSET_DATA));
      const isCurrentlyMuted = player.muted();

      const button = document.getElementById("mute-unmute");

      if (button) {
        button.onclick = function() {
          player.muted(!isCurrentlyMuted);
        };
      } else {
        console.error("Mute/Unmute button not found");
      }
      if (
        ASSET_DATA?.id ==
          PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.assetId &&
        // PAY_PER_VIEW_DETAILS?.[
        //   ASSET_DATA?.id
        // ]?.categoryId == 1 &&
        n == 0
      ) {
        let stopDatePPV =
          PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.expiryDate;
        let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

        if (
          ASSET_DATA?.id ==
            PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.assetId &&
          // PAY_PER_VIEW_DETAILS?.[
          //   ASSET_DATA?.id
          // ]?.categoryId == 1 &&
          new Date()?.getTime() > stopDateTimeStampPPV &&
          n == 0
        ) {
          player.pause();

          // history.replace("/container");
        }
        if (
          ASSET_DATA?.id ==
            PAY_PER_VIEW_DETAILS?.[1]?.[ASSET_DATA?.id]?.assetId &&
          // PAY_PER_VIEW_DETAILS?.[
          //   ASSET_DATA?.id
          // ]?.categoryId == 1 &&
          new Date()?.getTime() > stopDateTimeStampPPV &&
          n == 0
        ) {
          n = 1;
          if (player.isFullscreen()) {
            player.exitFullscreen();
          }

          const isPiPMode = getPiPStateFromStorage();

          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          Swal.fire({
            icon: "warning",
            text: "Your subscription for this content has expired",
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            // cancelButtonText: "May be later",
            // showConfirmButton:
            //   SELECTED_MOVIE_DATA?.isPPVRequired == 1 ? true : false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(
                PACKAGE_SUBSCRIPTION_ACTION.getPackageDetailsStart({
                  userId: USER_DETAILS?.id,
                })
              );
              // history.replace("/container");

              // window.location.href = navigateLocation;
            }
          });
        }
      }
      player.on("play", function() {
        localStorage.setItem("isPlaying", true);

        // Additional actions when the video starts playing
      });

      player.on("pause", function() {
        localStorage.setItem("isPlaying", false);
        // Additional actions when the video is paused
      });

      endTime = new Date().getTime();
      var timeSpent;
      var analyticsDataArray = [];

      if (startTime !== null) {
        timeSpent = (endTime - startTime) / 1000;

        // Check if timeSpent is a valid number
        if (!isNaN(timeSpent) && isFinite(timeSpent)) {
          if (ASSET_DATA !== null && ASSET_DATA !== undefined && flag != 0) {
            dataAnalytics = {
              assetId: ASSET_DATA?.id,
              categoryId: 1,
              duration: timeSpent,
            };
          }

          // Push the data or save it as needed
          // For example, assuming you have an array to store multiple analytics data
          analyticsDataArray.push(dataAnalytics);
        }
      }

      // console.log("arun 1" + videoAdsStatus);
      // console.log("arun 2" + player.currentTime());
      if (player.currentTime() < 1) {
        // console.log(" ooooooooooookmk  it is zerooooo" + player.currentTime());
        timeForAlert = 0;
        i = 0;
      }

      if (i == 0 && player.currentTime() > 0) {
        // console.log("!!!!!!!!!!!!     insilde ifff");
        timeForAlert = player.currentTime() + 30;

        i++;
      }
      // });
      let initialDeffrence =
        Number(timeForAlert) - Number(player.currentTime());
      if (initialDeffrence > 30) {
        let deffrence = initialDeffrence - 30;
        timeForAlert = timeForAlert - deffrence;
      }
      let j = 1;
      // console.log(
      //   "time" + timeForAlert + "currunt    time    " + player.currentTime()
      // );

      if (timeForAlert != 0 && player.currentTime() > timeForAlert) {
        // alert("jii");
        // console.log(
        //   "ooooooooooookmk" +
        //     player.currentTime() +
        //     "                   " +
        //     timeForAlert
        // );

        if (AUTHENTICATION === true) {
          if (flag == 0) {
            player.pause();
            let text = "";
            const isPiPMode = getPiPStateFromStorage();

            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            if (ASSET_DATA?.isPPVRequired == 1) {
              text = "Please subscribe to watch.";
            } else {
              text =
                "Please subscribe or upgrade your package to view this content.";
            }
            Swal.fire({
              icon: "warning",
              text: text,
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe",
              cancelButtonText: "May be later",
              showCancelButton: true,
              showCloseButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(GENERAL_DATA?.packageSubscriptionUrl);
              }
            });

            // history.replace("/container");
          }
        }

        if (AUTHENTICATION === false) {
          // console.log("pooja");
          player.pause();
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text:
              "You are not Signed in. Please Sign in to continue watching the video.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          }).then((OK) => {
            if (OK) {
              player.pause();
              // setSelectedCategory(1);
              // history.replace("/container");
            }
          });
        }

        if (AUTHENTICATION === false) {
        }
      }
    });

    return () => {
      if (player) {
        player.pause();

        player?.dispose();
        videoNode.current = null;
      }
    };
  }, [videoNode]);

  useEffect(() => {
    if (signInMessage == true) {
      if (AUTHENTICATION === true) {
        if (flag == 0) {
          const isPiPMode = getPiPStateFromStorage();

          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          Swal.fire({
            icon: "warning",
            text:
              "Please subscribe or upgrade your package to view this content.",
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe",
            cancelButtonText: "May be later",
            showCancelButton: true,
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(GENERAL_DATA?.packageSubscriptionUrl);
              player.pause();
            }
          });
        }
      } else if (AUTHENTICATION === false) {
        const isPiPMode = getPiPStateFromStorage();

        if (isPiPMode === true) {
          document.exitPictureInPicture(); // Exit PiP mode when navigating back
        }
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "You are not Signed in. Please Sign in to continue watching the video.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        }).then((OK) => {});
      }
    }
  }, [signInMessage]);
  // useEffect(() => {
  //   const button = document.getElementById('mute-unmute');

  //   if (button) {

  //     button.onclick = function () {
  //       alert(" btn  clicked");
  //       // const player = document.getElementById('video-player-id'); // Replace with your video player's ID
  //       if (player) {
  //         player.muted = !player.muted; // Toggle mute state
  //       } else {
  //         console.error("Video player not found");
  //       }
  //     };
  //   } else {
  //     console.error("Mute/Unmute button not found");
  //   }
  // }, [isMuted, videoNode]);

  return (
    <div className="container">
      <div data-vjs-player>
        <video
          ref={videoNode}
          className="video-js vjs-fluid autoplay teaser-player"
          controls
          autoplay
        ></video>
        {showOverlay && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <img
              src={posterUrl}
              alt="Error Poster"
              style={{
                width: "120%",
                height: "120%",
                filter: "blur(10px)",
                objectFit: "cover",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DRM_DETAILS: getDrmDetailsSelector,
  EPG_URL: getEpgVideoUrlSelector,
  IS_CATCHUP: getIsCatchupRequiredSelector,
  PLATFORM_ID: platformsSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  CHANNEL_DATA: getLiveAssetsDataSelector,
  CONFIGURATION_DAS: configurationSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  VIDEO_ADS: getgeneralVideoAdsDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  ASSET_DATA: getLiveChannelDataSelector,
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(LiveDetailsPlayer);
