import ACTIONSTYPES from "./actionTypes";
const {
  STORING_DATA_VERSIONS,
  NULL_DATA_VERSIONS,
  MAKE_VERSIONS_TRUE,
} = ACTIONSTYPES;

const initialState = {
  dataArray: [
    { id: 1, category: "live", version: 0 },
    { id: 2, category: "movie", version: 0 },
    { id: 3, category: "series", version: 0 },
    { id: 4, category: "banner", version: 0 },
    { id: 5, category: "generalDetails", version: 0 },
  ],
  updatedItemIds: [],
  isLiveUpdated: false,
  isMovieUpdated: false,
  isSeriesUpdated: false,
  isBannerUpdated: false,
  isGeneralDetailsUpdated: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STORING_DATA_VERSIONS:
      // console.log("jkscmkcmskc" + JSON.stringify(payload));

      const updatedDataArray = state.dataArray.map((item) => {
        const correspondingPayloadItem = payload?.find((p) => p.id === item.id);
        if (
          correspondingPayloadItem &&
          correspondingPayloadItem.version !== item.version
        ) {
          return { ...item, version: correspondingPayloadItem.version };
        }
        return item;
      });

      const updatedItemIds = updatedDataArray
        .filter(
          (item, index) => item.version !== state.dataArray[index].version
        )
        .map((item) => item.id);

      // console.log("updatedItemIds" + updatedItemIds);

      return {
        ...state,
        dataArray: updatedDataArray,
        updatedItemIds: updatedItemIds,
        isLiveUpdated: updatedItemIds.includes(1),
        isMovieUpdated: updatedItemIds.includes(2),
        isSeriesUpdated: updatedItemIds.includes(3),
        isBannerUpdated: updatedItemIds.includes(4),
        isGeneralDetailsUpdated: updatedItemIds.includes(5),
      };
    case MAKE_VERSIONS_TRUE:
      return {
        ...state,
        isLiveUpdated: true,
        isMovieUpdated: true,
        isSeriesUpdated: true,
        isBannerUpdated: true,
        isGeneralDetailsUpdated: true,
      };
    case NULL_DATA_VERSIONS:
      const resetFlags = {
        live: { isLiveUpdated: false },
        movies: { isMovieUpdated: false },
        series: { isSeriesUpdated: false },
        banner: { isBannerUpdated: false },
        generalDetails: { isGeneralDetailsUpdated: false },
      };
      if (payload in resetFlags) {
        return {
          ...state,
          ...resetFlags[payload],
        };
      } else {
        return {
          ...state,
        };
      }

    default:
      return state;
  }
};
