/** @format */

// liveAssetGenre
import React, { useMemo, useRef } from "react";
import Slider from "./Slider";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";
import * as MOVIE_ACTION from "./../Redux/MoviesRedux/actions";
import * as LIVE_ACTION from "./../Redux/live/actions";
import * as ACTION_TYPE from "./../Redux/EPG/action";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as ACTION_TYPES_LOGIN from "../Redux/auth/actions";
import * as EPG_ACTION from "./../Redux/EPG/action";
import * as ALL_LIVE_ASSETS_ACTION from "./../Redux/AllLiveDetails/actions";
import * as SERIAS_GENRE_ACTION from "./../Redux/SeriesGenre/actions";

import {
  getchangedGenreIndexSelector,
  getOfffsetLengthSelector,
} from "./../Redux/live/selectors";
import MovieCatogory from "./MovieCatogory";
import * as NOTIFICATION_ACTION from "./../Redux/PushNotification/actions";
import * as GET_FAVORITES_ACTION from "./../Redux/FavouteDataRedux/action";

import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
import MainHeader from "./MainHeader";
import NewLoadingPage from "./NewLoadingPage";
import SearchPage from "./SearchComponent/SearchPage";
import { useHistory } from "react-router-dom";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../Redux/deviceRedux/selectors";
import { getVideoAdsSelector } from "../Redux/video-adverticement/selectors";
import {
  allLiveAssetSelector,
  getAllGenresBaedOnkeySelector,
  getGenreDetailsBaedOnkeyDetailsSelector,
  getGenreDetailsLengthSelector,
} from "../Redux/AllLiveDetails/selectors";
import * as LATEST_EPG_ACTION from "../Redux/LatestEPG/actions";

import {
  getPackageDetailsSelector,
  getPayPerViewAssetDetailsSelector,
} from "../Redux/package&subscriptions/selector";
import DisplayMovieImage from "./DisplayMovieImage";
import {
  getDefaultPlatformsForRouteSelector,
  getDefaultPlatformsSelector,
} from "../Redux/DefaultPlatform/selectors";
import {
  getLiveVersionSelector,
  getMovieVersionSelector,
  getSeriesVersionSelector,
} from "../Redux/Dataversion/selector";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../Redux/authentication/selectors";
import {
  seriesAssetSelector,
  seriesGenresSelector,
} from "../Redux/SeriesGenre/selector";
import {
  getMovieDetaisSelector,
  getMovieGenreDetailsSelector,
} from "../Redux/MoviesRedux/selector"; 
import {
  configurationSelector,
  continueWatchingSelector,
  watchedHistorySelector,
} from "../Redux/DATA-analytics/selector";
import { getDeviceStatusSelector } from "../Redux/Delete Device/selectors";
let continueWatchingArray = [];
// let watchesHistoryArray = [];
const Container = ({
  LIVEASSETS,
  PLATFORM_ID,
  LIVE_ASSET_GENRE_DATA,

  searchState,
  setSearchState,
  setSearchKey,
  searchKey,
  VIDEO_ADS,
  OFFSET,
  setIsVideoPlayer,
  ALL_GENRE_DATA,
  ASSETS_LENGTH,
  setClearSearchState,
  clearSearchState,
  PAY_PER_VIEW_ASSET_DETAILS,
  PACKAGE_DETAILS,
  LIVEVERSION,
  USER_DETAILS,
  AUTHENTICATION,
  CURRENT_PLATFORM,
  SERIES_ASSETS,
  MOVIE_DETAILS,
  MOVIEVERSION,
  MOVIE_GENRE_DETAILS,
  CONTINUE_WATCHING_DATA,
  SERIES_GENRE,
  selectedCategory,
  setSelectedCategory,
  SERIESVERSION,
  DEVICE_STATUS,
  WATCHES_HISTORY_DATA,
  DEVICE_TOKEN,
  CONFIGURATION_DAS,
  DEFAULT_PLATFORM,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [watchesHistoryArray, setWatchesHistoryArray] = useState([]);

  // useEffect(() => {
  //   if (selectedCategory == 1) {
  //     const LIVE_ASSET_GENRE = Object.keys(
  //       LIVE_ASSET_GENRE_DATA != undefined &&
  //         LIVE_ASSET_GENRE_DATA != null &&
  //         LIVE_ASSET_GENRE_DATA
  //     )?.map((key) => LIVE_ASSET_GENRE_DATA[key]);
  //     // setGenreDetails(LIVE_ASSET_GENRE);
  //     console.log("arunvn1236" + JSON.stringify(LIVE_ASSET_GENRE));
  //   }
  // }, [LIVE_ASSET_GENRE_DATA, selectedCategory]);

  // console.log("arunvn1234" + JSON.stringify(LIVEASSETS?.genres));
  // console.log("arunvn1235" + JSON.stringify(LIVE_ASSET_GENRE_DATA));

  let adsEnabledForDefaultPlatform;
  if (DEFAULT_PLATFORM != undefined && DEFAULT_PLATFORM != null) {
    adsEnabledForDefaultPlatform = DEFAULT_PLATFORM?.data?.data?.isAdsEnabled;
  }
  const adsEnabled = AUTHENTICATION
    ? Object.values(PACKAGE_DETAILS).some(
        (item) => item?.assetGroup?.isAdsEnabled === 1
      )
    : adsEnabledForDefaultPlatform;

  useEffect(() => {
    let keysArray;
    if (PACKAGE_DETAILS != undefined && PACKAGE_DETAILS != null) {
      keysArray = Object.keys(PACKAGE_DETAILS);
    }

    if (AUTHENTICATION) {
      // console.log("getPushNotificationStarted");
      dispatch(
        NOTIFICATION_ACTION.getPushNotificationStart({
          packageId: JSON.stringify(keysArray),
        })
      );
    }
  }, [USER_DETAILS?.notificationReadStatus]);

  // console.log(
  //   "PAY_PER_VIEW_ASSET_DETAILS" + JSON.stringify(PAY_PER_VIEW_ASSET_DETAILS)
  // );
  // console.log(
  //   "CONTINUE_WATCHING_DATA" + JSON.stringify(CONTINUE_WATCHING_DATA)
  // );

  function calculatePercentageViewed(item) {
    const totalDurationInSeconds = convertTimeToSeconds(item.totalDuration);
    const durationInSeconds = convertTimeToSeconds(item.duration);
    return (durationInSeconds / totalDurationInSeconds) * 100;
  }

  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  // console.log("WATCHES_HISTORY_DATA" + JSON.stringify(WATCHES_HISTORY_DATA));

  let updatedMovieGenres;
  let date = new Date();
  let timestamp = date.getTime();
  if (selectedCategory == 2) {
    const filteredPPVAssets =
      Array.isArray(PAY_PER_VIEW_ASSET_DETAILS) &&
      PAY_PER_VIEW_ASSET_DETAILS?.filter((item) => {
        let ppvStopDateStamp = Date.parse(item?.expiryDate);
        return (
          item?.categoryId === 2 &&
          timestamp < ppvStopDateStamp &&
          MOVIE_DETAILS?.movieliveAssetDetails?.[item?.assetId]
        );
      });
    const ppvAssetObject = {
      id: null,
      name: "Premium",
      genreCategory: 3,
      genreassetassociation: filteredPPVAssets,
    };

    updatedMovieGenres =
      filteredPPVAssets.length > 0
        ? [ppvAssetObject, ...MOVIE_DETAILS?.moviegenres]
        : MOVIE_DETAILS?.moviegenres;
  }

  if (selectedCategory == 3) {
    const filteredPPVAssets =
      Array.isArray(PAY_PER_VIEW_ASSET_DETAILS) &&
      PAY_PER_VIEW_ASSET_DETAILS?.filter((item) => {
        let ppvStopDateStamp = Date.parse(item?.expiryDate);
        return (
          item?.categoryId === 3 &&
          timestamp < ppvStopDateStamp &&
          SERIES_ASSETS?.assetDetails?.[item?.assetId]
        );
      });

    const ppvAssetObject = {
      id: null,
      name: "Premium",
      genreCategory: 3,
      genreassetassociation: filteredPPVAssets,
    };

    updatedMovieGenres =
      filteredPPVAssets.length > 0
        ? [ppvAssetObject, ...SERIES_GENRE]
        : SERIES_GENRE;
  }

  if (selectedCategory == 1) {
    const filteredPPVAssets =
      Array.isArray(PAY_PER_VIEW_ASSET_DETAILS) &&
      PAY_PER_VIEW_ASSET_DETAILS?.filter((item) => {
        let ppvStopDateStamp = Date.parse(item?.expiryDate);
        // console.log("filteredPPVAssets", item?.categoryId);

        return (
          item?.categoryId === 1 &&
          timestamp < ppvStopDateStamp &&
          LIVEASSETS?.liveAssetDetails?.[item?.assetId]
        );
      });

    const ppvAssetObject = {
      id: null,
      name: "Premium",
      genreCategory: 3,
      genreassetassociation: filteredPPVAssets,
    };

    const LIVE_ASSET_GENRE = Object.keys(
      LIVE_ASSET_GENRE_DATA != undefined &&
        LIVE_ASSET_GENRE_DATA != null &&
        LIVE_ASSET_GENRE_DATA
    )?.map((key) => LIVE_ASSET_GENRE_DATA[key]);
    updatedMovieGenres =
      filteredPPVAssets.length > 0
        ? [ppvAssetObject, ...LIVE_ASSET_GENRE]
        : LIVE_ASSET_GENRE;
  }
  useEffect(() => {
    let updatedWatchesHistoryArray = [];

    if (selectedCategory == 2) {
      continueWatchingArray = [];

      // watchesHistoryArray = [];
      setWatchesHistoryArray([]);

      const MovieAssetIds = Object.keys(
        MOVIE_DETAILS?.movieliveAssetDetails || {}
      );
      if (
        AUTHENTICATION == true &&
        Number(CURRENT_PLATFORM?.viewing_analytics_enabled) == 1 &&
        Number(USER_DETAILS?.viewing_analytics_status) == 1
      ) {
        const continueWatchingFiltered = CONTINUE_WATCHING_DATA?.filter(
          (item) =>
            MovieAssetIds.includes(item?.assetId?.toString()) &&
            Number(item?.categoryId) == 2 &&
            Number(item?.videoDeleted) === 0 &&
            Number(item?.isVideoEnded) === 0
        );

        continueWatchingFiltered?.map((item, index) => {
          let continueWatcingObj = {
            assetId: item.assetId,
            categoryId: 2,
            sortOrder: index + 1,
            active: 1,
            genreId: "1",
            id: item.assetId,
            isContineuwatching: calculatePercentageViewed(item),
            duration: item?.duration,
            totalDuration: item?.totalDuration,
            type: 1,
          };
          continueWatchingArray.push(continueWatcingObj);

          // console.log("continueWatcingObj122" + continueWatcingObj.length);
        });
      }
      if (
        AUTHENTICATION === true &&
        Number(CURRENT_PLATFORM?.isWatchedHistoryRequired) == 1 &&
        Number(USER_DETAILS?.watchHistoryStatus) == 1
      ) {
        const WatchedHistoryFiltered = Array.isArray(WATCHES_HISTORY_DATA)
          ? WATCHES_HISTORY_DATA.filter(
              (item) =>
                MovieAssetIds.includes(item?.assetId?.toString()) &&
                Number(item?.categoryId) === 2 &&
                Number(item?.videoDeleted) === 0
            )
          : [];

        WatchedHistoryFiltered?.map((item, index) => {
          let watchesHistoryObj = {
            assetId: item.assetId,
            categoryId: 2,
            sortOrder: index + 1,
            active: 1,
            genreId: "1",
            id: item.assetId,
            isContineuwatching: 100,
            duration: item?.duration,
            totalDuration: item?.totalDuration,
            type: 2,
          };
          // watchesHistoryArray.push(watchesHistoryObj);
          updatedWatchesHistoryArray.push(watchesHistoryObj);
        });
        setWatchesHistoryArray(updatedWatchesHistoryArray);
      }
      // console.log("watchesHistoryArray outside useEffect", watchesHistoryArray);
    } else if (selectedCategory == 3) {
      continueWatchingArray = [];
      // watchesHistoryArray = [];
      setWatchesHistoryArray([]);
      if (
        AUTHENTICATION == true &&
        Number(CURRENT_PLATFORM?.viewing_analytics_enabled) == 1 &&
        Number(USER_DETAILS?.viewing_analytics_status) == 1
      ) {
        const seriesAssetIds = Object.keys(SERIES_ASSETS?.assetDetails || {});
        const continueWatchingFiltered = CONTINUE_WATCHING_DATA?.filter(
          (item) =>
            seriesAssetIds.includes(item?.assetId?.toString()) &&
            Number(item?.categoryId) === 3 &&
            Number(item?.videoDeleted) === 0
        );

        const assetIdMap = new Map();
        const groupedItems = CONTINUE_WATCHING_DATA?.reduce((groups, item) => {
          const { assetId } = item;
          if (!groups[assetId]) {
            groups[assetId] = [];
          }
          groups[assetId].push(item);

          return groups;
        }, {});

        const groupStatus = {};
        for (const assetId in groupedItems) {
          const group = groupedItems[assetId];
          const { episodeLength } = group[0];

          const status =
            group.length === episodeLength &&
            group.every((item) => item?.isVideoEnded === 1);
          groupStatus[assetId] = status;
        }

        continueWatchingFiltered.forEach((item, index) => {
          const assetId = item.assetId;
          if (!assetIdMap.has(assetId) || index < assetIdMap.get(assetId)) {
            assetIdMap.set(assetId, index);
          }
        });

        const filteredData = continueWatchingFiltered.filter(
          (item, index) => assetIdMap.get(Number(item.assetId)) === index
        );
        // const transformedData = continueWatchingFiltered.map((item) => ({
        //   ...item,
        //   isGroupFinished: groupStatus[item.assetId.toString()] || false,
        // }));
        filteredData
          .filter((item) => groupStatus[item.assetId.toString()] === false)
          .map((item, index) => {
            let continueWatcingObj = {
              id: item.assetId,
              assetId: Number(item.assetId),
              categoryId: 3,
              genreId: "1",
              sortOrder: index + 1,
              active: "1",
              isContineuwatching: calculatePercentageViewed(item),
              episodeId: Number(item.episodeId),
              seasonId: Number(item.seasonId),
              isVideoEnded: Number(item?.isVideoEnded),
              type: 1,
            };
            // console.log(
            //   "continueWatcingObjin3" + JSON.stringify(continueWatcingObj)
            // );
            continueWatchingArray.push(continueWatcingObj);
          });
      }

      //watches history
      if (
        AUTHENTICATION === true &&
        Number(CURRENT_PLATFORM?.isWatchedHistoryRequired) == 1 &&
        Number(USER_DETAILS?.watchHistoryStatus) == 1
      ) {
        const WatchedHistoryseriesAssetIds = Object.keys(
          SERIES_ASSETS?.assetDetails || {}
        );
        let watchedHistoryData = WATCHES_HISTORY_DATA;

        // Check if it's not an array, set it to an empty array
        if (!Array.isArray(watchedHistoryData)) {
          watchedHistoryData = [];
        }

        const WatchedHistoryFiltered = watchedHistoryData.filter(
          (item) =>
            WatchedHistoryseriesAssetIds.includes(item?.assetId?.toString()) &&
            Number(item?.categoryId) === 3 &&
            Number(item?.videoDeleted) === 0
        );
        // console.log(
        //   "WatchedHistoryFiltered" + JSON.stringify(WatchedHistoryFiltered)
        // );
        const WatchedHistoryassetIdMap = new Map();
        const WatchedHistorygroupedItems = Array.isArray(WATCHES_HISTORY_DATA)
          ? WATCHES_HISTORY_DATA.reduce((groups, item) => {
              const { assetId } = item;
              if (!groups[assetId]) {
                groups[assetId] = [];
              }
              groups[assetId].push(item);
              return groups;
            }, {})
          : {};

        const WatchedHistorygroupStatus = {};
        for (const assetId in WatchedHistorygroupedItems) {
          const group = WatchedHistorygroupedItems[assetId];
          const { episodeLength } = group[0];

          const status =
            group.length === episodeLength &&
            group.every((item) => item?.isVideoEnded === 1);
          WatchedHistorygroupStatus[assetId] = status;
        }

        WatchedHistoryFiltered.forEach((item, index) => {
          const assetId = item.assetId;
          if (
            !WatchedHistoryassetIdMap.has(assetId) ||
            index < WatchedHistoryassetIdMap.get(assetId)
          ) {
            WatchedHistoryassetIdMap.set(assetId, index);
          }
        });

        const WatchedHistoryfilteredData = WatchedHistoryFiltered.filter(
          (item, index) =>
            WatchedHistoryassetIdMap.get(Number(item.assetId)) === index
        );

        WatchedHistoryfilteredData.filter(
          (item) => WatchedHistorygroupStatus[item.assetId.toString()] === false
        ).map((item, index) => {
          let watchesHistoryObj = {
            id: item.assetId,
            assetId: Number(item.assetId),
            categoryId: 3,
            genreId: "1",
            sortOrder: index + 1,
            active: "1",
            isContineuwatching: 100,
            episodeId: Number(item?.episodeId),
            seasonId: Number(item?.seasonId),
            isVideoEnded: Number(item?.isVideoEnded),
            type: 2,
          };
          // watchesHistoryArray.push(watchesHistoryObj);

          updatedWatchesHistoryArray.push(watchesHistoryObj);
        });
        setWatchesHistoryArray(updatedWatchesHistoryArray);
      }
    }
  }, [
    WATCHES_HISTORY_DATA,
    CONTINUE_WATCHING_DATA,
    MOVIE_GENRE_DETAILS,
    selectedCategory,
    SERIES_GENRE,
    SERIES_ASSETS,
  ]);
  // console.log("watchesHistoryArray outside useEffect", watchesHistoryArray);

  //setting series data to state , if categroy is series

  //calling api based on category
  useEffect(() => {
    setIsVideoPlayer(false);
    if (selectedCategory == 1 || selectedCategory == 5) {
      dispatch(LATEST_EPG_ACTION.getLatestEpgStart());
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (searchState == false) {
      document.body.style.overflowY = "scroll";
    }
  }, [searchState]);

  useEffect(() => {
    if (
      selectedCategory == null ||
      (selectedCategory == undefined &&
        localStorage.getItem("pageReloaded") == 0)
    ) {
      let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
      // console.log("arunnnnn");
      if (defaultLandingPage == 1) {
        setSelectedCategory(1);
      } else if (defaultLandingPage == 2) {
        setSelectedCategory(2);
      } else if (defaultLandingPage == 3) {
        setSelectedCategory(3);
      } else {
        setSelectedCategory(1);
      }
    }
  }, [CURRENT_PLATFORM, selectedCategory]);
  // useEffect(() => {
  //   if (USER_DETAILS == undefined || AUTHENTICATION == false) {
  //     history.push("/login");
  //   }
  // }, []);
  //calling live api if category is 1
 
  useEffect(() => {
    if (!CURRENT_PLATFORM?.orgId) return; // Wait until orgId is available
  
    if (selectedCategory === 1) {
      continueWatchingArray = [];
      setWatchesHistoryArray([]);
  
  
      if (
        LIVEVERSION === true ||
        !LIVEASSETS?.genres ||
        LIVEASSETS?.genres.length < 1 ||
        !LIVEASSETS?.liveAssetDetails ||
        LIVEASSETS?.liveAssetDetails.length < 1
      ) {
        console.log("CURRENT_PLATFORM?.orgId", CURRENT_PLATFORM?.orgId);

        dispatch(
          ALL_LIVE_ASSETS_ACTION.getAllLiveAssetsStart({
            account_id: CURRENT_PLATFORM?.orgId,
          })
        );
        dispatch(
          ALL_LIVE_ASSETS_ACTION.getAllLiveAsetsBasedOnKeyStart({
            account_id: CURRENT_PLATFORM?.orgId,
          })
        );
      }
    }
  }, [LIVEVERSION, CURRENT_PLATFORM?.orgId, selectedCategory]);
  
  //calling the api if category is 2
  useEffect(() => {
    // console.log("calling movie api arun", MOVIE_DETAILS?.moviegenres);
    // console.log("calling movie api arun", MOVIE_DETAILS?.movieliveAssetDetails);
    // console.log("calling movie api arun", MOVIEVERSION);

    if (selectedCategory == 2) {
      if (
        MOVIEVERSION == true ||
        MOVIE_DETAILS?.moviegenres == null ||
        MOVIE_DETAILS?.moviegenres.length < 1 ||
        MOVIE_DETAILS?.movieliveAssetDetails == undefined ||
        MOVIE_DETAILS?.movieliveAssetDetails == null ||
        MOVIE_DETAILS?.movieliveAssetDetails.length < 1
      ) {
        // console.log("calling movie api arun");
        dispatch(
          MOVIE_ACTION.getMoviesDataStart({
            account_id: CURRENT_PLATFORM?.orgId,
          })
        );
      }
    }
  }, [MOVIEVERSION, CURRENT_PLATFORM, selectedCategory]);
  //calling the api if category is 3

  useEffect(() => {
    // if (selectedCategory == 3) {
    if (CURRENT_PLATFORM != undefined || CURRENT_PLATFORM != null) {
      if (
        SERIESVERSION == true ||
        SERIES_ASSETS?.genreDetails == null ||
        SERIES_ASSETS?.genreDetails.length < 1 ||
        SERIES_ASSETS?.assetDetails == undefined ||
        SERIES_ASSETS?.assetDetails == null ||
        SERIES_ASSETS?.assetDetails.length < 1
      ) {
        dispatch(
          SERIAS_GENRE_ACTION.getSriesGenresStart({
            account_id: CURRENT_PLATFORM?.orgId,
          })
        );
      }
    }
    // }
  }, [SERIESVERSION, CURRENT_PLATFORM, selectedCategory]);
  useEffect(() => {
    if (
      SERIES_ASSETS?.genreDetails != null ||
      SERIES_ASSETS?.genreDetails.length > 1 ||
      SERIES_ASSETS?.assetDetails != undefined ||
      SERIES_ASSETS?.assetDetails != null ||
      SERIES_ASSETS?.assetDetails.length > 1
    ) {
      dispatch(SERIAS_GENRE_ACTION.makeSeriesLoadingFalse());
    }
  }, []);
  useEffect(() => {
    let data2;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      data2 = {
        account_id: PLATFORM_ID?.currentPlatformId,
        userId: USER_DETAILS?.id,
        packageId: USER_DETAILS?.packageId,
      };
    }
    (async () => {
      dispatch(LIVE_ACTION.getOffsetLength(10));
      localStorage.setItem("offsetLength", 10);
      localStorage.setItem("genreId", null);
      localStorage.setItem("genreArray", []);
      if (AUTHENTICATION) {
        dispatch(ACTION_TYPES_LOGIN.getDeviceListStart(data2));

        dispatch(
          GET_FAVORITES_ACTION.getFavouriteDataStart({
            userId: USER_DETAILS?.id,
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");
    var arr2 = todayDate.split("/");

    var arr3 = arr2[2] + "/" + arr2[1];
    let programMonth = arr2[1];
    let programmeDate = arr2[2];
    if (parseInt(programMonth) <= 9) {
      programMonth = "0" + programMonth;
    }
    if (parseInt(programmeDate) <= 9) {
      programmeDate = "0" + programmeDate;
    }
    // programDate = arr2[0] + "-" + programMonth + "-" + programmeDate;

    let date = arr2[0] + "-" + programMonth + "-" + programmeDate;
    const data1 = {
      account_id: PLATFORM_ID?.currentPlatformId,
      programmeDate: date,
      limit: 1000,
    };
    let startTimeStamp = new Date(
      programMonth + "/" + programmeDate + "/" + arr2[0]
    ).setHours(0, 0, 0, 0);
    let stopTimeStamp = startTimeStamp + 86400 * 1000 - 60000;
    let data = {
      startTimeTimestamp: startTimeStamp,
      stopTimeTimestamp: stopTimeStamp,
      account_id: PLATFORM_ID?.currentPlatformId,
      offset: 0,
      limit: 1000,
      a: false,
      programmeDate: date,
    };
    dispatch(EPG_ACTION.getEpgDetailsStart(data));
  }, []);

  useEffect(() => {
    let dateArray1 = [];
    let todayDate = new Date().toLocaleDateString("zh-Hans-CN");

    var arr2 = todayDate.split("/");
    var arr3 = arr2[2] + "/" + arr2[1];

    dateArray1.push({ date: todayDate });
    if (dateArray1 != undefined && dateArray1 != null) {
      dispatch(ACTION_TYPE.getEpgStateSuccess(dateArray1.date));
    }
  }, []);

  // console.log(selectedCategory + "selectedCategory");
  console.log( "updatedMovieGenres"+JSON.stringify(updatedMovieGenres));
  
  return (
    <div>
      <MainHeader
        setSearchState={setSearchState}
        setSearchKey={setSearchKey}
        searchState={searchState}
        setClearSearchState={setClearSearchState}
        clearSearchState={clearSearchState}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div style={{ height: "75px", color: "black" }}></div>
      {searchState == true ? (
        <SearchPage
          setSearchKey={setSearchKey}
          searchKey={searchKey}
          setSearchState={setSearchState}
          setIsVideoPlayer={setIsVideoPlayer}
          setClearSearchState={setClearSearchState}
        />
      ) : (
        ""
      )}
      <Slider />

      <div className="main-content p-0">
        <section id="iq-favorites">
          <div className="container-fluid">
            <div
              className="row"
              //onClick={handleModalClose}
            >
              {// LIVE_LOADING == false
              updatedMovieGenres != undefined &&
              updatedMovieGenres != null &&
              updatedMovieGenres?.length > 0 ? (
                updatedMovieGenres?.map((i, index) => {
                  // console.log(
                  //   "arungenreNa" +
                  //     i?.name +
                  //     "     arungenreassetassociation" +
                  //     JSON.stringify(i?.genreassetassociation)
                  // );
                  // console.log("i?.genreCategory", i?.genreCategory);

                  var finalLiveAssetDetils = i?.genreassetassociation?.filter(
                    (item) => item?.active !== 4
                  );
                  if (
                    VIDEO_ADS?.data != undefined &&
                    VIDEO_ADS?.data != null &&
                    adsEnabled
                  ) {
                    VIDEO_ADS?.data?.map((item, index) => {
                      let adDetails = {
                        id: item?.id,
                        active: 4,
                        videoAddUrl: item?.videoUrl,
                      };
                      let count = 1;
                      if (item.videoGenreAssociation.length > 0) {
                        // console.log("callimnggggg");
                        item?.videoGenreAssociation?.map((item2, index) => {
                          let filteredData = finalLiveAssetDetils?.filter(
                            (a) => {
                              if (a?.id == item?.id && a?.active == 4) {
                                return a;
                              }
                            }
                          );
                          if (
                            item2?.genreId == i?.id &&
                            filteredData?.length < 1
                          ) {
                            if (count <= 1) {
                              finalLiveAssetDetils?.splice(0, 0, adDetails);
                              count = count + 1;
                            }
                          }
                        });
                      }
                    });
                  }

                  return (
                    <div>
                       <MovieCatogory
                        key={index}
                        value={i?.name}
                        genreId={i?.id}
                        genreCategory={i?.genreCategory}
                         // genreAssetAssociation={
                        //   // i?.genreCategory == 1
                        //   //   ? SAVED_CW_DATA
                        //   //   : // : i?.genreCategory == 2
                        //   // ? watchesHistoryArray
                        //   i?.genreassetassociation
                        // }
                        genreAssetAssociation={
                          i?.genreCategory == 1
                            ? continueWatchingArray
                            : i?.genreCategory == 2
                            ? watchesHistoryArray
                            : finalLiveAssetDetils
                        }
                        // assetDetails={LIVEASSETS?.liveAssetDetails}
                        displayType={i.displayType}
                        genreIndex={index}
                        USER_DETAILS={USER_DETAILS}
                        VIDEO_ADS={VIDEO_ADS}
                        OFFSET={OFFSET}
                        ALL_GENRE_DATA={ALL_GENRE_DATA}
                        ASSETS_LENGTH={ASSETS_LENGTH}
                        selectedCategory={selectedCategory}
                        AUTHENTICATION={AUTHENTICATION}
                        CURRENT_PLATFORM={CURRENT_PLATFORM}
                        DEVICE_TOKEN={DEVICE_TOKEN}
                        CONFIGURATION_DAS={CONFIGURATION_DAS}
                      />
                    </div>
                  );
                })
              ) : (
                <NewLoadingPage />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  LIVEASSETS: allLiveAssetSelector,

  ALL_GENRE_DATA: getAllGenresBaedOnkeySelector,
  PLATFORM_ID: platformsSelector,
  LIVE_ASSET_GENRE_DATA: getGenreDetailsBaedOnkeyDetailsSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_NAME: getDeviceNameSelector,
  INDEX_OFFSET: getchangedGenreIndexSelector,
  VIDEO_ADS: getVideoAdsSelector,
  OFFSET: getOfffsetLengthSelector,
  ASSETS_LENGTH: getGenreDetailsLengthSelector,
  PAY_PER_VIEW_ASSET_DETAILS: getPayPerViewAssetDetailsSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  CURRENT_PLATFORM_FOR_ROUTE: getDefaultPlatformsForRouteSelector,
  LIVEVERSION: getLiveVersionSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  MOVIEVERSION: getMovieVersionSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  SERIES_ASSETS: seriesAssetSelector,
  MOVIE_DETAILS: getMovieDetaisSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  WATCHES_HISTORY_DATA: watchedHistorySelector,
  MOVIE_GENRE_DETAILS: getMovieGenreDetailsSelector,
  SERIES_GENRE: seriesGenresSelector,
  SERIESVERSION: getSeriesVersionSelector,
  DEVICE_STATUS: getDeviceStatusSelector,

  CONFIGURATION_DAS: configurationSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
});

export default connect(mapStateToProps)(Container);
