import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";

var qs = require("qs");

export const socketStatusAPI = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  // console.log("arun insertAssetData " + JSON.stringify(payload));
  return await axiosInstance()
    .post("/create/message", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      // console.log("arun responseData" + JSON.stringify(response.data));

      return response.data;
    })

    .catch((error) => {
      Promise.reject(error.response.data);
      // console.log("errorData" + JSON.stringify(response.data));
    });
};

export const getDasconfigurationDataAsync = async (payload) => {
  //console.log("live assets parameters " + JSON.stringify(payload));
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post("/das/configurations", qs.stringify(payload), {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      // console.log("gdvgsa", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
export const getSendingDataToDasAsynce = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/insert/assetData`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.dataAnalyticsForAPI),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchingDataForContinueWatchingDataAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/continue/watching`;

  return await axiosInstance()
    .post(fullUrl, qs.stringify(payload?.dataForFetchContinueWatching), {
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })

    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      // console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
export const fetchingDataForWatchesHistoryDataAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/watchesHistory`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.dataForFetchWatchesHistory),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
export const removeContinueWatchingDataAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/remove/continueWatching`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.dataForRemoveContinueWatching),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
export const removeWatchesHistoryDataAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/remove/watchedHistory`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.dataForRemoveContinueWatching),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
export const viewAnalyticsStatusDataAsync = async (payload) => {
  try {
    const userContinueWatchingViewStatus = await axiosInstance().post(
      "update/viewAnalyticsStatusV1",
      qs.stringify(payload.dataForViewAnalyticsStatusStart)
    );
    return userContinueWatchingViewStatus;
  } catch (error) {
    throw error.response?.data || error;
  }
};
export const getSendingDataOfVideoadsAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/insertVideoAdsData`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.adsAnalyticsData),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
export const getSendingDataOfPosteradsAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/insertPosterAdsData`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.adsAnalyticsData),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      return response;
    })

    .catch((error) => {
      // console.log("error.response.data" + JSON.stringify(error));

      return Promise.reject(error);
    });
};
export const getSendingDataOfGenreVideoAsync = async (payload) => {
  const serverUrl = new URL(payload?.ServerIp);
  const protocol = serverUrl.protocol;

  // Add port :3006 only if the protocol is http
  const port = protocol === "http:" ? ":3006" : "";

  const fullUrl = `${protocol}//${serverUrl.hostname}${port}/daswebapp/insertGenreVideoAdsData`;

  return await axiosInstance()
    .post(
      fullUrl, // Use the constructed URL
      qs.stringify(payload?.adsAnalyticsData),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
