/** @format */

import { createSelector } from "reselect";

const getAllLiveAssets = (state) => {
  return state.AllLiveAssetReducer;
};

const getAllAssetsOffsetLength = (state) => {
  return state.AllLiveAssetReducer.genreDetailsProps;
};
const getAllOfffsetLength = (state) => state.AllLiveAssetReducer.offset;

const getAllLiveAssetDetails = (state) =>
  state.AllLiveAssetReducer.liveAssetDetails;
const getAllUppendedGenreData = (state) => state.AllLiveAssetReducer.data;

const getAllBanners = (state) => state.AllLiveAssetReducer.banners;
const getAllchangedGenreIndex = (state) =>
  state.AllLiveAssetReducer.changedGenreIndex;

const getAllLiveGenreAssetsForListing = (state) =>
  state.AllLiveAssetReducer.allLiveAssetForListing;
const getAllFinalLiveAssetDetails = (state) =>
  state.AllLiveAssetReducer.finaleLiveAssetDetails;

const getAllGenreDetailsProps = (state) => {
  return state.AllLiveAssetReducer.genreDetailsProps;
};

const getAllGenreLoading = (state) => {
  return state.AllLiveAssetReducer.liveLoading;
};
const getAllOffsetLoading = (state) => {
  return state.AllLiveAssetReducer.offsetLoading;
};

const getAllAssetsId = (state) => {
  return state.AllLiveAssetReducer.allAssetIds;
};

const getGenreDetailsBaedOnkeyDetails = (state) => {
  return state.AllLiveAssetReducer.GenreDetailsBaedOnkey;
};

const getAllGenresBaedOnkey = (state) => {
  return state.AllLiveAssetReducer.AllGenreDetailsBaedOnkey;
};

const getGenreDetailsLength = (state) => {
  return state.AllLiveAssetReducer.genreDetailsLength;
};

export const getGenreDetailsLengthSelector = createSelector(
  getGenreDetailsLength,
  (genreDetailsLength) => genreDetailsLength
);

export const getAllGenresBaedOnkeySelector = createSelector(
  getAllGenresBaedOnkey,
  (AllGenreDetailsBaedOnkey) => AllGenreDetailsBaedOnkey
);

export const getGenreDetailsBaedOnkeyDetailsSelector = createSelector(
  getGenreDetailsBaedOnkeyDetails,
  (GenreDetailsBaedOnkey) => GenreDetailsBaedOnkey
);

export const getAllAssetsIdSelector = createSelector(
  getAllAssetsId,
  (allAssetIds) => allAssetIds
);

export const getAllOffsetLoadingSelector = createSelector(
  getAllOffsetLoading,
  (offsetLoading) => offsetLoading
);
export const getAllGenreLoadingSelector = createSelector(
  getAllGenreLoading,
  (liveLoading) => liveLoading
);
export const getAllGenreDetailsPropsSelector = createSelector(
  getAllGenreDetailsProps,
  (genreDetailsProps) => genreDetailsProps
);

export const getAllOfffsetLengthSelector = createSelector(
  getAllOfffsetLength,
  (offset) => offset
);

export const getAllAssetsOffsetLengthSelector = createSelector(
  getAllAssetsOffsetLength,
  (genreDetailsProps) => genreDetailsProps
);

export const getAllLiveGenreAssetsForListingSelector = createSelector(
  getAllLiveGenreAssetsForListing,
  (allLiveAssetForListing) => allLiveAssetForListing
);
export const getAllUppendedGenreDataSelector = createSelector(
  getAllUppendedGenreData,
  (data) => data
);
export const getAllchangedGenreIndexSelector = createSelector(
  getAllchangedGenreIndex,
  (changedGenreIndex) => changedGenreIndex
);

export const getAllLiveAssetDetailsSelector = createSelector(
  getAllLiveAssetDetails,
  (liveAssetDetails) => liveAssetDetails
);
export const getAllFinalLiveAssetDetailsSelector = createSelector(
  getAllFinalLiveAssetDetails,
  (finaleLiveAssetDetails) => finaleLiveAssetDetails
);

export const allLiveAssetSelector = createSelector(
  getAllLiveAssets,
  (liveAssets) => liveAssets
);

export const getAllBannerSelector = createSelector(
  getAllBanners,
  (banners) => banners
);

//GENERAL details selectors

const getGeneralAdsDetail = (state) => {
  return state.AllLiveAssetReducer.generalAdsDetails;
};

const getgeneralGenreDetails = (state) => {
  return state.AllLiveAssetReducer.generalGenreDetails;
};

const getgeneralLanguageDetails = (state) => {
  return state.AllLiveAssetReducer.generalLanguageDetails;
};

const getgeneralTagDetails = (state) => {
  return state.AllLiveAssetReducer.generalTagDetails;
};

const getgeneralVideoAdsDetails = (state) => {
  return state.AllLiveAssetReducer.generalVideoAdsDetails;
};

export const getgeneralVideoAdsDetailsSelector = createSelector(
  getgeneralVideoAdsDetails,
  (generalVideoAdsDetails) => generalVideoAdsDetails
);

export const getgeneralTagDetailsSelector = createSelector(
  getgeneralTagDetails,
  (generalTagDetails) => generalTagDetails
);

export const getgeneralLanguageDetailsSelector = createSelector(
  getgeneralLanguageDetails,
  (generalLanguageDetails) => generalLanguageDetails
);

export const getgeneralGenreDetailsSelector = createSelector(
  getgeneralGenreDetails,
  (generalGenreDetails) => generalGenreDetails
);

export const getGeneralAdsDetailSelector = createSelector(
  getGeneralAdsDetail,
  (generalAdsDetails) => generalAdsDetails
);
