import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";
var qs = require("qs");
export const liveDataAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the payload.id
    const ciphertext = encryptDataForHeader(payload?.id);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    // Make the API request with the encrypted id
    const liveDataResponse = await axiosInstance().post(
      `/live/${ciphertext}`, // Use the encrypted id
      payload,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    );

    return liveDataResponse?.data; // Return only the response data
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};