import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../Redux/authentication/selectors";

function SubscribeTitle({ AUTHENTICATION }) {
  return (
    <div style={{ width: "276px" }}>
      {/* {AUTHENTICATION == true && (
        <span
          onClick={() => {
            window.open(subscribeUrl);
          }}
          style={{
            backgroundColor: "rgba(255, 0, 0, 0.7)", // Adjust the alpha value (0.8 for 80% opacity)
            opacity: "0.9",
            alignSelf: "flex-end",
            position: "absolute",
            top: 0,
            right: 0,
            padding: "5px",
            color: "white",
            borderRadius: "4px",
            borderTopRightRadius: "13px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "80px",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Subscribe
        </span>
      )} */}
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(SubscribeTitle);
