/** @format */

import base64 from "base-64";
import videojs from "video.js";
import { newImageIpPath } from "../../App";

export const getPlayList = (payload) => {
  let allChannelList = [];

  let selectedDrmCategory = 2;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }

  var keyServer;
  var url1;

  if (selectedDrmCategory == 1) {
    var pos = payload?.LIVE_VIDEO_URL?.lastIndexOf(".");
    let widewineLicense = payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
    let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName.trim();
    let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
    let drmId = payload?.CHANNEL_DATA[0]?.drmId;
    let PlayState = "1";
    let DrmSystem = "Widevine";
    let date = new Date();
    let firstPartUrl = payload?.LIVE_VIDEO_URL?.substring(0, pos);

    url1 = firstPartUrl + ".mpd";
    let month1 = date.getMonth() + 1;
    let nowStampString =
      date.getFullYear() +
      "-" +
      month1 +
      "-" +
      date.getDate() +
      " " +
      0 +
      ":" +
      date.getHours() +
      ":" +
      date.getMinutes();

    let ContentUrl = url1;
    let UniqueDeviceId = payload.SAVED_DEVICE_TOCKEN;
    let DeviceTypeName = payload.DEVICE_NAME;
    let LoginName = base64.encode(loginName);
    let Password = base64.encode(loginPassword);
    let KeyId = base64.encode(drmId);
    let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);

    let ContentUrlEncode = base64.encode(ContentUrl);
    let NowStamp = base64.encode(nowStampString);
    let DeviceTypeNameEncode = base64.encode(DeviceTypeName);

    keyServer =
      widewineLicense +
      "?" +
      "PlayState=" +
      PlayState +
      "&" +
      "DrmSystem=" +
      DrmSystem +
      "&" +
      "LoginName=" +
      LoginName +
      "&" +
      "Password=" +
      Password +
      "&" +
      "KeyId=" +
      KeyId +
      "&" +
      "UniqueDeviceId=" +
      UniqueDeviceIdEncode +
      "&" +
      "ContentUrl=" +
      ContentUrlEncode +
      "&" +
      "NowStamp=" +
      NowStamp +
      "&" +
      "DeviceTypeName=" +
      DeviceTypeNameEncode;
  } else if (selectedDrmCategory == 2) {
    var pos = payload?.LIVE_VIDEO_URL?.lastIndexOf(".");
    let firstPartUrl = payload?.LIVE_VIDEO_URL?.substring(0, pos);

    url1 = firstPartUrl + ".mpd";

    // "https://soapboxstreama.riversilica.com/Movies/demo.mp4/index.mpd";

    keyServer = payload?.AUTHENTICATION
      ? payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim() +
        "?assetId=" +
        payload?.CHANNEL_DATA[0]?.id +
        "&categoryId=3&userId=" +
        payload.USER_DETAILS?.id
      : payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
  }

  let data = {
    // src:
    //   "https://soapboxplayer.riversilica.com/genesis/medialocal_1/video/dwapara2.mp4",
    // videoType: "video/mp4",
    // src: url1,

    // type: "application/dash+xml",
    // keySystems: {
    //   "com.widevine.alpha": keyServer,
    // },
    src: url1,
    type: "application/dash+xml",
    keySystems: {
      "com.widevine.alpha": {
        videoRobustness: "SW_SECURE_CRYPTO",

        getLicense: (emeOptions, keyMessage, callback) => {
          videojs.xhr(
            {
              method: "POST",

              url: keyServer,

              responseType: "arraybuffer",

              body: keyMessage,
            },

            (err, response, responseBody) => {
              if (err) {
                callback(err);

                return;
              }

              callback(null, responseBody);
            }
          );
        },
      },
    },

    name: payload?.CHANNEL_DATA[0]?.title,
    id: payload?.CHANNEL_DATA[0]?.id,
  };
  let title = payload?.CHANNEL_DATA[0]?.title;

  let name = payload?.CHANNEL_DATA[0]?.title;
  let dataForPoster = {};
  if (
    payload?.GENERAl_POST_ADS[
      payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId
    ] != undefined
  ) {
    dataForPoster = {
      id: payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId,
      src:
        newImageIpPath +
        payload?.GENERAl_POST_ADS[
          payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId
        ]?.adv_url,
      time: Number(payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.duration),

      closeButtonInMilisec: 2000,
    };
  }

  // console.log(
  //   "payloadArunEpisodeVideoAdv" + JSON.stringify(payload?.CHANNEL_DATA)
  // );
  // console.log("payload.videoAdsArray" + JSON.stringify(payload.videoAdsArray));

  let PosteradConfig = [dataForPoster];
  let adConfig = payload.videoAdsArray;
  let sources = [data];
  allChannelList.push({
    sources,
    logo: payload?.CHANNEL_DATA[0]?.liveThumbnailPath + "thumbnail.png",
    poster: payload?.CHANNEL_DATA[0]?.liveThumbnailPath + "thumbnail.png",

    name,
    title,
    duration: payload?.CHANNEL_DATA[0]?.duration,
    id: payload?.CHANNEL_DATA[0]?.id,
    // index,
    PosteradConfig: PosteradConfig,
    adConfig: adConfig,
  });

  return allChannelList;
};
export const getPlayListforHLS = (payload) => {
  let allChannelList = [];

  var url1;

  var pos = payload?.LIVE_VIDEO_URL?.lastIndexOf(".");
  let firstPartUrl = payload?.LIVE_VIDEO_URL?.substring(0, pos);

  url1 = firstPartUrl + ".m3u8";

  let data = {
    src: url1,
    type: "application/x-mpegURL",

    name: payload?.CHANNEL_DATA[0]?.title,
    id: payload?.CHANNEL_DATA[0]?.id,
  };
  let title = payload?.CHANNEL_DATA[0]?.title;

  let name = payload?.CHANNEL_DATA[0]?.title;
  let dataForPoster = {};
  if (
    payload?.GENERAl_POST_ADS[
      payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId
    ] != undefined
  ) {
    dataForPoster = {
      id: payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId,
      src:
        newImageIpPath +
        payload?.GENERAl_POST_ADS[
          payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId
        ]?.adv_url,
      time: Number(payload?.CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.duration),

      closeButtonInMilisec: 2000,
    };
  }

  // console.log(
  //   "payloadArunEpisodeVideoAdv" + JSON.stringify(payload?.CHANNEL_DATA)
  // );
  // console.log("payload.videoAdsArray" + JSON.stringify(payload.videoAdsArray));

  let PosteradConfig = [dataForPoster];
  let adConfig = payload.videoAdsArray;
  let sources = [data];
  allChannelList.push({
    sources,
    logo: payload?.CHANNEL_DATA[0]?.liveThumbnailPath + "thumbnail.png",
    poster: payload?.CHANNEL_DATA[0]?.liveThumbnailPath + "thumbnail.png",

    name,
    title,
    duration: payload?.CHANNEL_DATA[0]?.duration,
    id: payload?.CHANNEL_DATA[0]?.id,
    // index,
    PosteradConfig: PosteradConfig,
    adConfig: adConfig,
  });

  return allChannelList;
};
export const getPlayListForFairplay = (payload) => {
  let allChannelList = [];
  let selectedDrmCategory = payload?.DRM_DETAILS?.selectedDrmCategory || null;
  let fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
  let fairplayLicense = payload?.DRM_DETAILS?.data[0]?.fairplayLicense?.trim();
  let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
  let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
  let uniqueDeviceId = payload?.SAVED_DEVICE_TOCKEN;
  let deviceTypeName = payload?.DEVICE_NAME;

  const assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map(
    (item) => payload?.ASSET_DETAILS[item]
  );
  let catchup = 0;

  assetDetails?.forEach((a, index) => {
    let startOverUrl, keyServer;
    const pos = a?.assetUrl?.lastIndexOf(".");
    const firstPartUrl = a?.assetUrl?.substring(0, pos);
    let date = new Date();

    let flag = 0;
    let timestamp = date?.getTime();

    // ***************PACKAGE_DETAILS**************
    for (var p = 0; p < a?.packages?.length; p++) {
      let stopDate =
        payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

      let stopDateTimeStamp = parseInt(Date.parse(stopDate));
      if (
        a?.packages[p]?.assetGroupId ==
          payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]?.packageId &&
        timestamp < stopDateTimeStamp
      ) {
        flag = 1;
      }
    }
    // ************ALACARTE_DETAILS***********************
    let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
    let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

    if (
      a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
      payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampAlacarte
    ) {
      flag = 1;
    }
    // *****************PAY_PER_VIEW_DETAILS*************
    let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
    let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

    if (
      a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
      // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampPPV
    ) {
      flag = 1;
    }
    // Catch-up or live URL
    if (
      payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg &&
      a?.catchupRequired === 1
    ) {
      const timeStamp =
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg.startTimeTimestamp /
        1000;
      startOverUrl = `${firstPartUrl}-${timeStamp}-now.m3u8`;
      catchup = 1;
    } else {
      startOverUrl = `${firstPartUrl}.m3u8`;
      catchup = 0;
    }

    // DRM Category 1
    if (selectedDrmCategory === 1) {
      const encodedParams = {
        PlayState: "1",
        DrmSystem: "Fairplay",
        LoginName: base64.encode(loginName),
        Password: base64.encode(loginPassword),
        KeyId: base64.encode(a?.drmId),
        UniqueDeviceId: base64.encode(uniqueDeviceId),
        ContentUrl: base64.encode(startOverUrl),
        NowStamp: base64.encode(new Date().toISOString()),
        DeviceTypeName: base64.encode(deviceTypeName),
      };

      keyServer = `${fairplayLicense}?${Object.entries(encodedParams)
        .map(([key, val]) => `${key}=${val}`)
        .join("&")}`;
    }

    // DRM Category 2
    if (selectedDrmCategory === 2) {
      keyServer = payload?.AUTHENTICATION
        ? `${fairplayLicense}?assetId=${a?.id}&categoryId=1&userId=${payload?.USER_DETAILS?.id}`
        : fairplayLicense;
    }

    // Fetch DRM Certificate and License
    const getCertificate = (emeOptions, callback) => {
      videojs.xhr(
        {
          method: "GET",
          url: fairplayCertificate,
          responseType: "arraybuffer",
        },
        (err, response, responseBody) => {
          if (err) return callback(err);
          callback(null, new Uint8Array(responseBody));
        }
      );
    };

    const getLicense = (emeOptions, contentId, keyMessage, callback) => {
      videojs.xhr(
        {
          method: "POST",
          url: keyServer,
          responseType: "string",
          body: keyMessage,
        },
        (err, response, responseBody) => {
          if (err) return callback(err);
          callback(null, licenseRequestLoaded(responseBody));
        }
      );
    };

    // Ad Configuration
    const adConfig = getAdConfig(payload, a);

    // Build Channel Data
    allChannelList.push({
      sources: [
        {
          src: startOverUrl,
          type: "application/x-mpegURL",
          keySystems: {
            "com.apple.fps.1_0": {
              videoRobustness: "SW_SECURE_CRYPTO",
              getCertificate,
              getLicense,
            },
          },
        },
      ],
      logo: `${a?.liveThumbnailPath}thumbnail.png`,
      poster: `${a?.liveThumbnailPath}thumbnail.png`,
      name: a?.title,
      title: a?.title,
      searchItem: `${a?.title}${a?.logicalChannelNumber}`,
      logicalChannelNumber: a?.logicalChannelNumber,
      epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
      default: index === 4,
      flag,
      id: a?.id,
      drm: a?.drmId,
      catchup,
      timeStamp:
        payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,
      PosteradConfig: adConfig.PosteradConfig,
      adConfig: adConfig.VideoadConfig,
    });
  });

  return allChannelList;
};

// Helper function for ad configuration
const getAdConfig = (payload, asset) => {
  const posterAd =
    payload?.GENERAl_POST_ADS[asset?.liveAssetsPostAds?.[0]?.adId];
  const videoAd = payload?.VIDEO_ADS[asset?.liveAssetsVideoAds?.[0]?.adId];

  return {
    PosteradConfig: posterAd
      ? [
          {
            id: asset?.liveAssetsPostAds[0]?.adId,
            src: `${newImageIpPath}${posterAd.adv_url}`,
            time: Number(asset?.liveAssetsPostAds[0]?.duration),
            closeButtonInMilisec: 2000,
          },
        ]
      : [],
    VideoadConfig: videoAd
      ? [
          {
            id: asset?.liveAssetsVideoAds[0]?.adId,
            src: videoAd?.videoUrl?.includes("http")
              ? videoAd.videoUrl
              : `${newImageIpPath}${videoAd.videoUrl}`,
            time: Number(asset?.liveAssetsVideoAds[0]?.duration),
            skip: Number(asset?.liveAssetsVideoAds[0]?.isSkipRequired)
              ? Number(asset?.liveAssetsVideoAds[0]?.skipBeyond)
              : undefined,
          },
        ]
      : [],
  };
};

// Helper function to check flag
const checkFlag = (payload, asset) => {
  const timestamp = Date.now();

  const packageValid = asset?.packages?.some((pkg) => {
    const stopDate = payload?.PACKAGE_DETAILS?.[pkg.assetGroupId]?.stopDate;
    return (
      pkg.assetGroupId ===
        payload?.PACKAGE_DETAILS?.[pkg.assetGroupId]?.packageId &&
      timestamp < Date.parse(stopDate)
    );
  });

  const alacarteValid =
    payload?.ALACARTE_DETAILS?.[asset?.id] &&
    timestamp < Date.parse(payload?.ALACARTE_DETAILS?.[asset?.id]?.expiryDate);

  const ppvValid =
    payload?.PAY_PER_VIEW_DETAILS?.[1]?.[asset?.id] &&
    timestamp <
      Date.parse(payload?.PAY_PER_VIEW_DETAILS?.[1]?.[asset?.id]?.expiryDate);

  return packageValid || alacarteValid || ppvValid ? 1 : 0;
};

// Helper function for license request loaded
const licenseRequestLoaded = (stringbuffer) => {
  const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
  }
  return bytes;
};
