import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import { newImageIpPath } from "../../App";
import { generalSettingsSelector } from "../../Redux/live/selectors";

const FooterPage = ({ CURRENT_PLATFORM, GENERAL_DATA }) => {
  //  alert(JSON.stringify(CURRENT_PLATFORM.brandLogo))
  let companyLogo;
  if (CURRENT_PLATFORM !== undefined && CURRENT_PLATFORM != null) {
    companyLogo = newImageIpPath + CURRENT_PLATFORM?.brandLogo;
  }
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 40000000,
        // height: "100px"
        // paddingTop: "100px",
        // marginTop: "100px",
      }}
    >
      <footer id="contact" className="footer-one iq-bg-dark">
        <div className="mt-0">
          <div className="container-fluid mt-0">
            <div className="row footer-standard">
              <div className="col-9 col-sm-9 col-xs-9 col-md-9 col-lg-9 col">
                <div className="widget text-left">
                  <div className="textwidget mt-3 mb-4">
                    <div>
                      <small style={{ color: "gray" }}>
                        {GENERAL_DATA?.copyrightMessage}
                        {/* © 2024 Dish Media Network Ltd. All rights reserved. */}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" col-2 col-sm-2 col-xs-2 col-md-2 col-lg-2 col-md-3  mt-lg-0"
                style={{
                  // backgroundColor: "red",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {/* <div className="widget text-left">
                  <div className="textwidget">
                   
                    <div className="d-flex align-items-center mt-3 ml-5">
                      <div className="app-image" href=""> */}
                <img
                  src={companyLogo}
                  style={{
                    maxHeight: "60px",
                    maxWidth: "100px",
                    // zIndex: 300,
                  }}
                  alt="My Awesome logo"
                />
                {/* </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(FooterPage);
