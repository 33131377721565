/** @format */

import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";
export const addDeviceAsync = async (payload) => {
  try {
    // Encrypt userId and make it URL-safe
    const ciphertext = encryptDataForHeader(payload?.userId);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    // Make the API request with the encrypted userId
    const response = await axiosInstance().post(
      `/V2/register/device/${ciphertext}`,
      payload
    );

    return response;
  } catch (error) {
    return Promise.reject(error.response ? error.response.data : error);
  }
};