/** @format */
import React, { useEffect, useRef, useState } from "react";
import "./../../LiveVideoPlayerComponent/video.css";
import videojs from "video.js";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import "./../../skins/chrome/videojs.min.css";
import "./../../skins/chrome/videojs.css";
import "./../../skins/chrome/ima.min.css";
import "./../../skins/chrome/ima.css";
import * as MOVIE_DATA from "./MovieData";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch } from "react-redux";
import { baseName } from "../../IpConstantFile/IpConstantFile";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../../Redux/platforms/selectors";
import { useHistory, useLocation } from "react-router-dom";
import {
  getGeneralAdsDetailSelector,
  getgeneralVideoAdsDetailsSelector,
} from "../../../Redux/AllLiveDetails/selectors";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../../Redux/deviceRedux/selectors";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";
import { getDrmDetailsSelector } from "../../../Redux/DRM integration/selectors";
import { getMovieDataForPlayerSelector } from "../../../Redux/playerMovieData/selector";
import * as VOD_ASSET from "./../../../Redux/playerMovieData/action";
import * as DATA_ANALYTICS from "./../../../Redux/DATA-analytics/actions.js";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../../Redux/package&subscriptions/selector";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../../Redux/package&subscriptions/actions";
import { getCurrentTimeZone, getPiPStateFromStorage, newImageIpPath } from "../../../App.js";
import {
  configurationSelector,
  continueWatchingSelector,
  watchedHistorySelector,
} from "../../../Redux/DATA-analytics/selector.js";
import { generalSettingsSelector } from "../../../Redux/live/selectors.js";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors.js";
import { Item } from "semantic-ui-react";
import "./../../loader/loadingPlugin.js";
import "./../../retryPlugin/retry.js"; // Import the loading plugin

import { getDefaultPlatformsSelector } from "../../../Redux/DefaultPlatform/selectors.js";
import { getSaveDeviceTockenSelector } from "../../../Redux/SaveDeviceTocken/selectors.js";
import "../../LatestVideoJsPlugin/MoviePlugin/moviePlugin.js";
import "../../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../../LatestVideoJsPlugin/keywordControlPluginWithIcons.js";
import "../../LatestVideoJsPlugin/replayButtonPlugin.js";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../../services/datAnalytics/dataAnalytics.js";
const MovieVideoJs = ({
  CURRENT_PLATFORM,
  VIDEO_ADS,
  SELECTED_MOVIE_DATA,
  setIsVideoPlayer,
  PLATFORM_ID,
  DEVICE_NAME,
  DEVICE_TOKEN,
  DEVICE_STATUS,
  DRM_DETAILS,
  GENERAl_POST_ADS,
  PACKAGE_DETAILS,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  CONFIGURATION_DAS,
  GENERAL_DATA,
  CONTINUE_WATCHING_DATA,
  USER_DETAILS,
  AUTHENTICATION,
  DEFAULT_PLATFORM,
  SAVED_DEVICE_TOCKEN,
  setSelectedCategory,
  WATCHES_HISTORY_DATA,
}) => {
  let time;
  const dispatch = useDispatch();
  const history = useHistory();
  const timeZone = getCurrentTimeZone();

  // console.log("DRM_DETAILS", JSON.stringify(DRM_DETAILS));
  const location = useLocation().state;
  let hlsKey = Number(GENERAL_DATA?.playOnlyHls);


  
  const durationForPLayer = CONTINUE_WATCHING_DATA?.filter(
    (item) =>
      Number(item.categoryId) === 2 &&
      item.assetId === SELECTED_MOVIE_DATA.id &&
      Number(item?.isVideoEnded) === 0 &&
      Number(item?.videoDeleted) === 0
  );
  useEffect(() => {
    dispatch(VOD_ASSET.getMovieDataForPlayer(SELECTED_MOVIE_DATA));
  }, []);

  let flag = 0;
  let date = new Date();
  let timestamp = date?.getTime();
  var currentTrackNo = 0;
  let videoAdStatus = null;

  let adsEnabledForDefaultPlatform;
  if (DEFAULT_PLATFORM != undefined && DEFAULT_PLATFORM != null) {
    adsEnabledForDefaultPlatform = DEFAULT_PLATFORM?.data?.data?.isAdsEnabled;
  }
  const adsEnabled = AUTHENTICATION
    ? Object.values(PACKAGE_DETAILS).some(
        (item) => item?.assetGroup?.isAdsEnabled === 1
      )
    : adsEnabledForDefaultPlatform;
  let videoAdsArray = [];
  // ***************PACKAGE_DETAILS**************
  for (var p = 0; p < SELECTED_MOVIE_DATA?.packages?.length; p++) {
    let stopDate =
      PACKAGE_DETAILS?.[SELECTED_MOVIE_DATA?.packages?.[p]?.assetGroupId]
        ?.stopDate;

    let stopDateTimeStamp = parseInt(Date.parse(stopDate));
    if (
      SELECTED_MOVIE_DATA?.packages[p]?.assetGroupId ==
        PACKAGE_DETAILS?.[SELECTED_MOVIE_DATA?.packages[p]?.assetGroupId]
          ?.packageId &&
      date?.getTime() < stopDateTimeStamp
    ) {
      flag = 1;
    }
  }
  // ************ALACARTE_DETAILS***********************
  let stopDateAlacarte =
    ALACARTE_DETAILS?.[SELECTED_MOVIE_DATA?.id]?.expiryDate;
  let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

  if (
    SELECTED_MOVIE_DATA?.id ==
      ALACARTE_DETAILS?.[SELECTED_MOVIE_DATA?.id]?.assetId &&
    ALACARTE_DETAILS?.[SELECTED_MOVIE_DATA?.id]?.categoryId == 2 &&
    timestamp < stopDateTimeStampAlacarte
  ) {
    flag = 1;
  }
  // *****************PAY_PER_VIEW_DETAILS*************
  let stopDatePPV =
    PAY_PER_VIEW_DETAILS?.[2]?.[SELECTED_MOVIE_DATA?.id]?.expiryDate;
  let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

  if (
    SELECTED_MOVIE_DATA?.id ==
      PAY_PER_VIEW_DETAILS?.[2]?.[SELECTED_MOVIE_DATA?.id]?.assetId &&
    // PAY_PER_VIEW_DETAILS?.[SELECTED_MOVIE_DATA?.id]?.categoryId == 2 &&
    new Date()?.getTime() < stopDateTimeStampPPV
  ) {
    flag = 1;
  }
  const convertTimeToNumber = (timeString) => {
    if (!timeString) {
      // Handle the case where timeString is undefined or null
      return 0; // Or any default value you prefer
    }
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const numericFormat = totalSeconds.toFixed(6);
    return parseFloat(numericFormat);
  };
  const calculatePercentageViewed = (viewedDuration, totalDuration) => {
    const viewedDurationParts = viewedDuration.split(":");
    const totalDurationParts = totalDuration.split(":");

    const viewedDurationInSeconds =
      parseInt(viewedDurationParts[0]) * 3600 +
      parseInt(viewedDurationParts[1]) * 60 +
      parseInt(viewedDurationParts[2]);

    const totalDurationInSeconds =
      parseInt(totalDurationParts[0]) * 3600 +
      parseInt(totalDurationParts[1]) * 60 +
      parseInt(totalDurationParts[2]);

    const percentageViewed =
      (viewedDurationInSeconds / totalDurationInSeconds) * 100;
    const percentageViewedInteger = Math.floor(percentageViewed);

    return percentageViewedInteger;
  };

  const [signInMessage, setSignInMessage] = useState(false);
  const [isVideoAdsAvailable, setIsVideoAdAvailable] = useState(null);

  document.title = SELECTED_MOVIE_DATA?.title;
  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  const videoJsOptions = {
    controls: true,

    aspectRatio: "16:8",
    autoplay: true,
    preload: "auto",
    responsive: true,
    // playsinilie: true,
    shareMenu: false,
    poster: newImageIpPath + SELECTED_MOVIE_DATA?.thumbnailUrl,
    qualitySelector: false, // Disable quality selector

    controlBar: {
      volumePanel: {
        inline: false, // Horizontal volume control
      },
    },

    // controls: true,
    // aspectRatio: "16:8",
    // autoplay: true,
    // preload: "auto",
    // responsive: true,
    // // playsinilie: true,
    // shareMenu: false,
    // poster: newImageIpPath + SELECTED_MOVIE_DATA?.thumbnailUrl,
    // qualitySelector: false, // Disable quality selector
    // controlBar: {
    //   volumePanel: {
    //     inline: false, // Horizontal volume control
    //   },
    // },
    // controlBar: {
    //   children: [
    //     "skipBackward", // Add a skip backward button (custom)
    //     "playToggle", // Play/pause button
    //     "skipForward", // Add a skip forward button (custom)
    //     "currentTimeDisplay",
    //     "progressControl",
    //     "durationDisplay",
    //     // "muteToggle",
    //     // "volumeControl",
    //     "pictureInPictureToggle", // Add Picture-in-Picture button

    //     "fullscreenToggle",
    //   ],
    // },
  };

  let platformLogo = newImageIpPath + CURRENT_PLATFORM?.productLogo;
  let navigateLocation = null;
  let navPath = null;
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;

  if (AUTHENTICATION === true) {
    if (location.from == "mylist") {
      navigateLocation = baseName + "mylistcontainer";
      navPath = "/mylistcontainer";
    } else if (location?.from == "genreCategory") {
      navigateLocation = baseName + "MovieGenreContainer";
      navPath = "/MovieGenreContainer";
    } else {
      navigateLocation = baseName + "container";
      navPath = "/container";
    }
  } else {
    if (location?.from == "genreCategory") {
      navigateLocation = baseName + "MovieGenreContainer";
      navPath = "/MovieGenreContainer";
    } else {
      if (defaultLandingPage == 2) {
        navPath = "";
        navigateLocation = baseName;
      } else {
        navPath = "/Container";
        navigateLocation = baseName + "Container";
        setSelectedCategory(2);
      }
    }
  }

  let player;
  const videoNode = useRef(null);

  useEffect(() => {
    player = videojs(
      videoNode.current,
      videoJsOptions,
      function onPlayerReady() {
        // var player = this;

        player.on("error", function() {
          var error = player.error();
          localStorage.setItem("isPlaying", false);

          console.error("Video.js Error:", error);

          player.pause();
        });
        // start 1 code

        player.on("loadedmetadata", function() {
          localStorage.setItem("isPlaying", true);

          if (AUTHENTICATION === true && flag != 0) {
            document.addEventListener("keydown", (event) => {
              if (event.key === " ") {
                if (player.paused()) {
                  player.play();
                } else {
                  player.pause();
                }
              }
            });
          }

          // Add the track to the player's audio track list.
          var audioTracks = player.audioTracks();

          if (audioTracks.length == 1) {
            audioTracks[0].enabled = true;
          } else if (audioTracks.length > 1) {
            audioTracks[currentTrackNo].enabled = true;
          }
          //alert(audioTracks.length);
          //audioTracks[currentTrackNo].enabled = true;

          // sourceBuffer.addEventListener("updateend", function() {
          //   alert("hii");
          // });
          audioTracks.addEventListener("change", function() {
            for (var i = audioTracks.length - 1; i >= 0; i--) {
              var track = audioTracks[i];
              if (track.enabled) {
                currentTrackNo = i;
              }
            }
          });
        });
        // end 2 code
        player.eme();
        player.keywordControlPluginWithIcons();

        player.loadingPlugin();
        player.errorOverlayPlugin(navigateLocation);
        player.displayPlatFormLogo(platformLogo);
        player.replayButtonPlugin();

        if (durationForPLayer?.length < 1 || location?.startOver == 0) {
          SELECTED_MOVIE_DATA?.vodAssetsVideoAds?.map((a) => {
            let videoUrl = VIDEO_ADS[a?.adId]?.videoUrl;
            if (videoUrl != undefined && videoUrl != null) {
              if (!videoUrl?.includes("http")) {
                videoUrl = newImageIpPath + videoUrl;
              } else {
                videoUrl = videoUrl;
              }
            }

            let data;
            if (Number(a?.isSkipRequired) !== 0) {
              data = {
                id: a?.adId,
                src: videoUrl,
                time: Number(a?.duration),
                skip: Number(a?.skipBeyond), // Corrected inequality operator
              };
            } else {
              data = {
                id: a?.adId,

                src: videoUrl,
                time: Number(a?.duration),
              };
            }

            if (
              VIDEO_ADS[a?.adId] != undefined &&
              VIDEO_ADS[a?.adId] != null &&
              adsEnabled
            ) {
              videoAdsArray?.push(data);
            }
          });
        } else if (
          durationForPLayer != undefined &&
          durationForPLayer != null &&
          durationForPLayer[0]?.duration != undefined &&
          durationForPLayer[0]?.duration != null &&
          location?.startOver == 1 &&
          AUTHENTICATION === true &&
          Number(CURRENT_PLATFORM?.viewing_analytics_enabled) == 1 &&
          Number(USER_DETAILS?.viewing_analytics_status) == 1 &&
          flag === 1
        ) {
          const convertedTime = convertTimeToNumber(
            durationForPLayer[0].duration
          );
          player.currentTime(convertedTime);

          SELECTED_MOVIE_DATA?.vodAssetsVideoAds?.forEach((a) => {
            if (a?.duration > convertedTime) {
              let videoUrl = VIDEO_ADS[a?.adId]?.videoUrl;
              if (videoUrl != undefined && videoUrl != null) {
                if (!videoUrl.includes("http")) {
                  videoUrl = newImageIpPath + videoUrl;
                }
              }
              let data;
              if (Number(a?.isSkipRequired) !== 0) {
                data = {
                  id: a?.adId,
                  src: videoUrl,
                  time: Number(a?.duration),
                  skip: Number(a?.skipBeyond), // Corrected inequality operator
                };
              } else {
                data = {
                  id: a?.adId,
                  src: videoUrl,
                  time: Number(a?.duration),
                };
              }

              if (
                VIDEO_ADS[a?.adId] !== undefined &&
                VIDEO_ADS[a?.adId] !== null &&
                adsEnabled
              ) {
                videoAdsArray?.push(data);
              }
            }
          });
        }
        const hasPreroll = videoAdsArray.some((ad) => parseInt(ad.offset) == 0);
        if (videoAdsArray?.length > 0 && hasPreroll) {
          setIsVideoAdAvailable(true);
          localStorage.setItem("adStatus", 1);
        } else {
          setIsVideoAdAvailable(false);
          localStorage.setItem("adStatus", 0);
        }
        let Movie_Details;
        if (hlsKey == 0) {
          if (window.WebKitMediaKeys) {
            // console.log("ArunWebKitMediaKeys");
            Movie_Details = MOVIE_DATA.getPlayListForFairplay({
              SELECTED_MOVIE_DATA,
              DRM_DETAILS,
              SAVED_DEVICE_TOCKEN,
              DEVICE_NAME,
              durationForPLayer,
              GENERAl_POST_ADS,
              videoAdsArray,
              startOver: location?.startOver,
              AUTHENTICATION,
              USER_DETAILS,
            });
          } else if (window.MediaKeys) {
            // console.log("ArunMediaKeys");

            Movie_Details = MOVIE_DATA.getPlayList({
              SELECTED_MOVIE_DATA,
              DRM_DETAILS,
              SAVED_DEVICE_TOCKEN,
              DEVICE_NAME,
              durationForPLayer,
              GENERAl_POST_ADS,
              videoAdsArray,
              startOver: location?.startOver,
              AUTHENTICATION,
              USER_DETAILS,
            });
          } else {
            // console.log("Arunelse");

            Movie_Details = MOVIE_DATA.getPlayList({
              SELECTED_MOVIE_DATA,
              DRM_DETAILS,
              SAVED_DEVICE_TOCKEN,
              DEVICE_NAME,
              durationForPLayer,
              GENERAl_POST_ADS,
              videoAdsArray,
              startOver: location?.startOver,
              AUTHENTICATION,
              USER_DETAILS,
            });
          }
        } else {
          // console.log("Arunelse");

          Movie_Details = MOVIE_DATA.getPlayListforHLS({
            SELECTED_MOVIE_DATA,
            DRM_DETAILS,
            SAVED_DEVICE_TOCKEN,
            DEVICE_NAME,
            durationForPLayer,
            GENERAl_POST_ADS,
            videoAdsArray,
            startOver: location?.startOver,
            AUTHENTICATION,
            USER_DETAILS,
          });
        }
        player.moviePlugin(Movie_Details, 0);
        // console.log("Movie_Details" + JSON.stringify(SELECTED_MOVIE_DATA));
        // console.log("Movie_Details" + JSON.stringify(Movie_Details));
        const seekButtonStyle = document.createElement("style");
        document.head.appendChild(seekButtonStyle);

        const progressControl = player.controlBar.getChild("progressControl");
        if (AUTHENTICATION === true && flag == 1) {
          if (progressControl) {
            progressControl.enable();
          }
          seekButtonStyle.textContent =
            ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
        } else {
          const progressControl = player.controlBar.getChild("progressControl");
          if (progressControl) {
            progressControl.disable();
          }
          seekButtonStyle.textContent =
            ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
        }
        player.on("ended", () => {
          // var replayButton = document.querySelector(".vjs-replay-button");
          //  if (replayButton) {
          //   replayButton.style.display = "block";
          // }
        });

        player.on("posterAdplaying", function(event) {
          // Access the data in the event's detail property
          const adData = event.detail;
          // console.log("adDataadDataadDataadData", adData);

          if (AUTHENTICATION) {
            let data2 = {
              adsAnalyticsData: {
                addId: adData.id,

                userId: USER_DETAILS?.id,

                platformId: CURRENT_PLATFORM?.orgId,

                profileId: 1,

                deviceId: DEVICE_TOKEN,
                timeZone: timeZone,

                categoryId: 2,
                assetId: SELECTED_MOVIE_DATA?.id,
              },
              ServerIp: CONFIGURATION_DAS?.analyticsIp,
            };

            // console.log("adsAnalyticsData", data2); // This will log the ad id

            getSendingDataOfPosteradsAsync(data2);
          }
        });

        player.on("adplaying", function(event) {
          videoAdStatus = "playing";

          // Access the data in the event's detail property
          seekButtonStyle.textContent =
            ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";

          localStorage.setItem("adStatus", 1);
        });
        player.on("adended", function(event) {
          // alert("hiii");
          videoAdStatus = "ended";
          const adData = event.detail;
          // console.log("adData", adData);
          if (AUTHENTICATION) {
            let data2 = {
              adsAnalyticsData: {
                addId: adData.id,

                userId: USER_DETAILS?.id,

                platformId: CURRENT_PLATFORM?.orgId,

                profileId: 1,

                deviceId: DEVICE_TOKEN,
                timeZone: timeZone,

                categoryId: 2,
                assetId: SELECTED_MOVIE_DATA?.id,
              },
              ServerIp: CONFIGURATION_DAS?.analyticsIp,
            };

            getSendingDataOfVideoadsAsync(data2);
          }

          setIsVideoAdAvailable(false);

          localStorage.setItem("adStatus", 0);

          if (AUTHENTICATION === true && flag == 1) {
            if (progressControl) {
              progressControl.enable();
            }
            seekButtonStyle.textContent =
              ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
          } else {
            if (progressControl) {
              progressControl.disable();
            }
            seekButtonStyle.textContent =
              ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
          }
        });
        player.play();
      }
    );

    function setPiPStateInStorage(isPiPMode) {
      localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
    }

    document.addEventListener("enterpictureinpicture", () => {
      // PiP mode entered
      setPiPStateInStorage(true);
    });

    document.addEventListener("leavepictureinpicture", () => {
      // PiP mode exited
      setPiPStateInStorage(false);
    });

    let i = 0;
    let time;

    player.on("timeupdate", function(e) {
      player.on("play", function() {
        // var replayButton = document.querySelector(".vjs-replay-button");

        // // Check if the replay button exists and then hide it
        // if (replayButton) {
        //   replayButton.style.display = "block";
        // }
        localStorage.setItem("isPlaying", true);
      });
      player.on("pause", function() {
        localStorage.setItem("isPlaying", false);
      });
      player.on("waiting", function() {
        this.addClass("vjs-waiting");
        localStorage.setItem("isPlaying", false);
      });
      // player.on("waiting", function() {
      //   this.addClass("vjs-waiting");
      //   // player.reconnect();
      // });
      player.on("playing", function() {
        this.removeClass("vjs-waiting");
        localStorage.setItem("isPlaying", true);
      });
      const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${hours
          .toString()
          .padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      };
      const totalDuration = player.duration();
      const currentTime = player.currentTime();
      const formattedTotalDuration = formatTime(totalDuration);
      const formattedCurrentTime = formatTime(currentTime);
      const dataToInsert = {
        duration: formattedCurrentTime, // replace with your actual duration
        totalDuration: formattedTotalDuration, // replace with your actual total duration
      };
      const percentageViewed = calculatePercentageViewed(
        dataToInsert.duration,
        dataToInsert.totalDuration
      );
      if (SELECTED_MOVIE_DATA != undefined && SELECTED_MOVIE_DATA != null) {
        dataAnalytics = {
          assetId: SELECTED_MOVIE_DATA?.id,
          categoryId: 2,
          duration: isInvalidDuration(formattedCurrentTime)
            ? "00:00:00"
            : formattedCurrentTime,
          isEligibleForView: 0,
          isVideoEnded: 0,
          totalDuration: isInvalidDuration(formattedTotalDuration)
            ? "00:00:00"
            : formattedTotalDuration,
          videoDeleted: "0",
        };
      }
      function isInvalidDuration(duration) {
        // Check if duration is in "NaN:NaN:NaN" format or any other invalid format
        return /^NaN:NaN:NaN$/.test(duration);
      }
      let percentageGiven =
        CONFIGURATION_DAS?.vodViewUnit == undefined
          ? 30
          : CONFIGURATION_DAS?.vodViewUnit;
      if (percentageViewed > percentageGiven) {
        dataAnalytics.isEligibleForView = 1;
      }
      if (percentageViewed > 85) {
        dataAnalytics.isVideoEnded = 1;
      } else {
        dataAnalytics.isVideoEnded = 0;
      }

      if (
        SELECTED_MOVIE_DATA?.id ==
        PAY_PER_VIEW_DETAILS?.[2]?.[SELECTED_MOVIE_DATA?.id]?.assetId
      ) {
        if (
          SELECTED_MOVIE_DATA?.id ==
            PAY_PER_VIEW_DETAILS?.[2]?.[SELECTED_MOVIE_DATA?.id]?.assetId &&
          new Date()?.getTime() > stopDateTimeStampPPV
        ) {
          player?.pause();

          if (player?.isFullscreen()) {
            player?.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          Swal.fire({
            icon: "warning",
            text: "Your subscription for this content has expired",
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = navigateLocation;
            }
          });
        }
      }
      if (player.currentTime() > time) {
        if (AUTHENTICATION === true) {
          if (flag == 0) {
            setSignInMessage(true);
          }
        }

        if (AUTHENTICATION === false) {
          setSignInMessage(true);
        }

        setTimeout(() => {
          if (AUTHENTICATION === true) {
            if (flag == 0) {
              // alert(location?.flag + "    " + navigateLocation);
              player?.pause();

              history.replace(navPath);
            }
          }

          if (AUTHENTICATION === false) {
            player?.pause();
            history.replace(navPath);
          }
        }, 1000);
      }
    });
    return () => {
      if (player) {
        const seekButtonStyle = document.createElement("style");
        document.head.appendChild(seekButtonStyle);
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
        player.pause();
        player?.dispose();
        videoNode.current = null;
      }
    };
  }, [videoNode]);

  useEffect(() => {
    player = videojs(videoNode.current, videoJsOptions);

    let i = 0;
    let timeForAlert;

    player.on("timeupdate", function(e) {
      let adsStatus = localStorage.getItem("adStatus");

      if (player.currentTime() < 1) {
        // console.log(" ooooooooooookmk  it is zerooooo" + player.currentTime());
        timeForAlert = 0;
        i = 0;
      }
      player.on("loadstart", () => {});

      if (
        i == 0 &&
        adsStatus == 0 &&
        player.currentTime() > 0
        //  &&         isVideoAdsAvailable != true &&
        // isVideoAdsAvailable != null
      ) {
        // console.log("!!!!!!!!!!!!     insilde ifff");
        timeForAlert = player.currentTime() + 30;

        i++;
      }
      // });
      let initialDeffrence =
        Number(timeForAlert) - Number(player.currentTime());
      if (initialDeffrence > 30) {
        let deffrence = initialDeffrence - 30;
        timeForAlert = timeForAlert - deffrence;
      }
      let j = 1;
      // console.log(
      //   "time" + timeForAlert + "currunt    time    " + player.currentTime()
      // );
      // console.log("isVideoAdsAvailable" + isVideoAdsAvailable);

      if (timeForAlert != 0 && player.currentTime() > timeForAlert) {
        // alert("jii");
        // console.log(
        //   "ooooooooooookmk" +
        //     player.currentTime() +
        //     "                   " +
        //     timeForAlert
        // );

        if (AUTHENTICATION === true) {
          if (flag == 0) {
            player.pause();
            let text = "";
            if (player?.isFullscreen()) {
              player?.exitFullscreen();
            }
            const isPiPMode = getPiPStateFromStorage();
            if (isPiPMode === true) {
              document.exitPictureInPicture(); // Exit PiP mode when navigating back
            }
            if (SELECTED_MOVIE_DATA?.isPPVRequired == 1) {
              text = "Please subscribe to watch.";
            } else {
              text =
                "Please subscribe or upgrade your package to view this content.";
            }
            Swal.fire({
              icon: "warning",
              text: text,
              allowOutsideClick: () => !Swal.isLoading(),
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Subscribe",
              cancelButtonText: "May be later",
              showCancelButton: true,
              showCloseButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(GENERAL_DATA?.packageSubscriptionUrl);
              }
            });

            // document.removeEventListener("keydown", spaceKeyHandler);

            history.replace(navPath);
          }
        }

        if (AUTHENTICATION === false) {
          // console.log("pooja");

          if (player?.isFullscreen()) {
            player?.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          player.pause();
          // document.removeEventListener("keydown", spaceKeyHandler);
          Swal.fire({
            icon: "warning",
            // position: "top-end",
            text:
              "You are not Signed in. Please Sign in to continue watching the video.",
            allowOutsideClick: () => !swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          }).then((OK) => {
            if (OK) {
              player.pause();
              // document.removeEventListener("keydown", spaceKeyHandler);
              setSelectedCategory(2);
              history.replace(navPath);
            }
          });
        }

        if (AUTHENTICATION === false) {
        }
      }
    });
  }, [isVideoAdsAvailable]);
  // .............................
  let dataAnalytics = {};
  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let interval = setInterval(
        () => {
          let dataAnalyticsForAPI = {};
          dataAnalyticsForAPI = {
            platformId: CURRENT_PLATFORM?.orgId,
            profileId: 1,
            deviceId: DEVICE_TOKEN,
            timeZone: timeZone,

            userId: USER_DETAILS?.id,
            assets: {},
          };
          let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));

          if (dataObject && typeof dataObject === "object") {
            const keys = Object.keys(dataObject);
            let finalData = [];

            keys.forEach((a) => {
              finalData?.push(dataObject?.[a]);
            });

            if (finalData != undefined && finalData != null) {
              dataAnalyticsForAPI.assets = JSON.stringify(finalData);
            }

            let data2;
            if (
              USER_DETAILS != undefined &&
              USER_DETAILS != null &&
              AUTHENTICATION != undefined &&
              AUTHENTICATION != null &&
              CONFIGURATION_DAS != null &&
              CONFIGURATION_DAS != undefined
            ) {
              data2 = {
                dataAnalyticsForAPI: dataAnalyticsForAPI,
                ServerIp: CONFIGURATION_DAS?.analyticsIp,
              };
            }
            dispatch(DATA_ANALYTICS.sendingDataToDasStart(data2));
          }
        },
        CONFIGURATION_DAS?.frequencyForSendingDataToServer == undefined
          ? 300000
          : Number(CONFIGURATION_DAS?.frequencyForSendingDataToServer) + 6500
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);
  let matchedHistory =
    WATCHES_HISTORY_DATA?.length > 0 &&
    WATCHES_HISTORY_DATA?.some((item) => {
      return (
        Number(item.assetId) === Number(SELECTED_MOVIE_DATA?.id) &&
        Number(item.categoryId) === 2
      );
    });

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1 &&
      flag == 1
    ) {
      const intervalId = setInterval(
        () => {
          let dataObject =
            JSON.parse(localStorage.getItem("MovieDataToLocal")) || {};

          // Check if dataAnalytics is not undefined or null
          // console.log("videoAdStatus" + videoAdStatus);

          if (
            dataAnalytics &&
            typeof dataAnalytics === "object" &&
            JSON.parse(localStorage.getItem("isPlaying")) == true &&
            videoAdStatus != "playing"
          ) {
            if (
              dataAnalytics.assetId !== undefined &&
              dataAnalytics.categoryId !== undefined
            ) {
              // Create a unique key for the current dataAnalytics
              // console.log("videoAdStatusArun" + videoAdStatus);

              const uniqueKey =
                dataAnalytics.assetId + "_" + dataAnalytics.categoryId;

              // Update or add the new data to the object
              dataObject[uniqueKey] = dataAnalytics;
              // dispatch(DATA_ANALYTICS.fetchPlayerData(dataAnalytics));

              // console.log("WATCHES_HISTORY_DATA", WATCHES_HISTORY_DATA);

              if (matchedHistory == true) {
                // console.log("pragathiiiiiiiiiiiii");
                matchedHistory = false;
                dispatch(
                  DATA_ANALYTICS.removeWatchesHistoryStart({
                    dataForRemoveContinueWatching: {
                      platformId: CURRENT_PLATFORM?.orgId,

                      profileId: 1,
                      userId: USER_DETAILS?.id,
                      assetId: Number(dataAnalytics?.assetId),
                      categoryId: 2,
                      type: "single",
                    },
                    ServerIp: CONFIGURATION_DAS?.analyticsIp,
                  })
                );
              }
              dispatch(DATA_ANALYTICS.fetchPlayerDataSuccess(dataAnalytics));

              // Update local storage with the modified object
              localStorage.setItem(
                "MovieDataToLocal",
                JSON.stringify(dataObject)
              );
            }
          }
          // alert("in movie Player sending local" + JSON.stringify(dataObject));
        },
        CONFIGURATION_DAS?.frequencyForStoringDataInApp == undefined
          ? 30000
          : CONFIGURATION_DAS?.frequencyForStoringDataInApp
      );

      // Cleanup function to clear the interval when the component is unmounted or conditions are met
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    if (signInMessage == true) {
      const isPiPMode = getPiPStateFromStorage();
      if (isPiPMode === true) {
        document.exitPictureInPicture(); // Exit PiP mode when navigating back
      }
      if (AUTHENTICATION === true) {
        if (flag == 0) {
          let text = "";

          if (SELECTED_MOVIE_DATA?.isPPVRequired == 1) {
            text = "Please subscribe to watch.";
          } else {
            text =
              "Please subscribe or upgrade your package to view this content.";
          }

          Swal.fire({
            icon: "warning",
            text: text,
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe",
            cancelButtonText: "May be later",
            showCancelButton: true,
            // showConfirmButton:
            //   SELECTED_MOVIE_DATA?.isPPVRequired == 1 ? true : false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(GENERAL_DATA?.packageSubscriptionUrl);
            }
          });
        }
      } else if (AUTHENTICATION === false) {
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "You are not Signed in. Please Sign in to continue watching the video.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        }).then((OK) => {});
      }
    }
  }, [signInMessage]);

  return (
    <div className="container">
      <div data-vjs-player>
        <video
          ref={videoNode}
          preload="none"
          className="video-js vjs-fluid autoplay"
          controls
          autoplay
        ></video>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  SELECTED_MOVIE_DATA: getMovieDataForPlayerSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  VIDEO_ADS: getgeneralVideoAdsDetailsSelector,
  PLATFORM_ID: platformsSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  DRM_DETAILS: getDrmDetailsSelector,
  GENERAl_POST_ADS: getGeneralAdsDetailSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CONFIGURATION_DAS: configurationSelector,
  GENERAL_DATA: generalSettingsSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  WATCHES_HISTORY_DATA: watchedHistorySelector,
});

export default connect(mapStateToProps)(MovieVideoJs);
