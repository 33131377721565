import ACTION_TYPES from "./action.types";

const {
  LOGIN_SUCCESS_NEW,
  LOGIN_FAILED,
  VERIFY_LOGIN_START,

  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,

  USER_DETAILS_START,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAILED,

  LOGIN_STATUS_FALSE,
  UPDATE_ACCESS_TOKEN,
} = ACTION_TYPES;

const initialState = {
  isAuthenticated: false,
  loginLoading: false,
  singupLoading: false,
  rememberMeLoginLoding: false,
  userDetails: {},
  loginDetails: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS_NEW:
      return {
        ...state,
        loginDetails: payload?.loginData,
        isAuthenticated: true,
        loginLoading: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loginLoading: false,
        isAuthenticated: false,
      };

    case VERIFY_LOGIN_START:
 
      return {
        ...state,
        isAuthenticated: false,
        loginLoading: true,
      };

    case SIGNUP_START:
      return {
        ...state,
        singupLoading: true,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        singupLoading: false,
      };

    case SIGNUP_FAILED:
      return {
        ...state,
        singupLoading: false,
      };

    case USER_DETAILS_START:
      return {
        ...state,
        rememberMeLoginLoding: true,
      };

    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload?.data?.[0],
        rememberMeLoginLoding: false,
      };

    case USER_DETAILS_FAILED:
      return {
        ...state,
        rememberMeLoginLoding: false,
      };

    case LOGIN_STATUS_FALSE:
      return {
        ...state,
        isAuthenticated: false,
      };

    case UPDATE_ACCESS_TOKEN:
      let data = { ...state.loginDetails, access_token: payload?.accessToken };

      return {
        ...state,
        loginDetails: data,
        // isAuthenticated: true,
        // loginLoading: false,
      };

    default:
      return state;
  }
};
