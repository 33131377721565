/** @format */

import Axios from "axios";
import CryptoJS from "crypto-js";
import axiosRetry from "axios-retry";
import { serverIpPath } from "../components/IpConstantFile/IpConstantFile";
import { SK } from "../Redux/Search/actions";
import * as AUTH_ACTION from "../Redux/authentication/actions.js";
import * as activeAccessTokenService from "../services/activeAccessToken/activeAccessToken.js";
import store from "../Redux/store.js";
import { HeadersAcessToken, HeadersAcessTokenForFormData } from "../components/HeadersAcessToken.js"; // Importing both header functions

const SKK = SK;
var qs = require("qs");

// export const encryptData = (data) => {
//   const jsonString = JSON.stringify(data);
//   return CryptoJS.AES.encrypt(jsonString, SKK).toString();
// };
const SECRET_KEY = CryptoJS.enc.Utf8.parse("6DWwxcMnPSSaLo6E");


export const encryptDataForHeader = (data) => {
  try {
    // Convert to string if not already
    const dataToEncrypt = String(data);

    // Encrypt using AES with ECB mode and PKCS7 padding
    const encryptedBytes = CryptoJS.AES.encrypt(dataToEncrypt, SECRET_KEY, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert encrypted data to Base64
    const base64Ciphertext = encryptedBytes.toString();

    // Make it URL-safe (but keep '=' characters to avoid padding issues)
    const urlSafeCiphertext = base64Ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, ""); // Remove trailing '='

    return urlSafeCiphertext;
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};



export const encryptData = (data) => {
  try {
    if (typeof data !== "string") {
      data = JSON.stringify(data);
    }

    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(data),

      SECRET_KEY,

      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );

    return encrypted.toString(); // Convert encrypted data to Base64 string
  } catch (error) {
    console.error("Encryption failed:", error);

    return null;
  }
};

// export function encryptData(data) {
//   if (typeof data !== "string") {
//     data = JSON.stringify(data); // Convert objects to JSON strings
//   }

//   // Ensure the key is 16 bytes (128 bits)
//   let key = CryptoJS.enc.Utf8.parse(SKK);
//   if (key.sigBytes !== 16) {
//     throw new Error("Key must be 16 bytes for AES encryption.");
//   }

//   // Encrypt the data using AES with ECB mode and PKCS5 padding
//   let encrypted = CryptoJS.AES.encrypt(data, key, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7,
//   });

//   // Convert encrypted data to Base64
//   return encrypted.toString();
// }

// Pad the data to ensure its length is a multiple of 16 bytes

// const decryptData = (cipherText) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(cipherText, SKK);
//     const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
//     return JSON.parse(decryptedString);
//   } catch (error) {
//     console.log("Decryption failed:", error);
//     return cipherText;
//   }
// };

export const decryptData = (encryptedBase64) => {
  try {
    if (!encryptedBase64) {
      throw new Error("Invalid input: Encrypted data is missing.");
    }

    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedBase64,

      SECRET_KEY,

      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );

    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8).trim();

    if (!decryptedText) {
      throw new Error("Decryption resulted in an empty string.");
    }

    return JSON.parse(decryptedText); // Parse JSON if applicable
  } catch (error) {
    console.error("Decryption failed:", error.message);

    return null;
  }
};

const axiosInstance = () => {
  const headers = {};
  const axiosInstance = Axios.create({
    baseURL: serverIpPath,
    headers,
  });
  axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: (_error) => true, // retry no matter what
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const accessToken = store.getState().authenticationReducer.loginDetails.access_token;

    if (accessToken) {
      if (req.headers["Content-Type"]?.includes("multipart/form-data")) {
        req.headers = {
          ...req.headers,
          ...HeadersAcessTokenForFormData(accessToken),
        };
      } else {
        req.headers = {
          ...req.headers,
          ...HeadersAcessToken(accessToken),
        };
      }
    }
     if (
      req.data &&
      !req.headers["Content-Type"]?.includes("multipart/form-data") &&
      typeof req.data === "object" &&
      !req.data.data 
      // &&
      // !req.url.includes("/epgjson") &&
      // !req.url.includes("/genre/liveDetails") &&
      // !req.url.includes("/liveAssetsBasedOnkey") &&
      // !req.url.includes("/getGeneralDetails") &&
      // !req.url.includes("/genre/vodDetails") &&
      // !req.url.includes("/genre/seriesDetails")
    ) {
      
      req.data = { data: encryptData(req.data) };
    }

    return req;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      // console.log("response.dataresponse.data",response.data.encryptedData)

 
       if (response?.data?.encryptedData) {

      response.data = decryptData(response.data.encryptedData);
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && error.response?.data?.auth === false) {
        try {
          const refreshToken = store.getState().authenticationReducer.loginDetails.refresh_token;
          const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync({ refreshToken });
          const newAccessToken = newAccessTokenResponse.data.accessToken;

          await store.dispatch(AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data));

          originalRequest.headers["x-access-token"] = newAccessToken;
          originalRequest.headers = {
            ...originalRequest.headers,
            ...HeadersAcessToken(newAccessToken),
          };

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
    
      if (typeof error?.response?.data.encryptedData === "string") {


        error.response.data = decryptData(error.response.data.encryptedData);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default axiosInstance;
