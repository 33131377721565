import "./loadingPlugin.css";
import videojs from "video.js";

const loadingPlugin = function(playerObj) {
  const player = this;
  let spinnerContainer;

  function showLoadingText() {
    spinnerContainer = document.createElement("div");
    spinnerContainer.className = "vjs-loading-spinner-container";
    spinnerContainer.style.zIndex = "10"; // Keep it below controls
    spinnerContainer.style.position = "absolute";
    spinnerContainer.style.left = "50%";
    spinnerContainer.style.top = "50%";
    spinnerContainer.style.transform = "translate(-50%, -50%)";
    spinnerContainer.style.pointerEvents = "none"; // Ensure controls are clickable
    spinnerContainer.innerHTML = `
      <div class="lds-spinner">
        <div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div>
      </div>
    `;

    player.el().appendChild(spinnerContainer);

    player.addClass("vjs-loading");
  }

  function hideLoadingSpinner() {
    if (spinnerContainer && spinnerContainer.parentNode) {
      spinnerContainer.parentNode.removeChild(spinnerContainer);
      spinnerContainer = null;
    }

    player.removeClass("vjs-loading");
  }

  function forceHideSpinner() {
    const spinnerElements = document.querySelectorAll(".lds-spinner");

    // Hide each spinner element
    spinnerElements.forEach((spinnerElement) => {
      spinnerElement.style.display = "none";
    });
    if (spinnerContainer) {
      spinnerContainer.style.backgroundColor = "red";
      spinnerContainer.style.display = "none";
    }
    player.removeClass("vjs-loading");
  }

  player.on("waiting", showLoadingText);

  player.on("playing", function() {
    // console.log("$$$$$$$$$$   playing");
    hideLoadingSpinner();
    forceHideSpinner();
  });

  player.on("error", function() {
    // console.log("$$$$$$$$$$   errorr");
    hideLoadingSpinner();
    forceHideSpinner();
  });

  player.on("canplay", hideLoadingSpinner);
  player.on("canplaythrough", hideLoadingSpinner);

  // Expose forceHideSpinner function
  player.forceHideSpinner = forceHideSpinner;
};

videojs.registerPlugin("loadingPlugin", loadingPlugin);
