/** @format */

import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { newImageIpPath } from "../../App";
import "../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../loader/loadingPlugin.js";
import "../retryPlugin/retry.js"; // Import the loading plugin

const MovieScreenTeaserVideoJS = (item) => {
  // console.log("item.teaserUrl" + item.teaserUrl);
  let platformLogo = newImageIpPath + item.CURRENT_PLATFORM.productLogo;
  let isMuted = item.isMuted;
  let src = item.teaserUrl;
  let posterUrl = item.posterUrl;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(true); // Set showOverlay to true initially

  let type = null;
  if (src !== undefined && src !== null) {
    if (src.search(".mp4") >= 0) {
      type = "video/mp4";
    } else if (src.search(".m3u8") >= 0) {
      type = "application/x-mpegURL";
    }
  }

  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: false,
        autoplay: true,
        preload: "auto",
        fluid: true,
        aspectRatio: "16:8",
        muted: isMuted,
        loop: true,
      });

      playerRef.current.loadingPlugin();

      playerRef.current.src({ src, type });
      playerRef.current.displayPlatFormLogo(platformLogo);

      // Show overlay when an error occurs
      playerRef.current.on("error", errordisplay);

      // Show overlay when the player is paused
      playerRef.current.on("pause", () => setShowOverlay(true));

      // Hide overlay when the player starts playing
      playerRef.current.on("play", () => setShowOverlay(false));
    }

    // On component unmount, clean up the player
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [src, type]);

  function errordisplay() {
    // Hide the modal dialogs
    var modalDialogs = document.querySelectorAll(".video-js .vjs-modal-dialog");
    modalDialogs.forEach(function(dialog) {
      dialog.style.display = "none";
    });

    // Show the overlay image
    setShowOverlay(true);
  }

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.muted(isMuted);
    }
  }, [isMuted]);

  return (
    <div data-vjs-player style={{ position: "relative" }}>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin teaser-player"
      />
      {showOverlay && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <img
            src={posterUrl}
            alt="Error Poster"
            style={{
              width: "120%",
              height: "120%",
              filter: "blur(10px)",
              objectFit: "cover",
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps)(MovieScreenTeaserVideoJS);
