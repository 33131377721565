/** @format */

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
  authSelector,
  getDeviceLoadingSelector,
  getReplaceDeviceStatusSelector,
} from "../Redux/auth/selectors";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../Redux/platforms/selectors";
import * as ACTION_TYPES from "../Redux/auth/actions";
import NewLoadingPage from "./NewLoadingPage";
import * as ADD_DEVICE_ACTION from "./../Redux/deviceRedux/actions";
import "./devicesPage.css";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../Redux/deviceRedux/selectors";
import * as CURRENT_PLATFORM_ACTION from "./../Redux/platforms/actions";
import { border } from "@mui/system";
import ReactTooltip from "react-tooltip";
import * as EPG_ACTION from "./../Redux/EPG/action";
import { websocketPathForCheckDevice } from "./IpConstantFile/IpConstantFile";
import { getSaveDeviceTockenSelector } from "../Redux/SaveDeviceTocken/selectors";
import { socketStatusAPI } from "../services/datAnalytics/dataAnalytics";
import { newImageIpPath } from "../App";
import {
  authenticatedStatusSelector,
  loginDetailsSelector,
  userDetailsSelector,
} from "../Redux/authentication/selectors";
import * as AUTHENTICATION_ACTIONS from "../Redux/authentication/actions";
import * as DEVICE_STATUS_ACTION from "../Redux/Delete Device/actions";

const style = {
  container: {
    width: "90%",
    height: "300px",
    padding: "20px",

    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      height: "400px",
      color: "pink",
    },
    transition: "0.5s ease",
  },
};

const DevicesPage = ({
  AUTH,
  PLATFORMS,
  DEVICE_TOKEN,
  DEVICE_LOADING,
  CURRENT_PLATFORM,
  REPLACE_DEVICE_STATUS,

  SAVED_DEVICE_TOCKEN,
  DEVICE_NAME,

  USER_DETAILS,
  AUTHENTICATION,
  LOGIN_DETAILS,
  setSelectedCategory,
}) => {
  const history = useHistory();

  // const [socket, setSocket] = useState(null);
  // const [socketStatus, setSocketStatus] = useState(false);
  // console.log("oooo" + JSON.stringify(ws));
  // console.log("oooo" + socketStatus);

  let navigationPath;
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  if (defaultLandingPage == 1) {
    navigationPath = "/container";
  } else if (defaultLandingPage == 2) {
    navigationPath = "/container";
  } else if (defaultLandingPage == 3) {
    navigationPath = "/container";
  } else {
    navigationPath = "/container";
  }
  let brandBackgroundImage;
  if (CURRENT_PLATFORM != undefined && CURRENT_PLATFORM != null) {
    brandBackgroundImage =
      newImageIpPath + CURRENT_PLATFORM.brandBackgroundImage;
  }
  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.push("/login");
    }
  }, [AUTHENTICATION]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DEVICE_STATUS_ACTION.deviceStatus(true));

    // Auto Login
    dispatch(
      AUTHENTICATION_ACTIONS.userDetailsStart({
        id: USER_DETAILS?.id,
        history: history,
        navigationPath: navigationPath,

        newDeviceTocken: SAVED_DEVICE_TOCKEN,
        oldDeviceToken: DEVICE_TOKEN,
        from: "replaceDevice",
      })
    );
    // dispatch(
    //   AUTHENTICATION_ACTIONS.verifyLoginStart({
    //     username: LOGIN_DETAILS?.data.username,
    //     password: LOGIN_DETAILS?.password?.trim(),
    //     loginType: "4",
    //     account_id: PLATFORMS?.currentPlatformId,
    //     deviceId: DEVICE_TOKEN,
    //     rememberMe: LOGIN_DETAILS?.rememberMe,
    //     history: history,
    //     navigationPath,
    //     newDeviceTocken: SAVED_DEVICE_TOCKEN,
    //     oldDeviceToken: DEVICE_TOKEN,
    //   })
    // );

    let data2;
    if (USER_DETAILS != undefined && USER_DETAILS != null) {
      data2 = {
        account_id: PLATFORMS.currentPlatformId,
        userId: USER_DETAILS?.id,
        packageId: USER_DETAILS?.packageId,
      };
    }
    dispatch(ACTION_TYPES.getDeviceListStart(data2));
  }, []);

  useEffect(() => {
    setSelectedCategory(null);

    dispatch(
      CURRENT_PLATFORM_ACTION.getCurrentPlatformsId(USER_DETAILS?.orgId)
    );
  }, []);

  let osName = null;
  const replaceIconRef = useRef({});

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      osName = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      osName = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      osName = "Windows";
    } else if (/Android/.test(userAgent)) {
      osName = "Android";
    } else if (!os && /Linux/.test(platform)) {
      osName = "Linux";
    }

    return osName;
  }
  getOS();
  //alert(getOS());

  let browserName;
  function fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    // if (/Edg/.test(window.navigator.userAgent)) {
    //   alert("You are using Edge.");
    // }
    let firefox = userAgent.indexOf("Edg");
    //  alert(userAgent)
    if (/edg|Edg/i.test(userAgent)) {
      browserName = "edge";
    } else if (/chrome|chromium|crios/i.test(userAgent)) {
      browserName = "chrome";
    } else if (/firefox|fxios/i.test(userAgent)) {
      browserName = "firefox";
    } else if (/safari/i.test(userAgent)) {
      browserName = "safari";
    } else if (/opr\//i.test(userAgent)) {
      browserName = "opera";
    } else {
      browserName = "No browser detection";
    }
  }

  fnBrowserDetect();
  // console.log("oooo" + JSON.stringify(AUTH?.devices));
  let platFormDevices = AUTH?.devices.filter((item) => {
    if (item?.orgId == PLATFORMS?.currentPlatformId) {
      return item;
    }
  });

  const handleReplace = (item) => {
    var oldDeviceToken = item?.deviceId;
    // alert("oldDeviceToken" + oldDeviceToken);

    // let data = {
    //   deviceId: oldDeviceToken,
    //   message: "Replaced By Other Device  through API in webapplication",
    // };

    // if (socketStatus == true) {
    //   socketStatusAPI(data);
    // }

    // // if (socket != null) {
    // ws?.send(
    //   JSON.stringify({
    //     deviceId: oldDeviceToken,
    //     message: "Replaced By Other Device  through Socket in webapplication",
    //   })
    // );
    // }
    //  else {
    //   newWs?.send(
    //     JSON.stringify({
    //       deviceId: oldDeviceToken,
    //       message: "Replaced By Other Device  through Socket in webapplication",
    //     })
    //   );
    // }

    dispatch(
      ACTION_TYPES.replaceDeviceStart({
        deviceToken: SAVED_DEVICE_TOCKEN,
        oldDeviceToken,
        userId: USER_DETAILS?.id,
        account_id: PLATFORMS.currentPlatformId,
        deviceName: osName + "-" + browserName,
        oldDeviceName: item?.deviceName,
        deviceType: 1,
        history: history,
        navigationPath,
        packageId: USER_DETAILS?.packageId,
      })
    );

    dispatch(ADD_DEVICE_ACTION.saveDeviceTocken(SAVED_DEVICE_TOCKEN));
    dispatch(ADD_DEVICE_ACTION.saveDeviceName(osName + "-" + browserName));

    // dispatch(ACTION_TYPES.getDeviceListStart(data));
    // history.push("/profileList");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${brandBackgroundImage})`,
        backgroundAttachment: "fixed",
        // backgroundBlendMode: "hard-light",
        minHeight: "600px",
        height: "100%",
      }}
    >
      {/* {showDeviceList == true ? ( */}
      <div className="container">
        <div
          className="mb-4"
          style={{
            alignItems: "center",
            textAlign: "center",
            width: "100",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Montserrat,sans-serif",
              color: "white",
            }}
          >
            Device Limit Reached!
          </h3>
          <div style={{ color: "white" }}>
            You have reached the maximum{" "}
            <b>{USER_DETAILS?.totalNoOfDevices}&nbsp; devices</b> To continue,
            click below and remove a device you no longer use.
          </div>
        </div>
        {/* {AUTH.isLoading == false ? ( */}
        <div className="row" style={{ paddingBottom: "5%" }}>
          <div className="col-lg-3 col-xl-2" style={{}}></div>

          <div
            className="col-lg-3 col-xl-8  "
            style={{ color: "white", cursor: "pointer" }}
          >
            {platFormDevices.map((item, index) => (
              <div
                onClick={() => handleReplace(item)}
                onMouseOver={() => {
                  replaceIconRef.current[index].style.display = "inline-block";
                  // replaceIconRef.current[index].style.borderLeft =
                  //   "2px solid gray";
                }}
                onMouseLeave={() => {
                  replaceIconRef.current[index].style.display = "none";
                }}
                key={index}
                className="row p-4 m-2 mb-4 cardContainer"
                style={{ border: "2px solid white", borderRadius: 10 }}
              >
                <div
                  className="col-lg-2 col-xl-2 text-center"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.deviceType == 1 ? (
                    <img
                      height={90}
                      width={90}
                      className="mt-4"
                      src="images/login/laptop1.png"
                    />
                  ) : item.deviceType == 2 ? (
                    <img
                      height={90}
                      style={{ maxWidth: "90px", maxHeight: "80px" }}
                      src="images/login/moble1.png"
                    />
                  ) : item.deviceType == 3 ? (
                    <img height={90} width={90} src="images/login/tv2.png" />
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="col-lg-8 col-xl-8 text-center"
                  style={{ color: "white" }}
                >
                  <div
                    className="css-1old884-ProgramTitle e1f8qikj3"
                    style={{
                      fontSize: "24px",
                      textAlign: "center",
                      fontFamily: "Montserrat,sans-serif",
                      textTransform: "uppercase",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",

                      marginTop: "35px",
                      marginBottom: "35px",
                    }}
                  >
                    {item.deviceName}
                  </div>
                </div>
                <div
                  className="col-lg-2"
                  style={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "35px",
                    marginBottom: "35px",
                  }}
                >
                  {/* <img
                      ref={ref => (replaceIconRef.current[index] = ref)}
                      src="images/login/replaceicon.png"
                      style={{
                        display: "none",
                        width: "80px",
                        height: "80px",
                        cursor: "pointer"
                      }}
                      onClick={() => handleReplace(item)}
                    /> */}
                  <div
                    ref={(ref) => (replaceIconRef.current[index] = ref)}
                    style={{
                      display: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-lg-3 col-xl-2" style={{}}></div>
        </div>
        {/* ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <NewLoadingPage />
          </div>
        )} */}
      </div>
      {/* ) : (
        <NewLoadingPage />
      )} */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  AUTH: authSelector,
  PLATFORMS: platformsSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_LOADING: getDeviceLoadingSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  REPLACE_DEVICE_STATUS: getReplaceDeviceStatusSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  DEVICE_NAME: getDeviceNameSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,

  LOGIN_DETAILS: loginDetailsSelector,
});

export default connect(mapStateToProps)(DevicesPage);
