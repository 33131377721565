/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import { nodeimprovisationServerPath } from "../../components/IpConstantFile/IpConstantFile";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const getMovieDataAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();
  return await axiosInstance()
    .post(
      nodeimprovisationServerPath + "/V3/genre/vodDetails",
      payload,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};




