import axiosInstance, {  encryptDataForHeader } from "../../utils/axiosInstance";
var qs = require("qs");

export const checkExistanceDeviceAsync = async (payload) => {
  try {
    // Encrypt the userId
    const ciphertext = encryptDataForHeader(payload?.userId);

    if (!ciphertext) {
      throw new Error("Encryption failed");
    }

    // Make the API request with the encrypted userId
    const response = await axiosInstance().post(
      `V2/check/devices/${ciphertext}`,
      payload
    );

    return response ;
  } catch (error) {
    return Promise.reject(error.response ? error.response.data : error);
  }
};