import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import SeriesGenreCategory from "./SeriesGenreCategory";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors";
import { authenticatedStatusSelector } from "../../../Redux/authentication/selectors";
import { generalSettingsSelector } from "../../../Redux/live/selectors";
const SeriesGenreContainer = ({
  setIsVideoPlayer,
  DEVICE_STATUS,
  AUTHENTICATION,
  setSelectedCategory,
  GENERAL_DATA,
}) => {
  document.title = GENERAL_DATA?.appTitle;
  const history = useHistory();

  useEffect(() => {
    setIsVideoPlayer(false);
  }, []);

  const myRef = useRef(null);
  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  useEffect(() => {
    if (myRef.current) {
      // console.log("Scrolling slider to element:", myRef.current);

      requestAnimationFrame(() => {
        myRef?.current?.scrollIntoView({
          // behavior: "smooth",
          block: "start",
        });
      });
    }
  }, [myRef]);
  return (
    <div
      ref={myRef}
      className="main-content p-0 wrapper"
      style={{
        minHeight: "600px",
        backgroundSize: "cover",
        // backgroundImage: `url(${backgroundUrl})`,
        backgroundAttachment: "fixed",
        //// backgroundBlendMode: "hard-light",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section id="iq-favorites">
        <div
          className="container-fluid"
          // onClick={handleModalClose}
        >
          <div
            className="row"
            //onClick={handleModalClose}
          >
            <SeriesGenreCategory setSelectedCategory={setSelectedCategory} />
          </div>
        </div>
      </section>
      {/* <div style={{ height: "80px" }}>
        <FooterPage />
      </div> */}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  DEVICE_STATUS: getDeviceStatusSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GENERAL_DATA: generalSettingsSelector,
});
export default connect(mapStateToProps)(SeriesGenreContainer);
