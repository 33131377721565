/** @format */

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { getGenreDetailsPropsSelector } from "../../../Redux/live/selectors";
import {
  getSeriesAssetsFOrListingSelector,
  seriesAssetSelector,
  seriesGenresSelector,
} from "../../../Redux/SeriesGenre/selector";
import * as OFFSET_ACTION from "./../../../Redux/SeriesGenre/actions";
import SeriesPageDisplayImage from "../../SeariesPages/SeriesPageDisplayImage";

const SeriesGenreCategory = ({
  GENRE_PROPS,
  GENRE,
  SERIES_ASSETS_FOR_LISTING,
  SERIES_ASSETS,

  setSelectedCategory,
  
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    setSelectedCategory(3);

    history.push("/container");
  };

  useEffect(() => {
    let genreData = GENRE?.filter((a) => {
      if (a.id == GENRE_PROPS?.genreId) {
        // alert(JSON.stringify(a));
        return a;
      }
    });
    let data = {
      value: GENRE_PROPS?.value,
      finalLiveAssetDetils: genreData?.[0]?.genreassetassociation,
      displayType:genreData?.[0]?.displayType

    };
     dispatch(OFFSET_ACTION.getSeriesGenreAssetsForListing(data));
    //console.log("pooja", JSON.stringify(genreData?.[0]?.genreassetassociation));
  }, []);

  let newData = [];
  SERIES_ASSETS_FOR_LISTING?.finalLiveAssetDetils?.map((a) => {
    if (SERIES_ASSETS?.assetDetails?.[a?.assetId] != null) {
      newData?.push(SERIES_ASSETS?.assetDetails?.[a?.assetId]);
    }
  });
  //console.log("***" + JSON.stringify(newData));
  return (
    <div
      className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 p-0 m-0"
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div className="iq-main-header  align-items-center justify-content-between">
        <div
          className="row col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12"
          style={{
            zIndex: 200,
            position: "fixed",
            opacity: "0.7",
            backgroundColor: "rgba(52, 53, 53, 0.9)",
            width: "100%",
            height: "10%",
            top: 0,
          }}
        >
          <div
            className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1"
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              color="white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left"
              onClick={handleClick}
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </div>

          <h4
            className="main-title col-10 col-sm-10 col-xs-10 col-md-10 col-lg-10 ml-2"
            style={{
              fontFamily: "Montserrat,sans-serif",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              float: "left",
              color: "white",
            }}
          >
            {SERIES_ASSETS_FOR_LISTING?.value}
          </h4>
        </div>
      </div>

      <div
        className="col-12"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
          top: 50,
        }}
      >
        {SERIES_ASSETS_FOR_LISTING?.finalLiveAssetDetils?.map((item, index) =>
          item?.active == 1 ? (
            <div style={{  }}>
              <SeriesPageDisplayImage
                key={index}
                item={item}
                from={"genreCategory"}

                displayType={SERIES_ASSETS_FOR_LISTING.displayType}

              />{" "}
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  GENRE_PROPS: getGenreDetailsPropsSelector,
  GENRE: seriesGenresSelector,
  SERIES_ASSETS_FOR_LISTING: getSeriesAssetsFOrListingSelector,
  SERIES_ASSETS: seriesAssetSelector,
});

export default connect(mapStateToProps)(SeriesGenreCategory);
