/** @format */

import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import { nodeimprovisationServerPath } from "../../components/IpConstantFile/IpConstantFile";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const getEpgDetailsAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();
  // Get the current date and time
  const date = new Date();

  // Get the time zone offset in minutes (difference from UTC)
  const offsetMinutes = date.getTimezoneOffset();

  // Determine the sign of the offset
  const offsetSign = offsetMinutes > 0 ? "-" : "+";

  // Calculate the absolute offset in hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

  // Helper function to pad single-digit numbers with a leading zero
  function padZero(num) {
    return num.toString().padStart(2, "0");
  }

  // Format the offset as GMT+HHMM or GMT-HHMM
  const offset = `GMT${offsetSign}${padZero(offsetHours)}${padZero(
    offsetMinutesRemainder
  )}`;

  // Initialize state and status based on the offset sign
  let state = null;
  let status = null;
  if (offset.includes("+")) {
    state = "+";
    status = "plus";
  } else if (offset.includes("-")) {
    state = "-";
    status = "minus";
  }

  // Split the offset string to get the hour and minute parts
  let dateOffsetArray = offset.split(state);

  // Log the date offset array for debugging
  // console.log("arun dateOffsetArray: " + dateOffsetArray);

  // Split the programme date into its components
  let newData = payload?.programmeDate?.split("-");
  let formattedDatee = newData?.[0] + newData?.[1] + newData?.[2];

  // Create the final data object with the formatted date and timezone
  let finalData = {
    ...payload,
    timezone: status + dateOffsetArray?.[1],
    date: formattedDatee,
  };

  // Log the final data for debugging
  // console.log("arun finalData:", finalData);

  // Example output:
  // dateOffsetArray: GMT,0500
  // finalData: { programmeDate: "2024-05-21", timezone: "plus0500", date: "20240521" }

  // console.log("finalDatafinalDatafinalData" + JSON.stringify(finalData));
  // alert(JSON.stringify(finalData));
  return await axiosInstance()
    .post(nodeimprovisationServerPath + "/V3/epgjson", finalData, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // alert(JSON.stringify(error));
      // console.log("error response " + JSON.stringify(error));
      Promise.reject(error.response.data);
    });
};
