/** @format */

import React from "react";
import { connect, useDispatch } from "react-redux";

import { createStructuredSelector } from "reselect";
import "./../SeariesPages/seriesPageModal.css";

import DisplayMovieImage from "../DisplayMovieImage";
import MovieScreenDisplayMovieImage from "../MoviesScreens/MovieScreenDisplayMovieImage";
import SeriesPageDisplayImage from "../SeariesPages/SeriesPageDisplayImage";
import { generalSettingsSelector } from "../../Redux/live/selectors";

const SearchDisplayImage = ({ item, GENERAL_DATA }) => {
  let isLiveRequired = Number(GENERAL_DATA?.isLiveRequired);
  let isMovieRequired = Number(GENERAL_DATA?.isMovieRequired);
  let isSeriesRequired = Number(GENERAL_DATA?.isSeriesRequired);

  const getItem1 = (item) => {
    let data = { assetId: item.id, categoryId: 1, active: 1 };
    return data;
  };
  const getItem2 = (item) => {
    let data = { assetId: item.id, categoryId: 2, active: 1 };
    return data;
  };
  const getItem3 = (item) => {
    let data = { assetId: item.id, categoryId: 3, active: 1 };
    return data;
  };

  return (
    <div className="" style={{ display: "block" }}>
      {/* {item.categoryId == 3 ? (
        <div
          className="favorites-contens "
          style={{ display: "block", backgroundColor: "yellow" }}
        ></div>
      ) : item.categoryId == 1 &&
        item.searchLiveAsset != undefined &&
        item.searchLiveAsset != null ? (
        <DisplayMovieImage item={getItem(item.searchLiveAsset)} />
      ) : item.categoryId == 2 &&
        item?.searchVodAsset != undefined &&
        item?.searchVodAsset != null ? (
        <MovieScreenDisplayMovieImage item={getItem(item?.searchVodAsset)} />
      ) : (



        <></>
      )} */}
      {item.categoryId == 1 &&
        item.searchLiveAsset != undefined &&
        item.searchLiveAsset != null &&
        isLiveRequired == 1 && (
          <DisplayMovieImage
            item={getItem1(item.searchLiveAsset)}
            from={"search"}
          />
        )}
      {item.categoryId == 2 &&
        item.searchVodAsset != undefined &&
        item.searchVodAsset != null &&
        isMovieRequired == 1 && (
          <MovieScreenDisplayMovieImage
            item={getItem2(item?.searchVodAsset)}
            from={"search"}
          />
        )}
      {item.categoryId == 3 &&
        item.searchSeries != undefined &&
        item.searchSeries != null &&
        isSeriesRequired == 1 && (
          <SeriesPageDisplayImage
            item={getItem3(item?.searchSeries)}
            from={"search"}
          />
        )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(SearchDisplayImage);
