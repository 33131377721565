import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./action.types";
// import * as RootNavigation from "../../../../new_app/Navigation/RootNavigation";
import * as ACTION from "./actions";
import * as API from "../../services/authentication/authentication";
import * as DATA_ANALYTICS_ACTION from "../DATA-analytics/actions";
import * as EPG_ACTION from "./../EPG/action";

import * as DEVICE_ACTION from "../auth/actions";
import * as DEFAULTPLATFORM_ACTION from "../DefaultPlatform/actions";

import * as PACKAGE_ACTION from "../package&subscriptions/actions";
// import * as FAV_ACTION from "../favourites/actions";
import * as ACTION_DATA_VERSIONING from "../../Redux/Dataversion/actions";
import { getEmailInfoAsync } from "./../../services/manageprofile/manageProfileService";
import * as PLATFORM_ACTION_TYPES from "../../Redux/platforms/actions";
import * as DEVICE_STATUS_ACTION from "../../Redux/Delete Device/actions";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../Redux/package&subscriptions/actions";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import swal from "sweetalert";
import { decryptData } from "../../utils/axiosInstance";

const {
  VERIFY_LOGIN_START,
  SIGNUP_START,
  USER_DETAILS_START,
  LOGOUT_START,
  USER_DETAILS_ACTION,
} = ACTION_TYPES;

/** Sagas */
// let history = payload?.history;

function* verifyLoginSaga(payload) {
  //  alert("payload"+JSON.stringify(payload))

  try {  

    const data = yield call(API.loginAsync, payload);


    //  alert("data"+JSON.stringify(data))

    if (data?.data?.status == true) {
      yield put(DEVICE_STATUS_ACTION.deviceStatus(false));

      if (payload?.rememberMe == true) {
        // console.log("truerrrrrrrrrrrrrrree");

        // console.log("arun" + JSON.stringify(data?.data.data?.[0]));

        yield put(PLATFORM_ACTION_TYPES.getPlatformsListStart());
        yield put(
          DEVICE_ACTION.saveCredentialsForRememberMe({
            username: data?.data.data?.[0].username,
            password: payload?.password,
            remember_me: payload?.rememberMe,
          })
        );
      } else {
        // console.log("fallllllllllllsee");
        yield put(
          DEVICE_ACTION.saveCredentialsForRememberMe({
            username: null,
            password: null,
            remember_me: false,
          })
        );
      }
      let loginData = {
        data: data?.data?.data?.[0],
        rememberMe: payload?.rememberMe,
        password: payload?.password,
        access_token: data?.data?.access_token,
        refresh_token: data?.data?.refresh_token,
      };

      yield put(ACTION.loginSuccessNew({ loginData: loginData }));

      let privacyPolicyStatus = data?.data.data?.[0].privacyPolicyStatus;

      if (privacyPolicyStatus == 0) {
        yield put(
          ACTION.userDetailsAction({
            id: data?.data.data?.[0].userId,
            history: payload?.history,
            navigationPath: payload?.navigationPath,

            newDeviceTocken: payload?.newDeviceTocken,
            oldDeviceToken: payload?.oldDeviceToken,
          })
        );
        payload.history?.push("/privacyPolicy");
      } else {
        yield put(
          ACTION.userDetailsStart({
            id: data?.data.data?.[0].userId,
            history: payload?.history,
            navigationPath: payload?.navigationPath,

            newDeviceTocken: payload?.newDeviceTocken,
            oldDeviceToken: payload?.oldDeviceToken,
          })
        );
      }
    }
  } catch (message) {
 
 
    
    if (message != undefined) {
      // console.log("pppppppppppp" + JSON.stringify(message));
      // console.log("pppppppppppp" + JSON.stringify(payload));
      payload.history?.push("/login");

      Swal.fire({
        icon: "warning",
        // position: "top-end",
        text:  message.message,
        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      }).then((OK) => {
        if (OK) {
        }
      });
    }
    yield put(ACTION.loginFailed({ message }));
  }
}

function* signupSaga(payload) {
  try {
    const { data } = yield call(API.signupAsync, payload);
    if (data?.status == true) {
      yield put(ACTION.signupSuccess({ data }));

      Swal.fire({
        icon: "Info",

        // position: "top-end",
        text: "Successfully Registered",

        allowOutsideClick: () => !swal.isLoading(),
        confirmButtonColor: "#3085d6",
        button: "OK",
      }).then((OK) => {
        if (OK) {
        }
      });

      return;
    }
  } catch ({ message }) {
    Swal.fire({
      icon: "Warning",

      // position: "top-end",
      //  text: "Successfully Registered",

      allowOutsideClick: () => !swal.isLoading(),
      confirmButtonColor: "#3085d6",
      button: "OK",
    }).then((OK) => {
      if (OK) {
      }
    });

    yield put(ACTION.signupFailed({ message }));
  }
}

function* userDetailsSaga(payload) {
  try {
    const data = yield call(API.userDetailsAsync, payload);

    if (data?.status == true) {
      // console.log("arun user saga status true");
      // console.log("arun user saga" + JSON.stringify(payload.from));

      yield put(ACTION.userDetailsSuccess(data));

      let data1 = {
        platformId: data?.data[0]?.orgId,
        profileId: 1,
        userId: data?.data[0]?.id,
      };
      // console.log("default platform data       ", JSON.stringify(data1));

      yield put(DATA_ANALYTICS_ACTION.configurationStart(data1));

      yield put(
        PACKAGE_ACTION.getPackageDetailsStart({
          userId: data?.data?.[0]?.id,
        })
      );
      // yield put(ACTION_DATA_VERSIONING.checkDataVersions());

      let deviceData = {
        account_id: data?.data?.[0]?.orgId,
        userId: data?.data?.[0]?.id,
        maxNumOfDevice: data?.data?.[0]?.totalNoOfDevices,
        newDeviceTocken: payload?.newDeviceTocken,
        packageId: data?.data?.[0]?.packageId,
        oldDeviceToken: payload?.oldDeviceToken,
        history: payload.history,
      };
      yield put(
        DEVICE_ACTION.getDeviceListForAddingDeviceStart({
          deviceData,
          navigationPath: payload?.navigationPath,
          from: payload?.from,
        })
      );
    }
  } catch (message) {
    // RootNavigation.reset({
    //   index: 0,
    //   routes: [{ name: "BottomNavigation" }],
    // });
    yield put(ACTION.userDetailsFailed({ message }));
  }
}

function* logoutSaga(payload) {
  try {
    const { data } = yield call(API.logoutAsync, payload);

    if (data.status == true) {
      yield put(DEVICE_STATUS_ACTION.deviceStatus(true));

      yield put(DEVICE_ACTION.device_rigister_status(false));

      yield put(EPG_ACTION.clearEpgSuccess());

      yield put(DEFAULTPLATFORM_ACTION.getDefaultPlatformsListStart());
      yield put(ACTION.loginStausFalse());
      yield put(DATA_ANALYTICS_ACTION.clearContinueWatching());
      yield put(DATA_ANALYTICS_ACTION.clearWatchedHistory());
      yield put(PACKAGE_SUBSCRIPTION_ACTION.makePackageDetailsEmpty());

      payload.history?.push("/login");

      // yield put(FAV_ACTION.clearFavData());
      // if (ws != null) {
      //   ws.close();
      // }
      // if (wsLive != null) {
      //   wsLive.close();
      // }
    }
  } catch ({ message }) {
    yield put(DEVICE_STATUS_ACTION.deviceStatus(true));

    yield put(DEFAULTPLATFORM_ACTION.getDefaultPlatformsListStart());
    yield put(ACTION.loginStausFalse());
    // yield put(FAV_ACTION.clearFavData());
    yield put(DATA_ANALYTICS_ACTION.clearContinueWatching());
    yield put(DATA_ANALYTICS_ACTION.clearWatchedHistory());

    payload.history?.push("/login");

    // if (ws != null) {
    //   ws.close();
    // }
    // if (wsLive != null) {
    //   wsLive.close();
    // }
  }
}

function* userDetailsActionSaga(payload) {
  try {
    const data = yield call(API.userDetailsAsync, payload);

    if (data?.status == true) {
      // console.log("arun user saga status true");
      // console.log("arun user saga" + JSON.stringify(payload));

      yield put(ACTION.userDetailsSuccess(data));
    }
  } catch ({ message }) {
    yield put(ACTION.userDetailsFailed({ message }));
  }
}

/** Watchers or Observers */
function* verifyLoginWatcher() {
  while (true) {
    const { payload } = yield take(VERIFY_LOGIN_START);
    yield call(verifyLoginSaga, payload);
  }
}

function* signupWatcher() {
  while (true) {
    const { payload } = yield take(SIGNUP_START);
    yield call(signupSaga, payload);
  }
}

function* useDetailsWatcher() {
  while (true) {
    const { payload } = yield take(USER_DETAILS_START);
    yield call(userDetailsSaga, payload);
  }
}

function* useDetailsActionWatcher() {
  while (true) {
    const { payload } = yield take(USER_DETAILS_ACTION);
    yield call(userDetailsActionSaga, payload);
  }
}

function* logoutWatcher() {
  while (true) {
    const { payload } = yield take(LOGOUT_START);
    yield call(logoutSaga, payload);
  }
}

export default function*() {
  yield all([
    verifyLoginWatcher(),
    signupWatcher(),
    useDetailsWatcher(),
    logoutWatcher(),
    useDetailsActionWatcher(),
  ]);
}
