// import { Alert } from "react-native";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
import store from "./../../Redux/store.js";
import * as activeAccessTokenService from "./../activeAccessToken/activeAccessToken.js";
import * as AUTH_ACTION from "./../../Redux/authentication/actions.js";
var qs = require("qs");

export const getPlatformsListAsync = async () => {
  // Alert.alert("platform service is working");
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post(
      "/platforms",
      {},
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    )
    .then((response) => {
      // console.log(
      //   " arun response Platforms" + JSON.stringify(response)
      // );

      return response.data;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
