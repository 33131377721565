/** @format */

import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./action";
import * as EPG_API from "./../../services/Epg/epg";

const { GET_EPG_DETAILS_START } = ACTION_TYPES;

function* getEpgDetailsSaga(payload) {
  try {
    const { data } = yield call(EPG_API.getEpgDetailsAsync, payload);

    let programmeDate = payload.programmeDate;
    // alert(data.programmeDate);
    let programData = {
      data: data?.data,
      programmeDate,
    };

    // alert("saga   " + JSON.stringify(data));
    if (data?.status == true) {
      // alert("poooo");
      yield put(ACTION.getEpgDetailsSuccess({ programData }));
    } else {
      yield put(ACTION.getEpgDetailsFailed("error"));
    }
  } catch ({ message }) {
    yield put(ACTION.getEpgDetailsFailed({ message }));
  }
}

function* getEpgDetailsWatcher() {
  while (true) {
    const { payload } = yield take(GET_EPG_DETAILS_START);
    yield call(getEpgDetailsSaga, payload);
  }
}

export default function*() {
  yield all([getEpgDetailsWatcher()]);
}
