import videojs from "video.js";
import "./retryPlugin.css";

const errorOverlayPlugin = function(navigateLocation) {
  const player = this;
  let errorOverlay;
  let retryButton;
  let goBackButton;

  function showOverlay() {
    errorOverlay = document.createElement("div");
    errorOverlay.classList.add("custom-vjs-error-overlay");

    const errorMessages = document.createElement("div");
    errorMessages.classList.add("custom-vjs-error-messages");

    const errorMessage1 = document.createElement("div");
    errorMessage1.classList.add("custom-vjs-error-message");
    errorMessage1.innerText = "We're having trouble playing this video..";
    errorMessage1.style.fontWeight = "bold"; // Make the text bold

    const errorMessage2 = document.createElement("div");
    errorMessage2.classList.add("custom-vjs-error-message");
    errorMessage2.innerText = "Please try again later..";
    errorMessage2.style.fontWeight = "bold"; // Make the text bold

    errorMessages.appendChild(errorMessage1);
    errorMessages.appendChild(errorMessage2);

    const buttonContainer = document.createElement("div");
    buttonContainer.classList.add("custom-vjs-button-container");

    retryButton = document.createElement("button");
    retryButton.classList.add("custom-vjs-retry-button");
    retryButton.innerText = "Retry";
    retryButton.onclick = function() {
      player.trigger("retry");
      window.location.reload();
    };

    goBackButton = document.createElement("button");
    goBackButton.classList.add("custom-vjs-go-back-button");
    goBackButton.innerText = "Go Back";
    goBackButton.onclick = function() {
      player.trigger("goBack");
      window.location = navigateLocation;
    };

    buttonContainer.appendChild(retryButton);
    buttonContainer.appendChild(goBackButton);

    errorOverlay.appendChild(errorMessages);
    errorOverlay.appendChild(buttonContainer);

    player.el().appendChild(errorOverlay);
  }

  function hideOverlay() {
    if (errorOverlay && errorOverlay.parentNode) {
      errorOverlay.parentNode.removeChild(errorOverlay);
      errorOverlay = null;
    }
  }

  let adsStatus = localStorage.getItem("liveAdStatus");

  player.on("error", () => {
    if (adsStatus != 1) {
      showOverlay();
    } else {
      var j = videojs.dom;
      j.removeClass(player.el(), "vjs-ad-playing");
    }
  });

  player.on("playing", hideOverlay);
  player.hideOverlay = hideOverlay;
};

videojs.registerPlugin("errorOverlayPlugin", errorOverlayPlugin);
