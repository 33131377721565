import ACTIONSTYPES from "./actionTypes";

const {
  STORING_DATA_VERSIONS,
  NULL_DATA_VERSIONS,
  CHECK_DATA_VERSIONS,

  MAKE_VERSIONS_TRUE,
} = ACTIONSTYPES;

export const storingDataVersions = (payload) => ({
  type: STORING_DATA_VERSIONS,
  payload,
});

export const nullDataVersions = (payload) => ({
  type: NULL_DATA_VERSIONS,
  payload,
});

export const checkDataVersions = (payload) => ({
  type: CHECK_DATA_VERSIONS,
  payload,
});
export const makeDataVersionsTrue = (payload) => ({
  type: MAKE_VERSIONS_TRUE,
  payload,
});
