import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getPackageArraySelector,
  getPackageDetailsSelector,
} from "../../Redux/package&subscriptions/selector.js";
import "@sweetalert2/themes/dark/dark.scss";
import "./PlanDetails.css"; // Import custom CSS for styling
import { useHistory } from "react-router-dom";
import MainHeader from "../MainHeader.js";
import { authenticatedStatusSelector } from "../../Redux/authentication/selectors.js";

export const formattedDate = (dateString) => {
  const date = new Date(dateString);
  const day = date
    .getUTCDate()
    .toString()
    .padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const PlanDetails = ({
  PACKAGE_DETAILS,
  ORDERED_PACKAGES,
  setSearchState,
  setSearchKey,
  setClearSearchState,
  clearSearchState,
  selectedCategory,
  setSelectedCategory,
  AUTHENTICATION,
}) => {
  const history = useHistory();
  const packageArray = ORDERED_PACKAGES.map((key) => PACKAGE_DETAILS[key]);

  const closeSettings = () => {
    history.goBack();
  };
  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.replace("/login");
    }
  }, [AUTHENTICATION]);
  const calculateDaysToExpiry = (stopDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(stopDate);
    const differenceMs = expiryDate - currentDate;
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays;
  };

  return (
    <div className="plan-details-container">
      <MainHeader
        setSearchState={setSearchState}
        setSearchKey={setSearchKey}
        setClearSearchState={setClearSearchState}
        clearSearchState={clearSearchState}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className="container">
        <div className="spacer"></div>

        {/* <h2
            className="text-center mb-4"
            style={{
              color: "white",
              alignContent: "center",
            }}
          >
            Plan Details
          </h2> */}

        <div className="row justify-content-center mt-5">
          {packageArray.map((item, index) => (
            <div className="col-md-4" key={index}>
              <div className="card mb-4 plan-card text-white bg-dark">
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                  <div className="close-icon" onClick={() => closeSettings()}>
                    {/* Close icon SVG */}
                  </div>

                  <div className="text-center details-container">
                    <h5
                      style={{
                        fontFamily: "Montserrat,sans-serif",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className="card-title text-white font-weight-bold"
                    >
                      {item.assetGroup.name}
                    </h5>
                    {item.isBasePackage === 1 ? (
                      <>
                        <p className="card-text">Free Package</p>
                        {/* <p className="card-text">Purchased Date: ------</p>
                        <p className="card-text">Expiry Date: ------</p>
                        <p className="card-text">
                          No. of Days to Expiry: ------
                        </p> */}
                      </>
                    ) : (
                      <>
                        <p className="card-text">
                          Subscription Date: &nbsp;{" "}
                          {formattedDate(item.startDate)}
                        </p>
                        <p className="card-text">
                          Expiry Date: &nbsp; {formattedDate(item.stopDate)}
                        </p>
                        <p className="card-text">
                          No. of Days to Expiry: &nbsp;{" "}
                          {calculateDaysToExpiry(item.stopDate)}
                        </p>
                      </>
                    )}
                    {calculateDaysToExpiry(item.stopDate) < 0 && (
                      <p>Subscription to this package has expired</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ORDERED_PACKAGES: getPackageArraySelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(PlanDetails);
