import PlatForm from "./components/PlatformScreens/PlatForm";
import { useHistory, useLocation } from "react-router-dom";
import "./App.css";
import SeriesAssetViewPage from "./components/SeariesPages/SeriesAssetComponent/SeriesAssetComponent.js";
import SignUp from "./components/SignUp";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import Container from "./components/Container";
import DevicesPage from "./components/DevicesPage.js";
import ProfileContainer from "./components/ManageProfile/ProfileContainer";
import { useEffect, useState } from "react";
import ResetPasswordPage from "./components/resetPassword/ResetPasswordPage";
import ActivationPage from "./components/resetPassword/ActivationPage";
import MyListContainer from "./components/MyList Pages/MyListContainer";
import ProfileList from "./components/ManageProfileList/ProfileList";
import HandleProfile from "./components/ManageProfileList/HandleProfile";
import UpdateProfile from "./components/ManageProfileList/UpdateProfile";
import CreateProfile from "./components/ManageProfileList/CreateProfile";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ForgotResetPasswordPage from "./components/ForgotResetPasswordPage";
import GenreContainer from "./components/GenerList/LiveGenreList/GenreContainer.js";
import LoginScreen from "./components/Login screens/LoginScreen";
import DeleteDevicePage from "./components/Delete device/DeleteDevicePage";
import MovieGenreContainer from "./components/GenerList/MovieGenreList/MovieGenreContainer";
import SeriesGenreContainer from "./components/GenerList/SeriesGenreList/SeriesGenreContainer";
import EpgContainer from "./components/EpgScreens/EpgContainer";
import FooterPage from "./components/footerComponent/FooterPage";
import { createStructuredSelector } from "reselect";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "./Redux/platforms/selectors";
import { getDeviceStatusForDeleteDeviceSelector } from "./Redux/auth/selectors";
import {
  getDeviceDetailsSelector,
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "./Redux/deviceRedux/selectors";
import * as SAVE_DEVICE_TOCKEN_ACTION from "./Redux/SaveDeviceTocken/actions";
import * as VIDEO_ADS from "./Redux/video-adverticement/actions";

import * as LIVE_ACTION from "./Redux/live/actions.js";
import * as ACTION_TYPES from "./Redux/platforms/actions";

import * as DRM from "./Redux/DRM integration/actions";
import * as DEFAULT_PLATFORM from "./Redux/DefaultPlatform/actions";
import * as ACTION_DATA_VERSIONING from "./Redux/Dataversion/actions";

import { connect, useDispatch } from "react-redux";
import { websocketPathForCheckDevice } from "./components/IpConstantFile/IpConstantFile";
import PrivacyPolicyScreen from "./components/privacyPolicy/PrivacyPolicyScreen";
import AndroidOs from "./components/AndroidOs";
import Ios from "./components/Ios";
import MainVideoPlayer from "./components/LiveVideoPlayerComponent/MainVideoPlayer";
import SeriesVideoPlayer from "./components/SeariesPages/SeriesVideoPlayer/SeriesVideoPlayer";
import MovieVideoJs from "./components/MoviesScreens/MovieScreenVideoPlayerComponent/MovieVideoJs";
import EpgPlayerNew from "./components/EpgScreens/EpgPlayerNew";
import { getSaveDeviceTockenSelector } from "./Redux/SaveDeviceTocken/selectors";
import { getDefaultPlatformsForRouteSelector } from "./Redux/DefaultPlatform/selectors";
import * as PACKAGE_SUBSCRIPTION_ACTION from "./Redux/package&subscriptions/actions";

import store from "./Redux/store.js";
import { configurationSelector } from "./Redux/DATA-analytics/selector.js";

import Settings from "./components/Settings/Settings.js";
import PlanDetails from "./components/Settings/PlanDetails.js";
import {
  authenticatedStatusSelector,
  loginDetailsSelector,
  userDetailsSelector,
} from "./Redux/authentication/selectors.js";
import {
  generalSettingsSelector,
  getGeneralPathSelector,
  getNewGeneralPath,
} from "./Redux/live/selectors.js";
import * as ADD_DEVICE_ACTION from "./Redux/deviceRedux/actions";
import * as DATA_ANALYTICS from "./Redux/DATA-analytics/actions.js";

import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import GetStartedScreen from "./components/GetStartedScreen.js";
import LiveAssetViewPage from "./components/LiveAssetComponent/LiveAssetViewPage.js";
import MovieAssetViewPage from "./components/MoviesScreens/MovieAssetComponent/MovieAssetViewPage.js";
import TeaserPlayer from "./components/TeaserPlayer/TeaserPlayer.js";
export let ws = null;
export let wsLive = null;
export let socketStatus = true;
export let newImageIpPath = null;
export let athenticationStatus = null;
export function getPiPStateFromStorage() {
  const storedState = localStorage.getItem("pipState");
  const parsedState = storedState
    ? JSON.parse(storedState)
    : { isPiPMode: false };
  return parsedState.isPiPMode || false; // Return true if isPiPMode is true, otherwise return false
}

export function setPiPStateInStorage(isPiPMode) {
  localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
}
export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
function App({
  CURRENT_PLATFORM,
  DEVICE_TOKEN,
  SAVED_DEVICE_TOCKEN,
  CURRENT_PLATFORM_FOR_ROUTE,
  DEVICE_NAME,
  CONFIGURATION_DAS,

  USER_DETAILS,
  AUTHENTICATION,
  LOGIN_DETAILS,
  GENERAL_DATA,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLoad = async () => {
    await dispatch(LIVE_ACTION.getGeneralSettingsStart({ account_id: 0 }));
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const stateData = store.getState();
  newImageIpPath = stateData ? getNewGeneralPath(stateData) : "default/path"; // Fallback path

  athenticationStatus = authenticatedStatusSelector(stateData);
  const [selectedCategory, setSelectedCategory] = useState(2);
  // localStorage.setItem("scrollIndex", -1);
  useEffect(() => {
    localStorage.setItem("onmovies", false);
    sessionStorage.removeItem("scrollIndex");
  }, []);
  useEffect(() => {
    (async () => {
      await dispatch(ACTION_TYPES.getPlatformsListStart());
      // await dispatch(LIVE_ACTION.getGeneralSettingsStart({ account_id: 0 }));
    })();
  }, []);
  const [seriesEpisodeVideoUrl, setSeriesEpisodeVideoUrl] = useState();
  const [mylistLiveVideoDetails, setMylistLiveVideoDetails] = useState();
  const [sliderModalActive, setSliderModalActive] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [searchKey, setSearchKey] = useState();
  const [isVideoPlayer, setIsVideoPlayer] = useState(false);
  const [clearSearchState, setClearSearchState] = useState(false);
  const [duplicateTab, setDuplicateTab] = useState(false);

  let dataArray = localStorage.getItem("MovieDataToLocal");
  if (dataArray != undefined && dataArray != null && dataArray.length < 1) {
    let data = {};
    localStorage.setItem("MovieDataToLocal", JSON.stringify(data));
  }

  useEffect(() => {
    // Function to increment session count
    const incrementSessionCount = () => {
      const tabsOpen = sessionStorage.getItem("tabsOpen");

      // Check if the page is being loaded for the first time or after a hard refresh
      if (performance.navigation.type === 1) {
        // If it's a new navigation (hard refresh), reset the count to 1
        sessionStorage.setItem("tabsOpen", 1);
      } else {
        // If not a hard refresh, increment the count
        if (tabsOpen == null) {
          sessionStorage.setItem("tabsOpen", 1);
        } else {
          sessionStorage.setItem("tabsOpen", parseInt(tabsOpen) + 1);
        }
      }
    };

    // Call the function when the component mounts
    incrementSessionCount();

    // Add event listener to handle page unload (safari compatible)
    const handleUnload = () => {
      const newTabCount = sessionStorage.getItem("tabsOpen");
      if (newTabCount !== null) {
        sessionStorage.setItem("tabsOpen", newTabCount - 1);
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    // Check if more than one tab is open
    if (sessionStorage.getItem("tabsOpen") > 1) {
      setDuplicateTab(true);
      // const handleBeforeUnload = (e) => {
      //   e.preventDefault();
      //   e.returnValue = ""; // Chrome requires returnValue to be set
      // };
      // window.addEventListener("beforeunload", handleBeforeUnload);
      Swal.fire({
        icon: "warning",
        // position: "top-end",
        allowOutsideClick: () => !swal.isLoading(),

        title: "This is a duplicate tab.\n\nRedirecting to a blank page...",
        showConfirmButton: false,
        timer: 500,

        // button: "OK",
      });

      setTimeout(() => {
        window.location.replace("about:blank");
      }, 500);
      // alert("This is a duplicate tab.\n\nRedirecting to a blank page...");
      // window.location.replace("about:blank");
    } else {
      setDuplicateTab(false);
    }
  }, [sessionStorage.getItem("tabsOpen")]);

  useEffect(() => {
    const registerTabGUID = () => {
      if (typeof Storage !== "undefined") {
        if (!sessionStorage.getItem("tabGUID")) {
          sessionStorage.setItem("tabGUID", tabGUID());
        }
        const guid = sessionStorage.getItem("tabGUID");
        window.addEventListener("storage", storageHandler, false);
        localStorage.setItem("tabGUID", guid);
      }
    };
    const storageHandler = (e) => {
      if (e.key === "tabGUID" && e.oldValue !== e.newValue) {
        tabWarning();
      }
    };
    const tabGUID = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    };
    const tabWarning = () => {
      // alert("This is a duplicate tab.\n\nRedirecting to a blank page...");
      window.location.replace("about:blank");
    };
    registerTabGUID();
    return () => {
      window.removeEventListener("storage", storageHandler);
    };
  }, []);

  useEffect(() => {
    if (SAVED_DEVICE_TOCKEN == null || SAVED_DEVICE_TOCKEN == undefined) {
      function createGuid() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return (
          s4() +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          s4() +
          s4()
        );
      }
      let newDeviceTocken = createGuid();
      dispatch(SAVE_DEVICE_TOCKEN_ACTION.saveDeviceTocken(newDeviceTocken));
    }
  }, []);

  let brandBackgroundImage;
  if (CURRENT_PLATFORM != undefined && CURRENT_PLATFORM != null) {
    brandBackgroundImage =
      newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage;
  }
  let osName = null;

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      osName = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      osName = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      osName = "Windows";
    } else if (/Android/.test(userAgent)) {
      osName = "Android";
    } else if (!os && /Linux/.test(platform)) {
      osName = "Linux";
    }

    return osName;
  }
  getOS();
  let browserName;
  function fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    // if (/Edg/.test(window.navigator.userAgent)) {
    //   alert("You are using Edge.");
    // }
    let firefox = userAgent.indexOf("Edg");
    //  alert(userAgent)
    if (/edg|Edg/i.test(userAgent)) {
      browserName = "edge";
    } else if (/chrome|chromium|crios/i.test(userAgent)) {
      browserName = "chrome";
    } else if (/firefox|fxios/i.test(userAgent)) {
      browserName = "firefox";
    } else if (/safari/i.test(userAgent)) {
      browserName = "safari";
    } else if (/opr\//i.test(userAgent)) {
      browserName = "opera";
    } else {
      browserName = "No browser detection";
    }
  }
  fnBrowserDetect();

  useEffect(() => {
    if (DEVICE_NAME == null) {
      dispatch(ADD_DEVICE_ACTION.saveDeviceName(osName + "-" + browserName));
    }
    dispatch(DRM.getDrmDetailsStart());
  }, []);

  useEffect(() => {
    dispatch(VIDEO_ADS.getVideoAdverticementStart());
  }, []);

  useEffect(() => {
    const fetchDefaultPlatformsList = async () => {
      // try {
      if (LOGIN_DETAILS?.rememberMe !== true) {
        // await
        dispatch(DEFAULT_PLATFORM.getDefaultPlatformsListStart());
      }
      // } catch (error) {
      // Handle error for this specific API call
      // console.error("Error fetching default platforms list:", error);
      // }
    };

    fetchDefaultPlatformsList();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (AUTHENTICATION == false) {
          await dispatch(
            DEFAULT_PLATFORM.getDefaultPlatformsListForRouteStart()
          );

          // await dispatch(ACTION_DATA_VERSIONING.checkDataVersions());
        }
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const userId = USER_DETAILS?.id;
    if (AUTHENTICATION === true && userId !== undefined && userId !== null) {
      dispatch(
        PACKAGE_SUBSCRIPTION_ACTION.getPackageDetailsStart({
          userId: USER_DETAILS?.id,
        })
      );
    }
  }, []);

  useEffect(() => {
    const fetchData = () => {
      const userId = USER_DETAILS?.id;
      if (
        AUTHENTICATION === true &&
        userId !== undefined &&
        userId !== null &&
        Number(CURRENT_PLATFORM?.isDasEnabled) == 1
      ) {
        if (
          Number(CURRENT_PLATFORM?.viewing_analytics_enabled) == 1 &&
          Number(USER_DETAILS?.viewing_analytics_status) == 1
        ) {
          dispatch(
            DATA_ANALYTICS.fetchingDataForContinueWatchingStart({
              dataForFetchContinueWatching: {
                platformId: 1,
                profileId: 1,
                userId: USER_DETAILS?.id,
              },
              ServerIp: CONFIGURATION_DAS?.analyticsIp,
            })
          );
        }

        if (
          Number(CURRENT_PLATFORM?.isWatchedHistoryRequired) == 1 &&
          Number(USER_DETAILS?.watchHistoryStatus) == 1
        ) {
          dispatch(
            DATA_ANALYTICS.fetchingDataForWatchesHistoryStart({
              dataForFetchWatchesHistory: {
                platformId: 1,
                profileId: 1,
                userId: USER_DETAILS?.id,
              },
              ServerIp: CONFIGURATION_DAS?.analyticsIp,
            })
          );
        }
      }
    };

    fetchData(); // Initial call
    const intervalId = setInterval(fetchData, 300000); // Call every 5 minutes

    // Clear interval on component unmount or dependencies change
    return () => clearInterval(intervalId);
  }, [CONFIGURATION_DAS]);

  let domainWithoutProtocol = null;
  if (CONFIGURATION_DAS?.analyticsIp != undefined) {
    domainWithoutProtocol = CONFIGURATION_DAS?.analyticsIp?.replace(
      /^https?:\/\//,
      ""
    );
  }

  // console.log("athenticationStatus" + athenticationStatus);
  useEffect(() => {
    let dasWebSocket;

    dasWebSocket = () => {
      if (wsLive === null || wsLive.readyState !== WebSocket.OPEN) {
        let protocol = "ws"; // Default to "ws" for non-secure protocols

        if (CONFIGURATION_DAS?.analyticsIp) {
          try {
            // Try to construct the URL
            const url = new URL(CONFIGURATION_DAS.analyticsIp);
            // Check protocol and assign accordingly
            protocol = url.protocol === "https:" ? "wss" : "ws";
          } catch (error) {
            console.error("Invalid URL:", CONFIGURATION_DAS.analyticsIp, error);
          }
        } else {
          console.error("CONFIGURATION_DAS.analyticsIp is undefined or null.");
        }

        // Determine the correct URL
        const port = protocol === "ws" ? ":3100" : ""; // Add port only for HTTP (ws)
        wsLive = new WebSocket(
          `${protocol}://${domainWithoutProtocol}${port}/AssetStream/?account=Web`
        );

        console.log("ArunWebSocket Object:", wsLive); // Log WebSocket object here
      }
      wsLive.onopen = () => {
        const liveAssetId = localStorage.getItem("liveAssetId");

        console.log("ArunWebSocket Live connected" + athenticationStatus);
        if (wsLive !== null && liveAssetId !== null) {
          // console.log("close2");
          wsLive.send(
            JSON.stringify({
              type: "close",
              deviceId: DEVICE_TOKEN,
              assetId: liveAssetId,
            })
          );
        }

        localStorage.removeItem("liveAssetId");
        // Scroll to the top when the WebSocket connection is established
      };
      wsLive.onmessage = (event) => {
        const data = JSON.parse(event?.data);
        if (data?.type == "pingCheck") {
          // console.log("rfrr" + JSON.stringify(data?.type));
          wsLive.send(
            JSON.stringify({
              type: "pong",
              deviceId: DEVICE_TOKEN,
            })
          );
          // Send a pong response when a ping message is received
          //  wsLive.pong(); // Note: The 'pong' method is available in Node.js WebSocket libraries, ensure your client-side WebSocket library supports it.
        }
      };
      wsLive.onclose = () => {
        // console.log("Arunwebsocket Live disconnected" + athenticationStatus);

        if (athenticationStatus === true) {
          setTimeout(dasWebSocket, 60000); // Use setTimeout instead of setInterval
        }
      };
    };
    if (athenticationStatus === false) {
      if (wsLive != null) {
        if (wsLive != null || wsLive.readyState == WebSocket.OPEN) {
          // console.log(" Arunwebsocket closed");
          wsLive.close();
        }
      }
    }
    if (AUTHENTICATION === true && domainWithoutProtocol != null) {
      dasWebSocket();
    }
  }, [AUTHENTICATION, domainWithoutProtocol]);

  // console.log("AUTHENTICATION" + AUTHENTICATION);
  const Decrement_Cuncurrunt_Users = () => {
    if (localStorage.getItem("inLiveVidioPlayer") === "liveVideoPlayer") {
      const liveAssetId = localStorage.getItem("liveAssetId");
      // console.log("close1");

      if (wsLive !== null && liveAssetId !== null) {
        // console.log("close2");
        wsLive.send(
          JSON.stringify({
            type: "close",
            deviceId: DEVICE_TOKEN,
            assetId: liveAssetId,
          })
        );
      }

      localStorage.removeItem("liveAssetId");
      localStorage.removeItem("inLiveVidioPlayer");
    }
  };
  const [locationKeys, setLocationKeys] = useState([]);
  // Function to get PiP state from localStorage

  const isPiPMode = getPiPStateFromStorage();

  useEffect(() => {
    if (isPiPMode === true) {
      document.exitPictureInPicture(); // Exit PiP mode when navigating forward
    }
  }, [isPiPMode]);

  useEffect(() => {
    return history.listen((location) => {
      // console.log("historyArun" + JSON.stringify(history));
      if (history.action === "PUSH") {
        Decrement_Cuncurrunt_Users();

        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          Decrement_Cuncurrunt_Users();
          const isPiPMode = getPiPStateFromStorage();

          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          Decrement_Cuncurrunt_Users();
          const isPiPMode = getPiPStateFromStorage();

          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating forward
          }
        }
      }
    });
  }, [history, locationKeys]);

  useEffect(() => {
    const connectWebSocket = () => {
      // console.log("AUTHENTICATION" + AUTHENTICATION);

      const protocol = window.location.protocol === "https:" ? "wss" : "ws";

      ws = new WebSocket(
        `${protocol}://${websocketPathForCheckDevice}${SAVED_DEVICE_TOCKEN}`
      );
      // console.log("arun Connected in app.js ws " + JSON.stringify(ws));

      ws.onopen = () => {
        // console.log("arunDevice Connected in app.js " + SAVED_DEVICE_TOCKEN);

        socketStatus = false;
      };

      ws.onclose = () => {
        socketStatus = true;
        // console.log(" arunDevice closed");
        setTimeout(connectWebSocket, 60000);
      };
    };

    if (AUTHENTICATION) {
      // console.log("AUTHENTICATION" + AUTHENTICATION);
      connectWebSocket();
    }

    return () => {
      // Cleanup code if needed
      if (ws) {
        ws.close();
      }
    };
  }, [AUTHENTICATION]);

  // alert(defaultLandingPage);
  let backgroundUrl = newImageIpPath + CURRENT_PLATFORM?.webappBackgroundImage;

  // useEffect(() => {
  //   const preventRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // Add event listener to prevent right-clicking on the entire document
  //   document.addEventListener("contextmenu", preventRightClick);

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     document.removeEventListener("contextmenu", preventRightClick);
  //   };
  // }, []);
  useEffect(() => {
    if (localStorage.getItem("pageReloaded") == 0) {
      // alert("default");
      setSelectedCategory(CURRENT_PLATFORM?.defaultLandingPage);
    } else {
      // alert(localStorage.getItem("selectedCategory"));
      setSelectedCategory(localStorage.getItem("selectedCategory"));
    }
  }, [CURRENT_PLATFORM]);

  const [previousPlatformId, setPreviousPlatformId] = useState(
    CURRENT_PLATFORM?.orgId
  );

  useEffect(() => {
    const currentPlatformId = CURRENT_PLATFORM?.orgId;

    // Check if the platform ID has changed
    if (currentPlatformId !== previousPlatformId) {
      // console.log("platformid: " + JSON.stringify(currentPlatformId));

      // Call your API here
      callApi(currentPlatformId);

      // Update the previous platform ID state
      setPreviousPlatformId(currentPlatformId);
    }
  }, [CURRENT_PLATFORM]); // Re-run effect when CURRENT_PLATFORM changes

  const callApi = (platformId) => {
    // Your API call logic here
    // console.log(`Calling API with platform ID: ${platformId}`);

    dispatch(ACTION_DATA_VERSIONING.makeDataVersionsTrue());

    // Example API call:
    // fetch(`your-api-endpoint/${platformId}`)
    //     .then(response => response.json())
    //     .then(data => console.log(data));
  };

  return (
    <div
      style={
        isVideoPlayer == false
          ? {
              backgroundImage: `url(${backgroundUrl})`,
              backgroundAttachment: "fixed",
              height: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${backgroundUrl})`,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              position: "fixed",
              overflowX: "scroll",
            }
          : {}
      }
    >
      {" "}
      <Router basename="/">
        <Switch>
          <Route path="/container" exact>
            <Container
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSliderModalActive={setSliderModalActive}
              sliderModalActive={sliderModalActive}
              setSeriesEpisodeVideoUrl={setSeriesEpisodeVideoUrl}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>
          <Route path="/liveDetailsPage" exact>
            <LiveAssetViewPage setIsVideoPlayer={setIsVideoPlayer} />
          </Route>
          <Route path="/MovieDetailsPage" exact>
            <MovieAssetViewPage setIsVideoPlayer={setIsVideoPlayer} />
          </Route>{" "}
          <Route path="/SeriesDetailsPage" exact>
            <SeriesAssetViewPage setIsVideoPlayer={setIsVideoPlayer} />
          </Route>
          <Route path="/login" exact>
            <LoginScreen
              setSelectedCategory={setSelectedCategory}
              setIsVideoPlayer={setIsVideoPlayer}
            />
          </Route>
          <Route path="/android" exact>
            <AndroidOs setIsVideoPlayer={setIsVideoPlayer} />
          </Route>
          <Route path="/ios" exact>
            <Ios setIsVideoPlayer={setIsVideoPlayer} />
          </Route>
          <Route path="/platform">
            <PlatForm />
          </Route>
          <Route path="/liveVideoPlayer">
            <MainVideoPlayer
              setIsVideoPlayer={setIsVideoPlayer}
              setSelectedCategory={setSelectedCategory}
            />
          </Route>
          <Route path="/epgVideoPlayer">
            <EpgPlayerNew setIsVideoPlayer={setIsVideoPlayer} />
          </Route>{" "}
          <Route path="/devicesPage">
            <DevicesPage setSelectedCategory={setSelectedCategory} />
          </Route>
          <Route path="/epgContainer">
            <EpgContainer
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>
          <Route path="/profileContainer">
            <ProfileContainer
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>
          {/* <Route
            path="/profileContainer"
            component={ProfileContainer}
            setSearchState={setSearchState}
            searchState={searchState}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
          /> */}
          <Route path="/" exact>
            <GetStartedScreen setSelectedCategory={setSelectedCategory} />
          </Route>
          <Route path="/movieScreenVideoPlayer" exact>
            <MovieVideoJs
              setSelectedCategory={setSelectedCategory}
              setIsVideoPlayer={setIsVideoPlayer}
            />
          </Route>
          <Route path="/teaserPlayer" exact>
            <TeaserPlayer />
          </Route>
          <Route path="/forgotResetPassword">
            <ForgotResetPasswordPage />
          </Route>
          <Route path="/profile/profile/activation/">
            <ActivationPage />
          </Route>
          <Route path="/resetPassword">
            <ResetPasswordPage />
          </Route>
          <Route path="/seriesEpisodeVideoPlayer">
            <SeriesVideoPlayer
              seriesEpisodeVideoUrl={seriesEpisodeVideoUrl}
              setIsVideoPlayer={setIsVideoPlayer}
              setSelectedCategory={setSelectedCategory}
            />
          </Route>
          <Route path="/mylistcontainer">
            <MyListContainer
              setMylistLiveVideoDetails={setMylistLiveVideoDetails}
              setSliderModalActive={setSliderModalActive}
              sliderModalActive={sliderModalActive}
              setSeriesEpisodeVideoUrl={setSeriesEpisodeVideoUrl}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </Route>
          <Route path="/profileList">
            <ProfileList />
          </Route>
          <Route path="/genreContainer">
            <GenreContainer
              setSelectedCategory={setSelectedCategory}
              setSliderModalActive={setSliderModalActive}
              sliderModalActive={sliderModalActive}
              setSeriesEpisodeVideoUrl={setSeriesEpisodeVideoUrl}
              setIsVideoPlayer={setIsVideoPlayer}
            />
          </Route>
          <Route path="/MovieGenreContainer">
            <MovieGenreContainer
              setSelectedCategory={setSelectedCategory}
              setIsVideoPlayer={setIsVideoPlayer}
            />
          </Route>
          <Route path="/seriesGenreContainer">
            <SeriesGenreContainer
              setSelectedCategory={setSelectedCategory}
              setIsVideoPlayer={setIsVideoPlayer}
            />
          </Route>
          <Route path="/HandleProfile" component={HandleProfile} />
          <Route path="/UpdateProfile" component={UpdateProfile} />
          <Route path="/CreateProfile" component={CreateProfile} />
          <Route path="/forgotPasswordPage" component={ForgotPasswordPage} />
          <Route path="/deleteDevicePage">
            <DeleteDevicePage
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>
          <Route path="/Settings">
            <Settings
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>{" "}
          <Route path="/PlanDetails">
            <PlanDetails
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSearchState={setSearchState}
              searchState={searchState}
              setSearchKey={setSearchKey}
              searchKey={searchKey}
              setIsVideoPlayer={setIsVideoPlayer}
              setClearSearchState={setClearSearchState}
              clearSearchState={clearSearchState}
            />
          </Route>
          <Route path="/privacyPolicy">
            <PrivacyPolicyScreen setIsVideoPlayer={setIsVideoPlayer} />
          </Route>
        </Switch>
      </Router>
      {isVideoPlayer == false && searchState == false && (
        <div style={{ marginTop: "70px" }}>
          <FooterPage />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  PLATFORMS: platformsSelector /* Selector contains platform details */,
  DEVICE_DETAILS: getDeviceDetailsSelector /* Selector contains Device Details */,
  DEVICE_TOKEN: getDeviceTokenSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  CURRENT_PLATFORM_FOR_ROUTE: getDefaultPlatformsForRouteSelector,
  DEVICE_NAME: getDeviceNameSelector,
  CONFIGURATION_DAS: configurationSelector,
  DEVICE_STATUS_FOR_REGISTER: getDeviceStatusForDeleteDeviceSelector,

  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  LOGIN_DETAILS: loginDetailsSelector,
  GENERAL_DATA: generalSettingsSelector,
});

export default connect(mapStateToProps)(App);
